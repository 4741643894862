import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { NgxAimService } from '../ngx-aim.service';
import { Observable } from 'rxjs';
import { WebCategory } from '../models';

@Injectable({
  providedIn: 'root'
})
export class WebCategoriesService {

  private baseUrl = `${this.ngxAimService.getApiUrl()}/web-categories`;

  constructor(
    private http: HttpClient,
    private ngxAimService: NgxAimService,
  ) {}

  getCategories(): Observable<WebCategory[]> {
    return this.http.get<WebCategory[]>(this.baseUrl);
  }
}
