<p-blockUI [target]="gpPaymentForm" [blocked]="isLoading" styleClass="border-round-lg">
  <p-progressSpinner></p-progressSpinner>
</p-blockUI>
<ngx-p-blockable-div #gpPaymentForm>
  <div >
    <div class="mb-2">
      <img src="assets/images/others/accepted-cards.png" alt="accepted cards">
    </div>
    <form id="payment-form" [ngStyle]="{'display': cardData ? 'none' : 'block'}">
      Card Number:
      <div id="card-number"></div>
      Expiration:
      <div id="card-expiration"></div>
      CVV:
      <div id="card-cvv"></div>
      <div id="submit" [ngClass]="{'disabled': !isSubmitButtonEnabled()}"></div>
    </form>
  </div>
</ngx-p-blockable-div>
<div *ngIf="cardData?.card">
  <div class="ml-2 mb-2">
    Added {{cardData?.card?.type}} ending in: {{cardData?.card?.masked_card_number?.slice(12)}}
  </div>
  <p-button (onClick)="onChangeCardClick()" label="Change Credit Card" class="ml-2"></p-button>
</div>
<p-dialog header="Please fix the following problems" [(visible)]="displayErrorDialog">
  <p *ngFor="let error of errors">
    {{error}}
  </p>
</p-dialog>
