import { Component } from '@angular/core';
import { CommonModule } from '@angular/common';
import { DynamicDialogConfig } from 'primeng/dynamicdialog';
import { PaymentLog } from '../../../../../models';
import { HttpErrorService } from '../../../../../services';
import { AdminOrderService } from '../../admin-order.service';

@Component({
  selector: 'aim-order-payment-log',
  standalone: true,
  imports: [CommonModule],
  templateUrl: './order-payment-log.component.html',
  styleUrls: ['./order-payment-log.component.scss']
})
export class OrderPaymentLogComponent {
  log: PaymentLog[] = [];

  constructor(
    private orderService: AdminOrderService,
    private config: DynamicDialogConfig,
    private httpErrorService: HttpErrorService
  ) {
    this.orderService.getPaymentLog(this.config.data).subscribe({
      next: (log) => this.log = log,
      error: (err) => this.httpErrorService.onHttpError(err, 'Could retrieve payment log'),
    });
  }
}
