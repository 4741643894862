<div class="col-12">
  <form [formGroup]="form" class="p-fluid p-formgrid grid dialog-form">
    <div class="field col-12 md:col-6">
      <span class="p-float-label">
        <input pInputText id="Id" formControlName="Id" />
        <label htmlFor="Id">Id</label>
      </span>
    </div>
    <div class="field col-12 md:col-6">
      <span class="p-float-label">
        <input pInputText id="Name" formControlName="Name" />
        <label htmlFor="Name">Name</label>
      </span>
    </div>
    <div class="field col-12 md:col-6">
      <span class="p-float-label">
        <input pInputText id="Description" formControlName="Description" />
        <label htmlFor="Description">Description</label>
      </span>
    </div>
    <div class="field col-12 md:col-6">
      <span class="p-float-label">
        <p-dropdown [options]="dropDownOptions" formControlName="Unpublished" placeholder="Show On Main Navigation"  inputId="Unpublished"></p-dropdown>
        <label for="Unpublished">Unpublished</label>
    </span>
    </div>
    <div class="field col-12 md:col-6">
      <span class="p-float-label">
        <p-dropdown [options]="dropDownOptions" formControlName="showSubCatImages" placeholder="Show Sub Category Images"  inputId="showSubCatImages"></p-dropdown>
        <label for="showSubCatImages">Show Sub Category Images</label>
    </span>
    </div>
    <div class="field col-12 md:col-12">
      <span>
        <span>Image</span>
        <p-fileUpload url="api/v1/admin/aim/web-categories/image"
                      name="thumbSrc"
                      accept="image/*"
                      [multiple]="false"
                      [headers]="headers"
                      (onUpload)="onUpload($event)"
                      (onError)="onUploadError($event)">
          <ng-template pTemplate="content"></ng-template>
        </p-fileUpload>
        <div *ngIf="form.get('thumbSrc')?.value">
          <img class="mt-1" [src]="'files/' + form.get('thumbSrc')?.value" width="100%" />
        </div>
      </span>
    </div>
  </form>
  <div class="flex justify-content-end">
    <p-button label="Save"
              icon="pi pi-check"
              styleClass="p-button-text"
              [loading]="isSaving"
              [disabled]="form.invalid || !!this.form.get('isDefault')?.value"
              (onClick)="onSave()">
    </p-button>
    <p-button label="Cancel"
              icon="pi pi-times"
              styleClass="p-button-text"
              [disabled]="isSaving"
              (onClick)="onCancel()">
    </p-button>
  </div>
</div>
<div class="progress-spinner" *ngIf="isLoading">
  <p-progressSpinner></p-progressSpinner>
</div>
