import { EventEmitter, inject } from '@angular/core';
import {
  PickerHours,
  PickerMeridiem,
  PickerMinutes,
  TimePickerHours,
  TimePickerMeridiem,
  TimePickerMinutes
} from '../../constants';
import { FormBuilder, Validators } from '@angular/forms';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { distinctUntilChanged } from 'rxjs';
import { TimePickerForm } from '../../models';

/** when extending this class be sure to implement ngOnInit,
 * @Input()
 * override set isDisabled(isDisabled: boolean) {
 *   if (isDisabled) {
 *     this.form.disable();
 *     this.toolTip = 'Select day frist';
 *   } else {
 *     this.form.enable();
 *     this.toolTip = '';
 *   }
 * }
 *
 * @Output()
 * override onTimeChange = new EventEmitter<TimePickerForm>();
 */
export abstract class NgxAimTimePickerComponent {
  // ngOnInit() {
  //   this.init();
  // }

  fb = inject(FormBuilder);

  set isDisabled(isDisabled: boolean) {
    if (isDisabled) {
      this.form.disable();
      this.toolTip = 'Select day frist';
    } else {
      this.form.enable();
      this.toolTip = '';
    }
  }

  onTimeChange!: EventEmitter<TimePickerForm>;

  hourValues = TimePickerHours;
  minuteValues = TimePickerMinutes;
  meridiemValues = TimePickerMeridiem;
  form = this.fb.group({
    hour: this.fb.control<PickerHours | null>(null, { validators: [Validators.required] }),
    minute: this.fb.control<PickerMinutes | null>(null, { validators: [Validators.required] }),
    meridiem: this.fb.control<PickerMeridiem | null>(null, { validators: [Validators.required] }),
  });

  changes$ = this.form.valueChanges.pipe(
    distinctUntilChanged(),
    takeUntilDestroyed(),
  );

  toolTip = '';

  init() {
    this.changes$.subscribe(() => {
      if (this.form.valid) {
        this.emitData();
      }
    });
  }

  emitData() {
    this.onTimeChange.next({
      hour: this.form.controls.hour.value!,
      minute: this.form.controls.minute.value!,
      meridiem: this.form.controls.meridiem.value!,
    });
  }
}
