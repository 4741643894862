import { Pipe, PipeTransform } from '@angular/core';
import { User } from '../../models';

@Pipe({
  name: 'userFullname',
  standalone: true
})
export class UserFullnamePipe implements PipeTransform {
  transform(user: User): string {
    return `${user.firstName} ${user.lastName}`;
  }
}
