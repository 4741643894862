import { Component } from '@angular/core';
import { CommonModule } from '@angular/common';
import { DynamicDialogConfig, DynamicDialogRef } from 'primeng/dynamicdialog';
import { FormsModule } from '@angular/forms';
import { ShipEngineCarrier } from '../../../../../../../models';
import { HttpErrorService } from '../../../../../../../services';
import { AdminPrimengModule } from '../../../../../admin.primeng.module';
import { AdminShipEngineService } from '../../admin-ship-engine.service';

@Component({
  selector: 'aim-edit-carrier-service',
  standalone: true,
  imports: [CommonModule, FormsModule, AdminPrimengModule],
  templateUrl: './edit-carrier-service.component.html',
  styleUrls: ['./edit-carrier-service.component.scss', '../../../../../admin.scss']
})
export class EditCarrierServiceComponent {

  isSaving = false;

  constructor(
    public config: DynamicDialogConfig<ShipEngineCarrier>,
    private AdminShipEngineService: AdminShipEngineService,
    private httpErrorService: HttpErrorService,
    private dialogRef: DynamicDialogRef,
  ) {}

  onSave() {
    this.isSaving = true;
    this.AdminShipEngineService.updateServices(this.config.data!.carrier_id, this.config.data!.services).subscribe({
      next: () => this.isSaving = false,
      error: (err) => this.httpErrorService.onHttpError(err),
    });
  }

  onCancel() {
    this.dialogRef.close();
  }
}
