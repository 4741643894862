import { Component, Input } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule } from '@angular/router';
import { CatForDisplay } from '../../../../models';
import { NgxCategoryFilterComponent } from './ngx-category-filter.class';

@Component({
  selector: 'ngx-aim-category-filter',
  standalone: true,
  imports: [CommonModule, RouterModule],
  templateUrl: './category-filter.component.html',
  styleUrls: ['./category-filter.component.scss']
})
export class NgxAimCategoryFilterComponent extends NgxCategoryFilterComponent {
  @Input()
  override categories: CatForDisplay[] = [];
}
