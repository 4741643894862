export const ATT_PREFIX = 'att.';

export type FilterChipType = 'attribute' | 'searchTerm' | 'manufacturer';

export interface FilterChip {
  type: FilterChipType;
  value: string;
  name?: string;
  id?: number;
}
