import { Component, OnInit } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormBuilder, Validators } from '@angular/forms';
import { DialogService, DynamicDialogConfig, DynamicDialogRef } from 'primeng/dynamicdialog';
import { ReactiveFormsModule } from '@angular/forms';
import { MessageService } from 'primeng/api';
import { AdminSetPasswordComponent } from './admin-set-password/admin-set-password.component';
import { AdminPrimengModule } from '../../admin.primeng.module';
import { ControlsOf } from '../../../../types';
import { SaveUserDto, User } from '../../../../models';
import { HttpErrorService } from '../../../../services';
import { UserType, UserTypes } from '../../../../enums';
import { AdminUserService } from '../admin-user.service';

@Component({
  selector: 'aim-edit-user',
  standalone: true,
  imports: [
    CommonModule,
    ReactiveFormsModule,
    AdminPrimengModule,
  ],
  templateUrl: './edit-user.component.html',
  styleUrls: ['./edit-user.component.scss', '../../admin.scss']
})
export class EditUserComponent implements OnInit {
  userTypes = UserTypes;
  isLoading = false;
  isSaving = false;

  form = this.fb.group<ControlsOf<SaveUserDto>>({
    id: this.fb.control<number | null>(null),
    email: this.fb.nonNullable.control<string>('', { validators: [Validators.required, Validators.email] }),
    firstName: this.fb.nonNullable.control<string>('', { validators: [Validators.required] }),
    lastName: this.fb.nonNullable.control<string>('', { validators: [Validators.required] }),
    phone: this.fb.nonNullable.control<string>(''),
    aimId: this.fb.control<number | null>(null),
    userType: this.fb.control<UserType.ADMIN | UserType.CUSTOMER | null>(null, { validators: [Validators.required] })
  });

  metaForm = this.fb.group({
    createDate: [{ value: null, disabled: true }],
    createBy: [{ value: null, disabled: true }],
    updateDate: [{ value: null, disabled: true }],
    updateBy: [{ value: null, disabled: true }],
    deleteDate: [{ value: null, disabled: true }],
    deleteBy: [{ value: null, disabled: true }],
  });

  constructor(
    private entityService: AdminUserService,
    private fb: FormBuilder,
    private config: DynamicDialogConfig,
    private dialogRef: DynamicDialogRef,
    private messageService: MessageService,
    private httpErrorService: HttpErrorService,
    private dialogService: DialogService
  ) {}

  ngOnInit() {
    if (this.config.data) {
      this.getEntity(this.config.data);
    }
  }

  getEntity(entityId: number) {
    this.isLoading = true;
    this.entityService.findOne(entityId).subscribe({
      next: (entity) => this.setFormData(entity),
      error: (err) => this.onError(err, 'Could not retrieve user')
    });
  }

  setFormData(entity: User) {
    this.isLoading = false;
    this.form.patchValue(entity);
  }

  onSave() {
    this.isSaving = true;
    this.entityService.save(this.form.getRawValue()).subscribe({
      next: (entity) => this.onSuccess(entity),
      error: (err) => this.onError(err, 'Could not save user')
    });
  }

  onCancel() {
    this.dialogRef.close();
  }

  onSetPasswordClick() {
    const dialog = this.dialogService.open(AdminSetPasswordComponent, {
      data: this.form.get('id')?.value,
      header: 'Password',
      width: '700px',
      contentStyle: { "max-height": "580px", "overflow": "auto" },
      dismissableMask: true,
    });
  }

  onError(err: any, message: string) {
    this.isSaving = false;
    this.isLoading = false;
    this.httpErrorService.onHttpError(err, message);
  }

  onSuccess(entity: User) {
    this.setFormData(entity);
    this.isSaving = false;
    this.messageService.add({
      severity: 'success',
      summary: 'User saved',
      key: 'app-toast'
    });
  }
}
