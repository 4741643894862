export enum AdminRoutes {
  ROOT = 'admin',
  DASHBOARD = 'dashboard',
  USERS = 'users',
  WEB_CATEGORIES = 'web-categories',
  CONTRACTS = 'contracts',
  CUSTOMER_DEFAULTS = 'customer-defaults',
  TAX_AUTHORITY = 'tax-authority',
  LOCATIONS = 'locations',
  ITEMS = 'items',
  CAROUSEL = 'carousel',
  GLOBAL_PAY = 'global-pay',
  ORDERS = 'orders',
  STATES = 'states',
  CATEGORIES = 'categories',
  SHIPPING = 'shipping',
  FFLS = 'ffls',
  FORM_IMPUTS = 'form-inputs',
}
