<p-blockUI [target]="ffl" [blocked]="isLoading || isSaving" styleClass="border-round-lg">
  <p-progressSpinner></p-progressSpinner>
</p-blockUI>

<ngx-p-blockable-div #ffl>
  <div class="flex flex-row justify-content-center mb-3">
    <div>SELECT FFL <i class="pi pi-question-circle" pTooltip="FFL stands for Federal Firearms License. Firearms must be shipped to a FFL when purchased on-line. 
      Firearm retailers have a FFL and most will accept the shipment and charge you a fee. You must select a FFL 
      from the list below or if you know of a FFL that is willing to accept the shipment you can click the ADD A FFL button."></i></div>
  </div>
  
  <div class="flex flex-row flex-wrap justify-space-between">
    <form [formGroup]="form" class="flex-column p-fluid p-formgrid grid ffl-form mr-2">
      <div class="field col-12 md:col-6 w-full">
        <span class="p-float-label">
          <input pInputText id="zipecode" formControlName="zipcode" />
          <label htmlFor="zipcode">Zipcode</label>
        </span>
      </div>
      <div class="field col-12 md:col-6 mb-1 w-full">
        <span class="p-float-label">
          <p-dropdown formControlName="distance" [options]="distanceOptions" optionLabel="value" placeholder="Select a Distance"></p-dropdown>
          <label htmlFor="distance">Distance</label>
        </span>
      </div>
      <div class="col-12 md:col-6 w-full">
        <p-button (onClick)="findAll()" label="Search"></p-button>
      </div>
      <div class="col-12 md:col-6 w-full">
        <p-button *ngIf="showInStorePickupBtn" (onClick)="onInStorePickupClick()" label="In Store Pickup"></p-button>
      </div>
    </form>
    <div>
      <p-listbox [options]="ffls"
                 [(ngModel)]="selectedFfl"
                 optionLabel="name"
                 [style]="{'width':'15rem'}"
                 [listStyle]="{'max-height': '320px'}">
      </p-listbox>
    </div>
    <div class="flex flex-column justify-content-start ml-5 mt-3">
      <div><strong>FFL Details</strong></div>
      <ng-container *ngIf="selectedFfl; else notSelected">
        <div class="grid">
          <div class="col-12 md:col-6 w-full">
            <div class="flex flex-column">
              <div class="flex">{{selectedFfl.name}}</div>
              <div class="flex">{{selectedFfl.address1}}</div>
              <div *ngIf="selectedFfl.address2" class="flex">{{selectedFfl.address2}}</div>
              <div class="flex">{{selectedFfl.city}}, {{selectedFfl.state}} {{selectedFfl.zip}}</div>
              <div *ngIf="selectedFfl.phone" class="flex">{{selectedFfl.phone}}</div>
              <div class="flex">
                <p-button (onClick)="onUseThisFflClick()" label="Use This FFL" class="mt-3"></p-button>
              </div>
            </div>
          </div>
        </div>
      </ng-container>
      <ng-template #notSelected>
        Please select a FFL from the list.
        <span class="mr-auto ml-auto font-bold">&mdash; OR &mdash;</span>
        <div class="mr-auto ml-auto mt-1">
          <p-button (onClick)="onAddClick()" label="Add a FFL"></p-button>
        </div>
      </ng-template>
    </div>
  </div>
</ngx-p-blockable-div>