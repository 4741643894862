<div class="carousel-container">
  <!-- [autoplayInterval]="autoplayInterval" was remove from p-carousel because it was causing a bug where the 
    carousel would be doubled along with the logo and search bar on the nav. -->
  <p-carousel [value]="slides"
              [numVisible]="1"
              [numScroll]="1"
              [circular]="slides.length > 1"
              [showNavigators]="false"
              [showIndicators]="slides.length > 1"
              [page]="currentPage"
              (onPage)="onPage($event)">
  <ng-template let-slide pTemplate="item">
      <div class="carousel-slide">
        <img [src]="(isMobile$ | async) ? 'files/' + slide.mobileSrc : 'files/' + slide.src" class="w-12" />
        <div class="chev-container" *ngIf="slides.length > 1">
          <button class="nav-chev" (click)="onPreviousClick()"><i class="pi pi-chevron-left chev-icon"></i></button>
          <button class="nav-chev" (click)="onNextClick()"><i class="pi pi-chevron-right chev-icon"></i></button>
        </div>
      </div>
  </ng-template>
</p-carousel>
</div>
