import { ChangeDetectionStrategy, Component, Input, OnInit } from '@angular/core';
import { CommonModule } from '@angular/common';
import { InputNumberModule } from 'primeng/inputnumber';
import { FormsModule } from '@angular/forms';
import { ButtonModule } from 'primeng/button';
import { PriceFilter } from '../../../../models';
import { NgxPriceFilterComponent } from './ngx-price-filter.class';

@Component({
  selector: 'ngx-aim-price-filter',
  standalone: true,
  imports: [CommonModule, FormsModule, InputNumberModule, ButtonModule],
  templateUrl: './price-filter.component.html',
  styleUrls: ['./price-filter.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class NgxAimPriceFilterComponent extends NgxPriceFilterComponent implements OnInit {
  @Input()
  override minMax: PriceFilter | undefined;

  ngOnInit() {
    this.init();
  }
}
