import { Component, ChangeDetectionStrategy, Output, EventEmitter, Input, OnInit } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ReactiveFormsModule } from '@angular/forms';
import { DropdownModule } from 'primeng/dropdown';
import { TimePickerForm } from '../../models';
import { TooltipModule } from 'primeng/tooltip';
import { NgxAimTimePickerComponent } from './ngx-aim-time-picker.class';

@Component({
  selector: 'ngx-time-picker',
  standalone: true,
  imports: [CommonModule, ReactiveFormsModule, DropdownModule, TooltipModule],
  templateUrl: './ngx-time-picker.component.html',
  styleUrls: ['./ngx-time-picker.component.css'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class NgxTimePickerComponent extends NgxAimTimePickerComponent implements OnInit {
  @Input()
  override set isDisabled(isDisabled: boolean) {
    if (isDisabled) {
      this.form.disable();
      this.toolTip = 'Select day frist';
    } else {
      this.form.enable();
      this.toolTip = '';
    }
  }

  @Output()
  override onTimeChange = new EventEmitter<TimePickerForm>();

  ngOnInit() {
    this.init();
  }
}
