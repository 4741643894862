import { inject } from '@angular/core';
import { NonNullableFormBuilder } from '@angular/forms';
import { Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { UserService } from '../../services';
import { passwordsMatchValidator } from '../../validators';
import { PublicRoutes } from '../../enums';

export abstract class NgxCreateAccountComponent {
  fb = inject(NonNullableFormBuilder);
  userService = inject(UserService);
  router = inject(Router);

  form = this.fb.group({
    firstName: ['', Validators.required],
    lastName: ['', Validators.required],
    email: ['', [Validators.required, Validators.email]],
    phone: ['', Validators.required],
    password: ['', [Validators.required, Validators.minLength(8)]],
    confirmPassword: ['', [Validators.required, Validators.minLength(8)]],
  }, { validators: [passwordsMatchValidator] });
  isLoading = false;
  error = '';

  onSubmit() {
    this.error = '';
    this.isLoading = true;
    this.userService.createAccount(this.form.getRawValue()).subscribe({
      next: (dto) => this.onCreateSuccess(dto.email),
      error: (err) => this.onCreateError(err)
    });
  }

  onCreateSuccess(email: string) {
    this.isLoading = false;
    this.router.navigate([PublicRoutes.CREATE_ACCOUNT_SUCCESSFUL], { state: { email } });
  }

  onCreateError(err: any) {
    this.isLoading = false;
    this.error = err.text || err.error?.message || err.error?.error || err.message || 'Something went wrong';
  }

  get fc() { return this.form.controls; }
}