import { Component, OnInit } from '@angular/core';
import { CommonModule } from '@angular/common';
import { NonNullableFormBuilder, Validators } from '@angular/forms';
import { DynamicDialogConfig, DynamicDialogRef } from 'primeng/dynamicdialog';
import { ReactiveFormsModule } from '@angular/forms';
import { MessageService } from 'primeng/api';
import { PasswordModule } from 'primeng/password';
import { AdminPrimengModule } from '../../../admin.primeng.module';
import { ControlsOf } from '../../../../../types';
import { SetUserPasswordDto } from '../../../../../models';
import { HttpErrorService } from '../../../../../services';
import { passwordsMatchValidator } from '../../../../../validators';
import { AdminUserService } from '../../admin-user.service';

@Component({
  selector: 'aim-admin-set-password',
  standalone: true,
  imports: [CommonModule, ReactiveFormsModule, PasswordModule, AdminPrimengModule],
  templateUrl: './admin-set-password.component.html',
  styleUrls: ['./admin-set-password.component.scss', '../../../admin.scss']
})
export class AdminSetPasswordComponent implements OnInit {
  isSaving = false;

  form = this.fb.group<ControlsOf<SetUserPasswordDto>>({
    id: this.fb.control<number>(0),
    password: this.fb.control<string>('', {
      validators: [Validators.required, Validators.minLength(8)]
    }),
    confirmPassword: this.fb.control<string>(''),
  }, { validators: [passwordsMatchValidator] });

  constructor(
    private entityService: AdminUserService,
    private fb: NonNullableFormBuilder,
    private config: DynamicDialogConfig,
    private dialogRef: DynamicDialogRef,
    private messageService: MessageService,
    private httpErrorService: HttpErrorService
  ) {}

  ngOnInit() {
    if (this.config.data) {
      this.form.get('id')?.setValue(this.config.data);
    }
  }

  onSave() {
    this.isSaving = true;
    this.entityService.setUserPassword(this.form.getRawValue()).subscribe({
      next: () => this.onSuccess(),
      error: (err) => this.onError(err, 'Could set password')
    });
  }

  onCancel() {
    this.dialogRef.close();
  }

  onError(err: any, message: string) {
    this.isSaving = false;
    this.httpErrorService.onHttpError(err, message);
  }

  onSuccess() {
    this.isSaving = false;
    this.messageService.add({
      severity: 'success',
      summary: 'Password updated',
      key: 'app-toast'
    });
  }

  get f() {
    return this.form.controls;
  }
}
