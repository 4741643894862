<ng-container *ngIf="item; else loadingTemplate">
  <main *ngIf="item" class="flex gap-3 flex-wrap justify-content-center">
    <div class="flex gap-3 flex-wrap justify-content-center">
      <ngx-aim-product-galleria [images]="item.images"></ngx-aim-product-galleria>
      <div class="mt-2 product-info">
        <h1>{{item.SETitle}}</h1>
        <p>{{item.Model}}</p>
        <ng-container *ngIf="isRental; else priceTemplate">
          <ngx-aim-rental-price [itemPk]="item.Pk"></ngx-aim-rental-price>
        </ng-container>
        <ng-template #priceTemplate>
          <h2>{{item.ActiveePrice | currency}}</h2>
        </ng-template>
        <p-button *ngIf="showBuyButton" (onClick)="onProductActionClick(item)" [disabled]="item.AvailableQuantity <= 0">{{isRental ? 'RENT' : 'ADD TO CART'}}</p-button>
        <div [innerHTML]="item.AvailableQuantity | outOfStock"></div>
        <p>{{item.Summary}}</p>
        <ul>
          <li *ngFor="let attribute of item.attributes">
            {{attribute.Name}}: {{attribute.Value}}
          </li>
        </ul>
      </div>
    </div>
    <p [innerHTML]="item.WebDescription"></p>
  </main>
</ng-container>
<ng-template #loadingTemplate>
  <div class="progress-spinner">
    <p-progressSpinner></p-progressSpinner>
  </div>
</ng-template>