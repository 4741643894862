import { Component, Input, OnInit } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ButtonModule } from 'primeng/button';
import { HasImagePipe } from '../../../pipes';
import { DataViewModule } from 'primeng/dataview';
import { DividerModule } from 'primeng/divider';
import { DialogModule } from 'primeng/dialog';
import { CardModule } from 'primeng/card';
import { NgxAimOverviewRentalsComponent } from './ngx-aim-overview-rentals.class';

@Component({
  selector: 'ngx-overview-rentals',
  standalone: true,
  imports: [CommonModule, ButtonModule, HasImagePipe, DataViewModule, DividerModule, DialogModule, CardModule],
  templateUrl: './ngx-overview-rentals.component.html',
  styleUrls: ['./ngx-overview-rentals.component.css']
})
export class NgxOverviewRentalsComponent extends NgxAimOverviewRentalsComponent implements OnInit {
  @Input({ required: true })
  override completeUrl: string = '';

  ngOnInit() {
    this.init();
  }
}
