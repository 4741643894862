import { Injectable } from '@angular/core';
import { AppLazyLoadEvent, FindAllResult, SaveUserDto, User, SetUserPasswordDto } from '../../../models';
import { HttpClient, HttpParams } from '@angular/common/http';
import { Observable } from 'rxjs';
import { NgxAimService } from '../../../ngx-aim.service';
import { GenericSearchService, HttpErrorService } from '../../../services';

@Injectable({
  providedIn: 'root'
})
export class AdminUserService extends GenericSearchService<User> {

  private baseUrl = `${this.ngxAimService.getApiUrl()}/admin/users`;

  constructor(
    private http: HttpClient,
    private ngxAimService: NgxAimService,
    private _httpErrorService: HttpErrorService
  ) {
    super(_httpErrorService, 'Users');
  }

  findAll(dto: AppLazyLoadEvent): Observable<FindAllResult<User>> {
    return this.http.post<FindAllResult<User>>(`${this.baseUrl}/all`, dto);
  }

  findOne(id: number): Observable<User> {
    return this.http.get<User>(`${this.baseUrl}/user/${id}`);
  }

  save(user: SaveUserDto): Observable<User> {
    return this.http.post<User>(this.baseUrl, user);
  }

  setUserPassword(dto: SetUserPasswordDto): Observable<void> {
    return this.http.post<void>(`${this.baseUrl}/set-password`, dto);
  }

  delete(ids: number[]): Observable<number> {
    return this.http.delete<number>(this.baseUrl, {
      params: new HttpParams()
        .set('ids', ids.join(','))
    });
  }
}
