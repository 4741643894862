import { Injectable } from '@angular/core';
import { MessageService } from 'primeng/api';

@Injectable({
  providedIn: 'root'
})
export class HttpErrorService {
  constructor(private messageService: MessageService) {}

  onHttpError(err: any, summary?: string) {
    const detail = err.text || err.error?.message || err.error?.error || err.message || 'Something went wrong';
    this.messageService.add({
      severity: 'error',
      summary: summary ? summary : 'Something went wrong. Please try again.',
      detail: detail,
      key: 'app-toast',
      life: 5000
    });
  }
}
