<p-toolbar>
  <div class="p-toolbar-group-start">
    <button pButton pRipple label="New" icon="pi pi-plus" class="p-button-primary mr-2" (click)="onNewClick()"></button>
    <button pButton
            pRipple
            label="Delete"
            icon="pi pi-trash"
            class="p-button-danger mr-2"
            (click)="onDeleteClick($event)"
            [disabled]="!selectedData || !selectedData.length">
    </button>
    <p-confirmPopup key="onDeleteClick"></p-confirmPopup>
    <button pButton
            type="button"
            class="p-button-secondary" 
            icon="pi pi-cog"
            (click)="showOptions=true"
            pTooltip="Options">
    </button>
    <p-dialog header="Options" [(visible)]="showOptions" showEffect="fade" [style]="{width: '400px'}">
      <div class="p-grid options">
        <div class="col-12 md:col-6">
          <div class="p-field-checkbox">
            <p-checkbox name="options"
                        value="withDeleted"
                        [(ngModel)]="selectedOptions"
                        label="Include deleted">
            </p-checkbox>
          </div>
        </div>
      </div>
      <p-footer>
        <button pButton
                pRipple
                type="button"
                icon="pi pi-check"
                (click)="onOptionsClose('update')"
                label="OK"
                class="p-button-text">
        </button>
        <button pButton
                pRipple
                type="button"
                icon="pi pi-times"
                (click)="onOptionsClose('cancel')"
                label="Cancel"
                class="p-button-text">
        </button>
      </p-footer>
    </p-dialog>
  </div>
</p-toolbar>

<p-table #dt
         dataKey="id"         
         [value]="(data$ | async)!"
         [loading]="(isLoading$ | async)!"
         [totalRecords]="(dataCount$ | async)!"
         [lazy]="true"
         [rows]="10"
         [showCurrentPageReport]="true"
         [rowsPerPageOptions]="[10, 25, 50]"
         [paginator]="true"
         [globalFilterFields]="['formInput.id', 'formInput.name', 'formInput.forType']"
         [filterDelay]="250"
         [breakpoint]="'960px'"
         [rowHover]="true"
         [(selection)]="selectedData"
         (onLazyLoad)="onLazyLoad($event)"
         currentPageReportTemplate="Showing {first} to {last} of {totalRecords} entries"
         sortField="formInput.id"
         sortMode="single"
         responsiveLayout="stack">
  <ng-template pTemplate="caption">
    <div class="flex">
      Form Inputs
      <span class="p-input-icon-left ml-auto">
        <i class="pi pi-search"></i>
        <input pInputText type="text" placeholder="Search keyword" (input)="applyFilterGlobal($event)" />
      </span>
    </div>
  </ng-template>
  <ng-template pTemplate="header">
    <tr>
      <th style="width: 3rem">
        <p-tableHeaderCheckbox></p-tableHeaderCheckbox>
      </th>
      <th style="min-width:15rem" pSortableColumn="formInput.id">
        <div class="flex align-items-center">
          Id
          <p-sortIcon field="formInput.id"></p-sortIcon>
          <p-columnFilter type="text" field="formInput.id" display="menu"></p-columnFilter>
        </div>
      </th>
      <th style="min-width:15rem" pSortableColumn="formInput.name">
        <div class="flex align-items-center">
          Name
          <p-sortIcon field="formInput.name"></p-sortIcon>
          <p-columnFilter type="text" field="formInput.name" display="menu"></p-columnFilter>
        </div>
      </th>
      <th style="min-width:15rem" pSortableColumn="formInput.required">
        <div class="flex align-items-center">
          Required
          <p-sortIcon field="formInput.required"></p-sortIcon>
          <p-columnFilter type="text" field="formInput.required" display="menu"></p-columnFilter>
        </div>
      </th>
      <!-- <th style="min-width:15rem" pSortableColumn="formInput.forType">
        <div class="flex align-items-center">
          For
          <p-sortIcon field="formInput.forType"></p-sortIcon>
          <p-columnFilter type="text" field="formInput.forType" display="menu"></p-columnFilter>
        </div>
      </th> -->
      <th style="width: 8rem"></th>
    </tr>
  </ng-template>
  <ng-template pTemplate="body" let-formInput>
    <tr [ngClass]="{'deleted-record': formInput.deleteDate}">
      <td>
        <p-tableCheckbox [value]="formInput" [disabled]="formInput.deleteDate"></p-tableCheckbox>
      </td>
      <td>
        <span class="p-column-title"><strong>Id</strong></span>
        {{formInput.id}}
      </td>
      <td>
        <span class="p-column-title"><strong>Name</strong></span>
        {{formInput.name}}
      </td>
      <td>
        <span class="p-column-title"><strong>Required</strong></span>
        {{formInput.required}}
      </td>
      <!-- <td>
        <span class="p-column-title"><strong>For</strong></span>
        {{formInput.forType}}
      </td> -->
      <td style="text-align: center">
        <button pButton
                pRipple
                icon="pi pi-pencil"
                class="p-button-rounded p-button-primary mr-2"
                (click)="openEntity(formInput.id)"
                pTooltip="Edit"
                tooltipPosition="left">
        </button>
      </td>
    </tr>
  </ng-template>
  <ng-template pTemplate="emptymessage">
    <tr>
      <td colspan="7">No Form Inputs found.</td>
    </tr>
  </ng-template>
</p-table>
