import { inject } from '@angular/core';
import { NonNullableFormBuilder, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { UserService } from '../services';
import { PublicRoutes } from '../enums';

export abstract class NgxContactUsComponent {
  fb = inject(NonNullableFormBuilder);
  userService = inject(UserService);
  router = inject(Router);

  form = this.fb.group({
    firstName: this.fb.control<string>('', [Validators.required]),
    lastName: this.fb.control<string>(''),
    email: this.fb.control<string>('', [Validators.email]),
    phone: this.fb.control<string>(''),
    message: this.fb.control<string>('', [Validators.required]),
  });

  routes = PublicRoutes;
  error = '';
  isLoading = false;
  showSuccessDialog = false;

  onSubmit() {
    this.error = '';
    this.isLoading = true;
    this.userService.sendContactUsMessage(this.form.getRawValue()).subscribe({
      next: () => this.onSendSuccess(),
      error: (err) => this.onSendError(err)
    });
  }

  onSendSuccess() {
    this.isLoading = false;
    this.showSuccessDialog = true;
  }

  onSendError(err: any) {
    this.isLoading = false;
    this.error = err.text || err.error?.message || err.error?.error || err.message || 'Something went wrong';
  }

  onOkClick() {
    this.showSuccessDialog = false;
    this.router.navigateByUrl('/');
  }

  get fc() { return this.form.controls; }
}
