import { Component } from '@angular/core';
import { AdminRoutes } from '../admin.routes';
import { MenuItem } from 'primeng/api';
import { MenuModule } from 'primeng/menu';
import { RouterLink } from '@angular/router';
import { ButtonModule } from 'primeng/button';
import { AppStateService } from '../../../services';
import { PaymentType } from '../../../enums';

@Component({
  selector: 'aim-admin-header',
  templateUrl: './admin-header.component.html',
  styleUrls: ['./admin-header.component.scss'],
  standalone: true,
  imports: [ButtonModule, RouterLink, MenuModule]
})
export class AdminHeaderComponent {
  routes = AdminRoutes;
  aimMenuItems: MenuItem[] = [
    {
      label: 'Orders',
      icon: 'pi pi-shopping-cart',
      routerLink: this.routes.ORDERS,
    },
    {
      label: 'Web Categories',
      icon: 'pi pi-folder',
      routerLink: this.routes.WEB_CATEGORIES,
    },
    {
      label: 'STR Categories',
      icon: 'pi pi-folder',
      routerLink: this.routes.CATEGORIES,
    },
    {
      label: 'Contracts',
      icon: 'pi pi-book',
      routerLink: this.routes.CONTRACTS,
    },
    {
      label: 'Items',
      icon: 'pi pi-tag',
      routerLink: this.routes.ITEMS,
    },
    {
      label: 'Customer Defaults',
      icon: 'pi pi-file-edit',
      routerLink: this.routes.CUSTOMER_DEFAULTS,
    },
    {
      label: 'Tax Authority',
      icon: 'pi pi-file-edit',
      routerLink: this.routes.TAX_AUTHORITY,
    },
    {
      label: 'Locations',
      icon: 'pi pi-file-edit',
      routerLink: this.routes.LOCATIONS,
    },
  ];

  siteMenuItems: MenuItem[] = [
    {
      label: 'Users',
      icon: 'pi pi-users',
      routerLink: this.routes.USERS,
    },
    {
      label: 'Carousel',
      icon: 'pi pi-palette',
      routerLink: this.routes.CAROUSEL,
    },
    {
      label: 'Shipping',
      icon: 'pi pi-gift',
      routerLink: this.routes.SHIPPING,
    },
    {
      label: 'States',
      icon: 'pi pi-globe',
      routerLink: this.routes.STATES,
    },
  ];

  constructor(private appState: AppStateService) {
    this.addPaymentSettingsMenuItem();
    this.addFirearmStoreMenuItems();
  }

  addPaymentSettingsMenuItem() {
    switch (this.appState.paymentType) {
      case PaymentType.GLOBALPAY:
      case PaymentType.GLOBALPAY_AUTH_ONLY: {
        this.siteMenuItems.push({
          label: 'Global Pay',
          icon: 'pi pi-dollar',
          routerLink: this.routes.GLOBAL_PAY,
        });
        break;
      }
    }
  }

  addFirearmStoreMenuItems() {
    this.siteMenuItems.push({
      label: 'FFL',
      icon: 'pi pi-building',
      routerLink: this.routes.FFLS,
    });
  }
}
