<p-toolbar>
  <div class="p-toolbar-group-start">
    <p-button label="Sync"
              icon="pi pi-sync"
              class="p-button-primary mr-2"
              (click)="onSyncClick()"
              [loading]="isSyncing">
    </p-button>
  </div>
</p-toolbar>

<p-table #dt
         dataKey="id"         
         [value]="(data$ | async)!"
         [loading]="(isLoading$ | async)!"
         [totalRecords]="(dataCount$ | async)!"
         [lazy]="true"
         [rows]="10"
         [showCurrentPageReport]="true"
         [rowsPerPageOptions]="[10, 25, 50]"
         [paginator]="true"
         [globalFilterFields]="['category.Description']"
         [filterDelay]="250"
         [breakpoint]="'960px'"
         [rowHover]="true"
         (onLazyLoad)="onLazyLoad($event)"
         currentPageReportTemplate="Showing {first} to {last} of {totalRecords} entries"
         sortField="category.Description"
         sortMode="single"
         responsiveLayout="stack">
  <ng-template pTemplate="caption">
    <div class="flex">
      Categories
      <span class="p-input-icon-left ml-auto">
        <i class="pi pi-search"></i>
        <input pInputText type="text" placeholder="Search keyword" (input)="applyFilterGlobal($event)" />
      </span>
    </div>
  </ng-template>
  <ng-template pTemplate="header">
    <tr>
      <th style="min-width:15rem" pSortableColumn="category.Description">
        <div class="flex align-items-center">
          Description
          <p-sortIcon field="category.Description"></p-sortIcon>
          <p-columnFilter type="text" field="category.Description" display="menu"></p-columnFilter>
        </div>
      </th>
      <th style="min-width:15rem" pSortableColumn="category.showOnMainNav">
        <div class="flex align-items-center">
          Show On Main Navigation
          <p-sortIcon field="egory.showOnMainNav"></p-sortIcon>
          <p-columnFilter type="text" field="category.showOnMainNav" display="menu"></p-columnFilter>
        </div>
      </th>
      <th style="width: 8rem"></th>
    </tr>
  </ng-template>
  <ng-template pTemplate="body" let-category>
    <tr [ngClass]="{'deleted-record': category.deleteDate}">
      <td>
        <span class="p-column-title"><strong>Description</strong></span>
        {{category.Description}}
      </td>
      <td>
        <span class="p-column-title"><strong>Show On Main Navigation</strong></span>
        {{category.showOnMainNav}}
      </td>
      <td style="text-align: center">
        <button pButton
                pRipple
                icon="pi pi-pencil"
                class="p-button-rounded p-button-primary mr-2"
                (click)="openEntity(category.CategoryNumber)"
                pTooltip="Edit"
                tooltipPosition="left">
        </button>
      </td>
    </tr>
  </ng-template>
  <ng-template pTemplate="emptymessage">
    <tr>
      <td colspan="7">No categories found.</td>
    </tr>
  </ng-template>
</p-table>
