import { NgModule, ModuleWithProviders, Optional, SkipSelf } from '@angular/core';
import { NgxAimConfig } from './ngx-aim.service';
import { LayoutModule } from '@angular/cdk/layout';

@NgModule({
  imports: [
    LayoutModule
  ],
})
export class NgxAimModule {
  constructor(@Optional() @SkipSelf() parentModule?: NgxAimModule) {
    if (parentModule) {
      throw new Error('NgxAimModule is already loaded. Import it in the app.config file only');
    }
  }

  static forRoot(config: NgxAimConfig): ModuleWithProviders<NgxAimModule> {
    return {
      ngModule: NgxAimModule,
      providers: [
        { provide: NgxAimConfig, useValue: config }
      ]
    }
  }
}
