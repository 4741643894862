<div class="flex flex-row flex-wrap justify-content-between">
  <div *ngIf="order?.accessoryShipRateId" class="flex">
    <div class="flex flex-column">
      <h3>{{shippingTitle}}</h3>
      <span><strong>Service Type:</strong> {{order.accessoryDelivery}}</span>
      <span><strong>Tracking Number:</strong> {{order.accessoryTrackingNumber}}</span>
      <ng-container *ngIf="!order.accessoryTrackingNumber; else accessoryHasTracking">
        <p-button (onClick)="onCreateLabelClick(itemTypes.ACCESSORY)"
                  [loading]="isSaving"
                  [disabled]="order.accessoryShipRateId?.startsWith('app-')"
                  label="CREATE LABEL"
                  class="ml-2 mt-2">
        </p-button>
      </ng-container>
      <ng-template #accessoryHasTracking>
        <p-button (onClick)="onViewLabelClick(order.accessoryDeliveryLabelPdfUrl)" label="VIEW LABEL" class="ml-2 mt-2"></p-button>
      </ng-template>
    </div>
  </div>
  <div *ngIf="order?.serializedShipRateId" class="flex">
    <div class="flex flex-column">
      <h3>FFL</h3>
      <span><strong>Service Type:</strong> {{order.serializedDelivery}}</span>
      <span><strong>Tracking Number:</strong> {{order.serializedTrackingNumber}}</span>
      <ng-container *ngIf="!order.serializedTrackingNumber; else serialHasTracking">
        <p-button (onClick)="onCreateLabelClick(itemTypes.SERIALIZED)"
                  [loading]="isSaving"
                  [disabled]="order.serializedShipRateId?.startsWith('app-')"
                  label="CREATE LABEL"
                  class="ml-2 mt-2">
        </p-button>
      </ng-container>
      <ng-template #serialHasTracking>
        <p-button (onClick)="onViewLabelClick(order.serializedLabelPdfUrl)" label="VIEW LABEL" class="ml-2 mt-2"></p-button>
      </ng-template>
    </div>
  </div>
</div>
