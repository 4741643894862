import { inject } from '@angular/core';
import { DynamicDialogConfig, DynamicDialogRef } from 'primeng/dynamicdialog';
import { ShipEngineAddressValidateResponse } from '../../../../models';

export abstract class NgxAimVerifiyAddressComponent {
  dialogRef = inject(DynamicDialogRef);
  config = inject(DynamicDialogConfig<ShipEngineAddressValidateResponse>);

  onUseAddressClick() {
    this.dialogRef.close(true);
  }

  onFixAddressClick() {
    this.dialogRef.close(false);
  }
}