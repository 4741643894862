<div class="col-12">
  <form [formGroup]="form" class="p-fluid p-formgrid grid dialog-form">
    <div class="field col-12 md:col-6">
      <span class="p-float-label">
        <input pInputText id="firstname" formControlName="firstName" />
        <label htmlFor="firstname">First Name</label>
      </span>
    </div>
    <div class="field col-12 md:col-6">
      <span class="p-float-label">
        <input pInputText id="lastname" formControlName="lastName" />
        <label htmlFor="lastName">Last Name</label>
      </span>
    </div>
    <div class="field col-12 md:col-6">
      <span class="p-float-label">
        <input pInputText id="email" formControlName="email" />
        <label htmlFor="email">Email</label>
      </span>
    </div>
    <div class="field col-12 md:col-6">
      <span class="p-float-label">
        <input pInputText id="phone" formControlName="phone" />
        <label htmlFor="phone">Phone</label>
      </span>
    </div>
    <div class="field col-12 md:col-6">
      <span class="p-float-label">
        <p-dropdown [options]="userTypes"
                    formControlName="userType"
                    placeholder="User Type"
                    inputId="userType"
                    [disabled]="!!form.get('id')?.value">
        </p-dropdown>
        <label htmlFor="userType">User Type</label>
      </span>
    </div>
  </form>
  <div class="flex justify-content-end">
    <p-button label="Save"
              icon="pi pi-check"
              styleClass="p-button-text"
              [loading]="isSaving"
              [disabled]="form.invalid"
              (onClick)="onSave()">
    </p-button>
    <p-button label="Set Password"
              icon="pi pi-lock"
              styleClass="p-button-text"
              [disabled]="form.invalid || isSaving || !form.get('id')?.value"
              (onClick)="onSetPasswordClick()">
    </p-button>
    <p-button label="Cancel"
              icon="pi pi-times"
              styleClass="p-button-text"
              [disabled]="isSaving"
              (onClick)="onCancel()">
    </p-button>
  </div>
  <p-divider align="left">
    <i class="pi pi-info-circle mr-2"></i>
    <b>Metadata</b>
  </p-divider>
</div>
