import { Component, Input, ChangeDetectionStrategy } from '@angular/core';
import { CommonModule } from '@angular/common';
import { DropdownModule } from 'primeng/dropdown';
import { FormsModule } from '@angular/forms';
import { SelectedAttribute, WebCategoryJsonAttribute } from '../../../../models';
import { NgxAttributeFilterComponent } from './ngx-attribute-filter.class';

@Component({
  selector: 'ngx-aim-attribute-filter',
  standalone: true,
  imports: [CommonModule, DropdownModule, FormsModule],
  templateUrl: './attribute-filter.component.html',
  styleUrls: ['./attribute-filter.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class NgxAimAttributeFilterComponent extends NgxAttributeFilterComponent {
  @Input()
  override attribute: WebCategoryJsonAttribute | undefined;

  @Input()
  override set selectedAttributes(attributes: SelectedAttribute[]) {
    for (const att of attributes) {
      if (att.Description === this.attribute?.Description) {
        this.selected = att.selectedValue;
        return;
      }
    }
    this.selected = null;
  }
}
