import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { NgxAimService } from '../../../ngx-aim.service';
import { AppLazyLoadEvent, FindAllResult, Location, SetLocationDto } from '../../../models';
import { Observable } from 'rxjs';
import { GenericSearchService, HttpErrorService } from '../../../services';

@Injectable({
  providedIn: 'root'
})
export class AdminLocationsService extends GenericSearchService<Location> {
  private baseUrl = `${this.ngxAimService.getApiUrl()}/admin/aim/locations`;

  constructor(
    private http: HttpClient,
    private ngxAimService: NgxAimService,
    private _httpErrorService: HttpErrorService
  ) {
    super(_httpErrorService, 'Locations');
  }

  sync(): Observable<void> {
    return this.http.post<void>(`${this.baseUrl}/sync`, {});
  }

  findAll(dto: AppLazyLoadEvent): Observable<FindAllResult<Location>> {
    return this.http.post<FindAllResult<Location>>(`${this.baseUrl}/all`, dto);
  }

  findOne(id: number): Observable<Location> {
    return this.http.get<Location>(`${this.baseUrl}/location/${id}`);
  }

  setDefault(dto: SetLocationDto): Observable<Location> {
    return this.http.post<Location>(`${this.baseUrl}/set-default`, dto);
  }
}
