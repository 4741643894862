<p-toolbar>
  <div class="p-toolbar-group-start">
    <button pButton pRipple label="New" icon="pi pi-plus" class="p-button-primary mr-2" (click)="onNewClick()"></button>
    <button pButton
            pRipple
            label="Delete"
            icon="pi pi-trash"
            class="p-button-danger mr-2"
            (click)="onDeleteClick($event)"
            [disabled]="!selectedData || !selectedData.length">
    </button>
    <p-confirmPopup key="onDeleteClick"></p-confirmPopup>
    <button pButton
            type="button"
            class="p-button-secondary" 
            icon="pi pi-cog"
            (click)="showOptions=true"
            pTooltip="Options">
    </button>
    <p-dialog header="Options" [(visible)]="showOptions" showEffect="fade" [style]="{width: '400px'}">
      <div class="p-grid options">
        <div class="col-12 md:col-6">
          <div class="p-field-checkbox">
            <p-checkbox name="options"
                        value="withDeleted"
                        [(ngModel)]="selectedOptions"
                        label="Include deleted">
            </p-checkbox>
          </div>
        </div>
      </div>
      <p-footer>
        <button pButton
                pRipple
                type="button"
                icon="pi pi-check"
                (click)="onOptionsClose('update')"
                label="OK"
                class="p-button-text">
        </button>
        <button pButton
                pRipple
                type="button"
                icon="pi pi-times"
                (click)="onOptionsClose('cancel')"
                label="Cancel"
                class="p-button-text">
        </button>
      </p-footer>
    </p-dialog>
  </div>
</p-toolbar>

<p-table #dt
         dataKey="id"         
         [value]="(data$ | async)!"
         [loading]="(isLoading$ | async)!"
         [totalRecords]="(dataCount$ | async)!"
         [lazy]="true"
         [rows]="10"
         [showCurrentPageReport]="true"
         [rowsPerPageOptions]="[10, 25, 50]"
         [paginator]="true"
         [globalFilterFields]="['user.firstName', 'user.lastName', 'user.email']"
         [filterDelay]="250"
         [breakpoint]="'960px'"
         [rowHover]="true"
         [(selection)]="selectedData"
         (onLazyLoad)="onLazyLoad($event)"
         currentPageReportTemplate="Showing {first} to {last} of {totalRecords} entries"
         sortField="user.lastName"
         sortMode="single"
         responsiveLayout="stack">
  <ng-template pTemplate="caption">
    <div class="flex">
      Users
      <span class="p-input-icon-left ml-auto">
        <i class="pi pi-search"></i>
        <input pInputText type="text" placeholder="Search keyword" (input)="applyFilterGlobal($event)" />
      </span>
    </div>
  </ng-template>
  <ng-template pTemplate="header">
    <tr>
      <th style="width: 3rem">
        <p-tableHeaderCheckbox></p-tableHeaderCheckbox>
      </th>
      <th style="min-width:15rem" pSortableColumn="user.firstName">
        <div class="flex align-items-center">
          First Name
          <p-sortIcon field="user.firstName"></p-sortIcon>
          <p-columnFilter type="text" field="user.firstName" display="menu"></p-columnFilter>
        </div>
      </th>
      <th style="min-width:15rem" pSortableColumn="user.lastName">
        <div class="flex align-items-center">
          Last Name
          <p-sortIcon field="user.lastName"></p-sortIcon>
          <p-columnFilter type="text" field="user.lastName" display="menu"></p-columnFilter>
        </div>
      </th>
      <th style="min-width:15rem" pSortableColumn="user.email">
        <div class="flex align-items-center">
          Email
          <p-sortIcon field="user.email"></p-sortIcon>
          <p-columnFilter type="text" field="user.email" display="menu"></p-columnFilter>
        </div>
      </th>
      <th style="min-width:15rem" pSortableColumn="user.userType">
        <div class="flex align-items-center">
          Type
          <p-sortIcon field="user.userType"></p-sortIcon>
          <p-columnFilter field="user.userType" matchMode="equals" display="menu">
            <ng-template pTemplate="filter" let-value let-filter="filterCallback">
              <p-dropdown [ngModel]="value" [options]="userTypes" (onChange)="filter($event.value)" placeholder="Any" [showClear]="true">
                <ng-template let-option pTemplate="item">
                  <p-tag [value]="option"></p-tag>
                </ng-template>
              </p-dropdown>
            </ng-template>
          </p-columnFilter>
        </div>
      </th>
      <th style="width: 8rem"></th>
    </tr>
  </ng-template>
  <ng-template pTemplate="body" let-user>
    <tr [ngClass]="{'deleted-record': user.deleteDate}">
      <td>
        <p-tableCheckbox [value]="user" [disabled]="user.deleteDate"></p-tableCheckbox>
      </td>
      <td>
        <span class="p-column-title"><strong>Frist Name</strong></span>
        {{user.firstName}}
      </td>
      <td>
        <span class="p-column-title"><strong>Last Name</strong></span>
        {{user.lastName}}
      </td>
      <td>
        <span class="p-column-title"><strong>Email</strong></span>
        {{user.email}}
      </td>
      <td>
        <span class="p-column-title"><strong>Type</strong></span>
        {{user.userType}}
      </td>
      <td style="text-align: center">
        <button pButton
                pRipple
                icon="pi pi-pencil"
                class="p-button-rounded p-button-primary mr-2"
                (click)="openEntity(user.id)"
                pTooltip="Edit"
                tooltipPosition="left">
        </button>
      </td>
    </tr>
  </ng-template>
  <ng-template pTemplate="emptymessage">
    <tr>
      <td colspan="7">No users found.</td>
    </tr>
  </ng-template>
</p-table>
