import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'productActionButtonLabel',
  standalone: true,
  pure: true
})
export class ProductActionButtonLabelPipe implements PipeTransform {
  transform(isRental: boolean): string {
    return isRental ? 'RENT' : '';
  }
}
