import { Injectable } from '@angular/core';
import { NgxAimService } from '../../../ngx-aim.service';
import { HttpClient, HttpParams } from '@angular/common/http';
import { Observable } from 'rxjs';
import { Ffl } from '../../../models';

@Injectable({
  providedIn: 'root'
})
export class NgxFflSelectorService {
  baseUrl = `${this.ngxAimService.getApiUrl()}/ffl`;

  constructor(
    private ngxAimService: NgxAimService,
    private http: HttpClient,
  ) {}

  findAll(zipcode: string, distance: string, state: string): Observable<Ffl[]> {
    return this.http.get<Ffl[]>(this.baseUrl, {
      params: new HttpParams()
        .set('zip', zipcode)
        .set('distance', distance)
        .set('state', state)
    });
  }

  create(dto: Partial<Ffl>): Observable<Ffl> {
    return this.http.post<Ffl>(`${this.baseUrl}`, dto);
  }
}
