import { Observable } from 'rxjs';
import { PickerHours, PickerMeridiem, PickerMinutes } from '../constants';
import { ItemForDisplay } from './item.model';
import { MenuItem } from 'primeng/api';

export interface AppBaseEntity {
  id: number | null;
  createDate: Date;
  updateDate: Date;
  deleteDate: Date;
}

export interface CountryState {
  id: number;
  abbreviation: string;
  name: string;
  isActive: boolean;
}

export interface BusinessSettings {
  name: string;
  websiteUrl: string;
  fromEmail: string;
  phone: string;
  phoneUrl: string;
  email: string;
  address1: string;
  address2: string;
  city: string;
  state: string;
  postalCode: string;
  tz: string;
  tax: number;
}

export interface AppUploadEvent {
  originalEvent: any;
  files: File[];
}

export interface TimePickerForm {
  hour: PickerHours;
  minute: PickerMinutes;
  meridiem: PickerMeridiem;
};

export interface TimePickers {
  startDate?: TimePickerForm;
  endDate?: TimePickerForm;
}

export type TimePickerType = 'startDate' | 'endDate';

export type ProductsVM = {
  data: Observable<ItemForDisplay[]>;
  dataCount: Observable<number>;
  rows: Observable<number>;
  first: Observable<number>;
  sortBy: Observable<number>;
}

export interface MenuCommandEvent {
  item: MenuItem;
  orginalEvent: MouseEvent;
}
