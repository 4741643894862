import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { CountryState } from '../models';
import { NgxAimService } from '../ngx-aim.service';
import { HttpErrorService } from './http-error.service';
@Injectable({
  providedIn: 'root'
})
export class CountryStateService {
  private statesSubject = new BehaviorSubject<CountryState[]>([]);
  public states$ = this.statesSubject.asObservable();
  private isLoading = false;
  private baseUrl = `${this.ngxAimService.getApiUrl()}/country-states`;

  constructor(
    private http: HttpClient,
    private ngxAimService: NgxAimService,
    private httpErrorService: HttpErrorService,
  ) {}

  initStates() {
    if (this.isLoading || this.statesSubject.value.length) {
      return;
    }

    this.isLoading = true;
    this.findAll().subscribe({
      next: (states) => {
        this.statesSubject.next(states);
        this.isLoading = false
      },
      error: (err) => {
        this.httpErrorService.onHttpError(err, 'Could not load shipping states');
        this.isLoading = false;
      },
    });
  }

  findAll(): Observable<CountryState[]> {
    return this.http.get<CountryState[]>(`${this.baseUrl}`);
  }
}
