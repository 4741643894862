<div class="p-fluid p-formgrid grid">
  <div class="col-4">
    <p-inputNumber inputId="integeronly" prefix="$" [min]="0" [(ngModel)]="min" placeholder="Min"></p-inputNumber>
  </div>
  <div class="col-4">
    <p-inputNumber inputId="integeronly" prefix="$" [min]="0" [(ngModel)]="max" placeholder="Max"></p-inputNumber>
  </div>
  <div class="col-3">
    <p-button styleClass="p-button-rounded" (onClick)="onGoClick()">GO</p-button>
  </div>
</div>
