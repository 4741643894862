import { Component, Input, ChangeDetectionStrategy } from '@angular/core';
import { CommonModule } from '@angular/common';
import { GalleriaModule } from 'primeng/galleria';
import { ImageInfo } from '../../../../models';
import { NgxProductGalleriaComponent } from './ngx-product-galleria.class';

@Component({
  selector: 'ngx-aim-product-galleria',
  standalone: true,
  imports: [CommonModule, GalleriaModule],
  templateUrl: './product-galleria.component.html',
  styleUrls: ['./product-galleria.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class NgxAimProductGalleriaComponent extends NgxProductGalleriaComponent {
  @Input()
  override images: ImageInfo[] = [];
}
