<p-toolbar>
  <div class="p-toolbar-group-start">
    <!-- Web categories will now sync every time an item sync happens -->
    <!-- <p-button label="Sync"
              icon="pi pi-sync"
              class="p-button-primary mr-2"
              (click)="onSyncClick()"
              [loading]="isSyncing">
    </p-button> -->
  </div>
</p-toolbar>

<p-table #dt
         dataKey="id"         
         [value]="(data$ | async)!"
         [loading]="(isLoading$ | async)!"
         [totalRecords]="(dataCount$ | async)!"
         [lazy]="true"
         [rows]="10"
         [showCurrentPageReport]="true"
         [rowsPerPageOptions]="[10, 25, 50]"
         [paginator]="true"
         [globalFilterFields]="['web_category.Name', 'web_category.Description', 'web_category.FullPath']"
         [filterDelay]="250"
         [breakpoint]="'960px'"
         [rowHover]="true"
         (onLazyLoad)="onLazyLoad($event)"
         currentPageReportTemplate="Showing {first} to {last} of {totalRecords} entries"
         sortField="web_category.Id"
         sortMode="single"
         responsiveLayout="stack">
  <ng-template pTemplate="caption">
    <div class="flex">
      Categories
      <span class="p-input-icon-left ml-auto">
        <i class="pi pi-search"></i>
        <input pInputText type="text" placeholder="Search keyword" (input)="applyFilterGlobal($event)" />
      </span>
    </div>
  </ng-template>
  <ng-template pTemplate="header">
    <tr>
      <th style="min-width:15rem" pSortableColumn="web_category.Name">
        <div class="flex align-items-center">
          Name
          <p-sortIcon field="web_category.Name"></p-sortIcon>
          <p-columnFilter type="text" field="web_category.Name" display="menu"></p-columnFilter>
        </div>
      </th>
      <th style="min-width:15rem" pSortableColumn="web_category.FullPath">
        <div class="flex align-items-center">
          Full path
          <p-sortIcon field="web_category.FullPath"></p-sortIcon>
          <p-columnFilter type="text" field="web_category.FullPath" display="menu"></p-columnFilter>
        </div>
      </th>
      <th style="min-width:15rem" pSortableColumn="web_category.Description">
        <div class="flex align-items-center">
          Description
          <p-sortIcon field="web_category.Description"></p-sortIcon>
          <p-columnFilter type="text" field="web_category.Description" display="menu"></p-columnFilter>
        </div>
      </th>
      <th style="min-width:15rem" pSortableColumn="web_category.Unpublished">
        <div class="flex align-items-center">
          Unpublished
          <p-sortIcon field="web_category.Unpublished"></p-sortIcon>
          <p-columnFilter type="text" field="web_category.Unpublished" display="menu"></p-columnFilter>
        </div>
      </th>
      <th style="min-width:15rem" pSortableColumn="web_category.showSubCatImages">
        <div class="flex align-items-center">
          Show Sub Cat Images
          <p-sortIcon field="web_category.showSubCatImages"></p-sortIcon>
          <p-columnFilter type="text" field="web_category.showSubCatImages" display="menu"></p-columnFilter>
        </div>
      </th>
      <th style="width: 8rem"></th>
    </tr>
  </ng-template>
  <ng-template pTemplate="body" let-web_category>
    <tr [ngClass]="{'deleted-record': web_category.deleteDate}">
      <td>
        <span class="p-column-title"><strong>Name</strong></span>
        {{web_category.Name}}
      </td>
      <td>
        <span class="p-column-title"><strong>Full Path</strong></span>
        {{web_category.FullPath}}
      </td>
      <td>
        <span class="p-column-title"><strong>Description</strong></span>
        {{web_category.Description}}
      </td>
      <td>
        <span class="p-column-title"><strong>Unpublished</strong></span>
        {{web_category.Unpublished}}
      </td>
      <td>
        <span class="p-column-title"><strong>Show Sub Cat Images</strong></span>
        {{web_category.showSubCatImages}}
      </td>
      <td style="text-align: center">
        <button pButton
                pRipple
                icon="pi pi-pencil"
                class="p-button-rounded p-button-primary mr-2"
                (click)="openEntity(web_category.Id)"
                pTooltip="Edit"
                tooltipPosition="left">
        </button>
      </td>
    </tr>
  </ng-template>
  <ng-template pTemplate="emptymessage">
    <tr>
      <td colspan="7">No categories found.</td>
    </tr>
  </ng-template>
</p-table>
