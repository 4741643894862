export enum StoreType {
  GENERIC = 'generic',
  FIREARMS = 'firearms',
}

export const StoreTypes = [StoreType.GENERIC, StoreType.FIREARMS];

export enum AppMode {
  DEVELOPMENT = 'development',
  PRODUCTION = 'production',
}

export const AppModes = [AppMode.DEVELOPMENT, AppMode.PRODUCTION];
