import { inject } from '@angular/core';
import { CustomerService, HttpErrorService } from '../../services';
import { PublicRoutes } from '../../enums';
import { CustomerHistoryGeneric, GetCustomerHistoryDto } from '../../models';

/** when extending this class be sure to implement ngOnInit */
export abstract class NgxOpenInvoicesComponent {
  // ngOnInit(): void {
  //   this.init();
  // }

  customerService = inject(CustomerService);
  httpErrorService = inject(HttpErrorService);

  routes = PublicRoutes;
  history: CustomerHistoryGeneric[] = [];
  isLoading = false;

  init(): void {
    this.getCustomerHistory();
  }

  getCustomerHistory() {
    const dto: GetCustomerHistoryDto = {
      GetPaymentsDue: true,
    }
    this.customerService.getCustomerHistory(dto).subscribe({
      next: (history) => this.onGetHistorySuccess(history),
      error: (err) => this.onHistoryFailure(err),
    });
  }

  onGetHistorySuccess(history: any) {
    this.isLoading = false;
    this.history = history;
  }

  onHistoryFailure(err: any) {
    this.isLoading = false;
    this.httpErrorService.onHttpError(err, 'Could not load history');
  }
}