import { Component, OnInit } from '@angular/core';
import { CommonModule } from '@angular/common';
import { NonNullableFormBuilder } from '@angular/forms';
import { DynamicDialogConfig, DynamicDialogRef } from 'primeng/dynamicdialog';
import { ReactiveFormsModule } from '@angular/forms';
import { MessageService } from 'primeng/api';
import { AdminPrimengModule } from '../../../admin.primeng.module';
import { TrueFalseText } from '../../../../../types';
import { CountryState } from '../../../../../models';
import { HttpErrorService, UtilsService } from '../../../../../services';
import { AdminCountryStateService } from '../admin-country-state.service';

@Component({
  selector: 'aim-edit-country-states',
  standalone: true,
  imports: [CommonModule, ReactiveFormsModule, AdminPrimengModule],
  templateUrl: './edit-country-states.component.html',
  styleUrls: ['./edit-country-states.component.scss', '../../../admin.scss']
})
export class EditCountryStatesComponent implements OnInit {
  dropDownOptions: TrueFalseText[] = ['True', 'False'];

  form = this.fb.group({
    id: this.fb.control<number>(0),
    name: this.fb.control<string>(''),
    abbreviation: this.fb.control<string>(''),
    isActive: this.fb.control<TrueFalseText>('False'),
  });

  isLoading = false;
  isSaving = false;

  constructor(
    private entityService: AdminCountryStateService,
    private fb: NonNullableFormBuilder,
    private config: DynamicDialogConfig,
    private dialogRef: DynamicDialogRef,
    private messageService: MessageService,
    private httpErrorService: HttpErrorService,
    private utils: UtilsService,
  ) {}

  ngOnInit() {
    this.form.get('name')?.disable();
    this.form.get('abbreviation')?.disable();

    if (typeof this.config.data === 'number') {
      this.getEntity(this.config.data);
    }
  }

  getEntity(entityId: number) {
    this.isLoading = true;
    this.entityService.findOne(entityId).subscribe({
      next: (entity) => this.setFormData(entity),
      error: (err) => this.onError(err, 'Could not retrieve state')
    });
  }

  setFormData(entity: CountryState) {
    this.isLoading = false;
    this.form.patchValue({
      ...entity,
      isActive: this.utils.convertBooleanToTrueFalseText(entity.isActive),
    });
  }

  onSave() {
    this.isSaving = true;
    const dto: CountryState = {
      id: this.form.get('id')!.value,
      name: this.form.get('name')!.value,
      abbreviation: this.form.get('abbreviation')!.value,
      isActive: this.utils.convertTrueFalseTextToBoolean(this.form.get('isActive')!.value),
    }
    this.entityService.save(dto).subscribe({
      next: (entity) => this.onSuccess(entity),
      error: (err) => this.onError(err, 'Could not save state')
    });
  }

  onCancel() {
    this.dialogRef.close();
  }

  onError(err: any, message: string) {
    this.isSaving = false;
    this.isLoading = false;
    this.httpErrorService.onHttpError(err, message);
  }

  onSuccess(entity: CountryState) {
    this.setFormData(entity);
    this.isSaving = false;
    this.messageService.add({
      severity: 'success',
      summary: 'State saved',
      key: 'app-toast'
    });
  }
}
