<p-toolbar>
  <div class="p-toolbar-group-start">
    <button pButton pRipple label="New" icon="pi pi-plus" class="p-button-primary mr-2" (click)="onNewClick()"></button>

    <p-dropdown [options]="carouselNames" [(ngModel)]="selectedCarousel" (onChange)="onCarouselChange()"></p-dropdown>
  </div>
</p-toolbar>
<p-orderList [value]="slides"
             [listStyle]="{ 'max-height': '30rem' }"
             header="Slides"
             [dragdrop]="true"
             [stripedRows]="true"
             (onReorder)="onReorder($event)">
  <ng-template let-slide pTemplate="item">
    <div class="flex align-items-center p-2 w-full flex-wrap">
      <div class="w-full text-center lg:w-auto lg:text-left">
        <img [src]="'files/' + slide.mobileSrc" [alt]="slide.name" class="mr-3 shadow-3 w-7rem mb-3 lg:w-5rem lg:mb-auto" />
      </div>
      <div class="w-full text-center lg:w-auto lg:text-left">
        <img [src]="'files/' + slide.src" [alt]="slide.name" class="mr-3 shadow-3 w-7rem mb-3 lg:w-5rem lg:mb-auto" />
      </div>
      <div class="flex-1">
        <h5 class="mb-2">{{ slide.name }}</h5>
        <i class="pi pi-tag vertical-align-middle mr-2"></i>
        <span class="vertical-align-middle line-height-1">category</span>
      </div>
      <div class="flex flex-column align-items-end z-5 gap-1">
        <p-button icon="pi pi-pencil" styleClass="p-button-rounded" (onClick)="openEntity(slide.id)"></p-button>
        <p-button icon="pi pi-trash" styleClass="p-button-rounded p-button-danger" (onClick)="onDeleteClick($event, slide.id)"></p-button>
      </div>
    </div>
  </ng-template>
</p-orderList>
<p-confirmPopup key="onDeleteClick"></p-confirmPopup>
