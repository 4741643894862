import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { NgxAimService } from '../../../ngx-aim.service';
import { AppLazyLoadEvent, FindAllResult, SetTaxAuthorityDto, TaxAuthority } from '../../../models';
import { Observable } from 'rxjs';
import { GenericSearchService, HttpErrorService } from '../../../services';

@Injectable({
  providedIn: 'root'
})
export class AdminTaxAuthorityService extends GenericSearchService<TaxAuthority> {
  private baseUrl = `${this.ngxAimService.getApiUrl()}/admin/aim/tax-authority`;

  constructor(
    private http: HttpClient,
    private ngxAimService: NgxAimService,
    private _httpErrorService: HttpErrorService
  ) {
    super(_httpErrorService, 'Tax authority');
  }

  sync(): Observable<void> {
    return this.http.post<void>(`${this.baseUrl}/sync`, {});
  }

  findAll(dto: AppLazyLoadEvent): Observable<FindAllResult<TaxAuthority>> {
    return this.http.post<FindAllResult<TaxAuthority>>(`${this.baseUrl}/all`, dto);
  }

  findOne(id: number): Observable<TaxAuthority> {
    return this.http.get<TaxAuthority>(`${this.baseUrl}/tax-authority/${id}`);
  }

  setDefault(dto: SetTaxAuthorityDto): Observable<TaxAuthority> {
    return this.http.post<TaxAuthority>(`${this.baseUrl}/set-default`, dto);
  }
}
