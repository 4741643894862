<div class="col-12">
  <form [formGroup]="form" class="p-fluid p-formgrid grid dialog-form">
    <div class="field col-12 md:col-12">
      <span class="p-float-label">
        <input pInputText id="name" formControlName="name" />
        <label htmlFor="name">Name</label>
      </span>
    </div>
    <div class="field col-12 md:col-12">
      <span>
        <span>Desktop Image</span>
        <p-fileUpload url="api/v1/admin/carousel-slide/image"
                      name="src"
                      accept="image/*"
                      [multiple]="false"
                      [headers]="headers"
                      (onUpload)="onUpload($event, false)"
                      (onError)="onUploadError($event)">
          <ng-template pTemplate="content"></ng-template>
        </p-fileUpload>
        <div *ngIf="form.get('src')?.value">
          <img class="mt-1" [src]="'files/' + form.get('src')?.value" width="100%" />
        </div>
      </span>
    </div>
    <div class="field col-12 md:col-12">
      <span>
        <span>Mobile Image</span>
        <p-fileUpload url="api/v1/admin/carousel-slide/image"
                      name="mobileSrc"
                      accept="image/*"
                      [multiple]="false"
                      [headers]="headers"
                      (onUpload)="onUpload($event, true)"
                      (onError)="onUploadError($event)">
          <ng-template pTemplate="content"></ng-template>
        </p-fileUpload>
        <div *ngIf="form.get('mobileSrc')?.value">
          <img class="mt-1" [src]="'files/' + form.get('mobileSrc')?.value" width="100%" />
        </div>
      </span>
    </div>
  </form>
  <div class="flex justify-content-end">
    <p-button label="Save"
              icon="pi pi-check"
              styleClass="p-button-text"
              [loading]="isSaving"
              [disabled]="form.invalid"
              (onClick)="onSave()">
    </p-button>
    <p-button label="Cancel"
              icon="pi pi-times"
              styleClass="p-button-text"
              [disabled]="isSaving"
              (onClick)="onCancel()">
    </p-button>
  </div>
</div>
