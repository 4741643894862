import { ViewContainerRef, QueryList, inject } from '@angular/core';
import { AccordionTab, AccordionTabOpenEvent } from 'primeng/accordion';
import { distinctUntilChanged } from 'rxjs';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { Router } from '@angular/router';
import { AppStateService, AuthenticationService, ContractsCheckoutService, ContractsService, PaymentService } from '../../services';
import { AddressType, CheckoutTabNames, CheckoutType, PaymentType, PublicRoutes, TransactionType } from '../../enums';
import { CheckoutTabsDisabled, CheckoutTotals } from '../../models';

/** when extending this class be sure to implement ngOnInit, ngAfterViewInit,
 *  @ViewChild('paymentForm', { read: ViewContainerRef })
 *  override paymentFormRef!: ViewContainerRef;
 * 
 *  @ViewChildren('accordionTab')
 *  override accordionTabs!: QueryList<AccordionTab>;
 */
export abstract class NgxContractPaymentComponent {
  // ngOnInit() {
  //   this.init();
  // }

  // ngAfterViewInit(): void {
  //   this.afterViewInit();
  // }

  contractsService = inject(ContractsService);
  checkoutService = inject(ContractsCheckoutService);
  router = inject(Router);
  appState = inject(AppStateService);
  authService = inject(AuthenticationService);
  paymentService = inject(PaymentService);

  paymentFormRef!: ViewContainerRef;
  accordionTabs!: QueryList<AccordionTab>;

  tabs = CheckoutTabNames;
  tabIndex$ = this.checkoutService.tabIndex$.pipe(
    distinctUntilChanged(),
    takeUntilDestroyed(),
  );
  tabsDisabled$ = this.checkoutService.tabsDisabed$.pipe(
    distinctUntilChanged(),
    takeUntilDestroyed(),
  );
  totals$ = this.checkoutService.totals$.pipe(
    distinctUntilChanged(),
    takeUntilDestroyed(),
  );
  isLoadingTotals$ = this.checkoutService.isLoadingTotals$.pipe(
    distinctUntilChanged(),
    takeUntilDestroyed(),
  );
  totals: CheckoutTotals = {
    items: 0,
    shipping: 0,
    tax: 0,
    total: 0,
  }
  publicRoutes = PublicRoutes;
  tabsDisabled: CheckoutTabsDisabled = {};
  addressType = AddressType;
  checkoutType = CheckoutType.CONTRACT;
  cartHasSerializedItem = false;
  cartHasAccessoryItem = false;
  acceptPayments = true;

  init() {
    if (!this.appState.isBrowser) {
      return;
    }

    if (!this.authService.getCurrentUser()) {
      this.router.navigate(['/', PublicRoutes.SIGN_IN], {
        queryParams: { returnUrl: `/${PublicRoutes.CONTRACTS}/${PublicRoutes.PAYMENT}`, isCheckout: true }
      });
      return;
    }

    this.checkoutService.calculateTotals();
    this.acceptPayments = this.appState.paymentType !== PaymentType.NONE;

    if (!this.contractsService.selectedGrade) {
      this.router.navigate(['/']);
      return;
    }

    this.tabsDisabled$.subscribe((tabs) => {
      this.tabsDisabled = tabs;
    });
    this.totals$.subscribe(totals => {
      this.totals = totals;
    });
  }

  afterViewInit(): void {
    if (!this.appState.isBrowser || !this.authService.getCurrentUser()) {
      return;
    }

    this.initPaymentForm(this.appState.paymentType);
    this.checkoutService.tabs = this.accordionTabs.map((tab, i) => ({ header: tab.header!, index: i }));
  }

  initPaymentForm(type: PaymentType) {
    this.paymentService.cardData.type = type;
    switch (type) {
      case PaymentType.AUTHORIZENET: {
        import('../payment/ngx-authorizenet/ngx-authorizenet.component').then(c => {
          const comp = this.paymentFormRef.createComponent(c.NgxAuthorizenetComponent);
          comp.instance.checkoutService = this.checkoutService;
        });
        break;
      }
      case PaymentType.GLOBALPAY:
      case PaymentType.GLOBALPAY_AUTH_ONLY: {
        import('../payment/ngx-globalpay/ngx-globalpay.component').then(c => {
          const comp = this.paymentFormRef.createComponent(c.NgxGlobalpayComponent);
          comp.instance.checkoutService = this.checkoutService;
        });
        break;
      }
      case PaymentType.TSYS_AUTH_ONLY: {
        import('../payment/ngx-tsys/ngx-tsys.component').then(c => {
          const comp = this.paymentFormRef.createComponent(c.NgxTsysComponent);
          comp.instance.checkoutService = this.checkoutService;
          comp.instance.transactionType = TransactionType.CONTRACT;
        });
        break;
      }
    }
  }

  onTabOpen($event: AccordionTabOpenEvent) {
    this.checkoutService.setTabIndex($event.index);
  }
}