/** List of ways items can be sorted
 * @readonly
 * @enum {number}
 */
export enum ItemSortBy {
  NEWEST,
  LOWEST_PRICE,
  HIGHEST_PRICE,
}

/** Valid selections the user can make from the front end */
export const ItemPageSizes = [12, 24, 36, 48];
