import { Component } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ButtonModule } from 'primeng/button';
import { NgxAimVerifiyAddressComponent } from './ngx-aim-verify-address.class';

@Component({
  selector: 'ngx-verify-address',
  standalone: true,
  imports: [CommonModule, ButtonModule],
  templateUrl: './ngx-verify-address.component.html',
  styleUrls: ['./ngx-verify-address.component.css']
})
export class NgxVerifyAddressComponent extends NgxAimVerifiyAddressComponent {}
