import { Component, OnInit } from '@angular/core';
import { CommonModule, CurrencyPipe } from '@angular/common';
import { RouterModule } from '@angular/router';
import { NgxAimProductGalleriaComponent } from './product-galleria/product-galleria.component';
import { ButtonModule } from 'primeng/button';
import { DialogService } from 'primeng/dynamicdialog';
import { ProgressSpinnerModule } from 'primeng/progressspinner';
import { NgxAimRentalPriceComponent } from './rental-price/rental-price.component';
import { OutOfStockPipe, ProductActionButtonLabelPipe } from '../../../pipes';
import { NgxProductComponent } from './ngx-product.class';

@Component({
  selector: 'ngx-aim-product',
  standalone: true,
  imports: [
    CommonModule,
    RouterModule,
    NgxAimProductGalleriaComponent,
    ButtonModule,
    ProgressSpinnerModule,
    ProductActionButtonLabelPipe,
    NgxAimRentalPriceComponent,
    OutOfStockPipe,
    CurrencyPipe,
  ],
  templateUrl: './product.component.html',
  styleUrls: ['./product.component.scss'],
  providers: [DialogService],
})
export class NgxAimProductComponent extends NgxProductComponent implements OnInit {
  ngOnInit() {
    this.init();
  }
}
