<div class="col-12">
  <h2>{{config.data?.friendly_name}}</h2>
  <div *ngFor="let service of config.data?.services; let i = index;" class="mb-3">
    <p-checkbox 
                [(ngModel)]="service.active"
                [binary]="true"
                [inputId]="service.service_code">
    </p-checkbox>
    <label class="ml-2" [for]="service.service_code">{{service.name}}</label>
  </div>
  <div class="flex justify-content-end">
    <p-button label="Save"
              icon="pi pi-check"
              styleClass="p-button-text"
              [loading]="isSaving"
              (onClick)="onSave()">
    </p-button>
    <p-button label="Cancel"
              icon="pi pi-times"
              styleClass="p-button-text"
              [disabled]="isSaving"
              (onClick)="onCancel()">
    </p-button>
  </div>
</div>
<div class="progress-spinner" *ngIf="isSaving">
  <p-progressSpinner></p-progressSpinner>
</div>
