<div class="col-12">
  <form [formGroup]="form" class="p-fluid p-formgrid grid dialog-form">
    <div class="field col-12 md:col-6">
      <span class="p-float-label">
        <p-password id="password" formControlName="password" [toggleMask]="true" appendTo="body">
          <ng-template pTemplate="header">
              <h6>Pick a password</h6>
          </ng-template>
          <ng-template pTemplate="footer">
              <p-divider></p-divider>
              <p class="mt-2">Requirements</p>
              <ul class="pl-2 ml-2 mt-0" style="line-height: 1.5">
                  <li>Minimum 8 characters</li>
              </ul>
          </ng-template>
      </p-password>
        <label htmlFor="password">Password</label>
      </span>
    </div>
    <div class="field col-12 md:col-6">
      <span class="p-float-label">
        <p-password id="confirmPassword" formControlName="confirmPassword" [toggleMask]="true" [feedback]="false" />
        <label htmlFor="confirmPassword">Confirm Password</label>
      </span>
    </div>
  </form>
  <div class="flex justify-content-end">
    <p *ngIf="f.password.valid && f.confirmPassword.valid && form.errors?.['passwordsNotMatched']" class="error">Passwords do not match</p>
    <p-button label="Save"
              icon="pi pi-check"
              styleClass="p-button-text"
              [loading]="isSaving"
              [disabled]="form.invalid"
              (onClick)="onSave()">
    </p-button>
    <p-button label="Cancel"
              icon="pi pi-times"
              styleClass="p-button-text"
              [disabled]="isSaving"
              (onClick)="onCancel()">
    </p-button>
  </div>
</div>
