<p-blockUI [target]="deliveryOptions" [blocked]="(loading$ | async)!" styleClass="border-round-lg">
  <p-progressSpinner></p-progressSpinner>
</p-blockUI>
<ngx-p-blockable-div #deliveryOptions>
  <div class="flex flex-column gap-3" style="min-height: 90px;">
    <div *ngFor="let option of options$ | async" class="field-checkbox">
      <p-radioButton [inputId]="option.rate_id" name="option" [value]="option" [(ngModel)]="selectedOption"></p-radioButton>
      <label [for]="option.rate_id" class="ml-2">{{option.service_type}} &mdash; {{option.total_amount | currency}}</label>
    </div>
  </div>
  <p-button (onClick)="onSelectOptionCLick()" [disabled]="!this.selectedOption">Use this option</p-button>
</ngx-p-blockable-div>
