import { inject } from '@angular/core';
import { FormBuilder, Validators } from '@angular/forms';
import { STRRate, STRAmount, STRCalcSubCatAmountDto, TimePickerForm, TimePickerType, STRData, TimePickers } from '../../../../models';
import { startDateBeforeEndDateValidator } from '../../../../validators/start-date-is-before-end-date.validator';
import { distinctUntilChanged } from 'rxjs';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { AppStateService, ShortTermRentalCheckoutService, ShortTermRentalService } from '../../../../services';
import { CheckoutTabNames, PaymentType, STRInterval } from '../../../../enums';

/** when extending this class be sure to implement ngOnInit */
export abstract class NgxAimRentalCheckAvailabilityComponent {
  // ngOnInit() {
  //   this.init();
  // }

  fb = inject(FormBuilder);
  rentalService = inject(ShortTermRentalService);
  checkoutService = inject(ShortTermRentalCheckoutService);
  appState = inject(AppStateService);

  form = this.fb.group({
    startDate: this.fb.control<Date | null>({ value: null, disabled: true }, { validators: [Validators.required] }),
    endDate: this.fb.control<Date | null>({ value: null, disabled: true }, { validators: [Validators.required] }),
    rate: this.fb.control<STRRate | null>(null, { validators: [Validators.required] }),
  }, { validators: [startDateBeforeEndDateValidator] });

  changes$ = this.form.valueChanges.pipe(
    distinctUntilChanged(),
    takeUntilDestroyed(),
  );

  rentalData = this.checkoutService.getRentalData();
  rates: STRRate[] = [];
  amount?: STRAmount | null;
  minDate = new Date();
  timePickers: TimePickers = {}
  actionButtonText: 'CHECK AVAILABILITY' | 'CONTINUE' = 'CHECK AVAILABILITY';
  error = '';
  amountError = '';
  isLoading = false;
  isLoadingAmount = false;
  showTime = false;
  startTimeDisabled = true;
  endTimeDisabled = true;
  acceptPayments = true;

  init() {
    this.getRates();
    this.initValueChanges();
    this.acceptPayments = this.appState.paymentType !== PaymentType.NONE;
  }

  getRates() {
    this.form.controls.startDate.enable();
    this.form.controls.endDate.enable();
    this.rentalService.getRateForSubCat(this.rentalData.subCategory).subscribe({
      next: (res) => {
        this.isLoading = false;
        this.rates = res.Rates;
        this.form.controls.startDate.enable();
        this.form.controls.endDate.enable();
      },
      error: (err) => {
        this.isLoading = false;
        this.error = 'Please call for pricing';
      }
    });
  }

  initValueChanges() {
    this.changes$.subscribe((res) => {
      if (this.actionButtonText !== 'CHECK AVAILABILITY') {
        this.actionButtonText = 'CHECK AVAILABILITY';
        this.amount = null;
      }

      this.showTime = res.rate?.IntervalType === 'Hours' ? true : false;
      if (!this.showTime) {
        this.timePickers = {};
      }

      if (this.form.controls.startDate.valid) {
        this.startTimeDisabled = false;
      } else {
        this.startTimeDisabled = true;
      }

      if (this.form.controls.endDate.valid) {
        this.endTimeDisabled = false;
      } else {
        this.endTimeDisabled = true;
      }
    });
  }

  setTimesToOpenClose() {
    if (this.form.controls.startDate.value && this.timePickers.startDate) {
      this.timePickers.startDate.hour = '9';
      this.timePickers.startDate.minute = '00';
      this.timePickers.startDate.meridiem = 'AM';
    }
    if (this.form.controls.endDate.value && this.timePickers.endDate) {
      this.timePickers.endDate.hour = '5';
      this.timePickers.endDate.minute = '00';
      this.timePickers.endDate.meridiem = 'PM';
    }
  }

  calcAmount() {
    if (!this.form.controls.startDate.value || !this.form.controls.endDate.value) {
      return;
    }

    this.isLoadingAmount = true;
    this.amountError = '';
    this.amount = null;
    const startDate = new Date(this.form.controls.startDate.value);
    const endDate = new Date(this.form.controls.endDate.value);

    if (this.form.controls.rate.value?.IntervalType !== STRInterval.HOURS) {
      this.setTimesToOpenClose();
    }

    this.addTimeToDate(startDate, this.timePickers.startDate);
    this.addTimeToDate(endDate, this.timePickers.endDate);

    const dto: STRCalcSubCatAmountDto = {
      CategoryNumber: this.rentalData.CategoryNumber,
      SubNumber: this.rentalData.SubNumber,
      strData: {
        rate: this.form.controls.rate.value!,
        PickupDate: startDate,
        ReturnDate: endDate,
      }
    }
    this.rentalService.calcAmountForSubCat(dto).subscribe({
      next: (res) => this.onCalcSuccess(res, dto.strData),
      error: (err) => this.onCalcFailure(err),
    });
  }

  onCalcSuccess(res: STRAmount, strData: STRData) {
    this.actionButtonText = 'CONTINUE';
    this.isLoadingAmount = false;
    this.amount = res;
    strData.strAmount = res;
    this.checkoutService.setStrData(strData);
  }

  onCalcFailure(err: any) {
    this.actionButtonText = 'CHECK AVAILABILITY';
    this.amount = null;
    this.isLoadingAmount = false;
    this.amountError = err.error.message;
  }

  onActionButtonClick() {
    if (this.actionButtonText === 'CHECK AVAILABILITY') {
      this.calcAmount();
      return;
    }

    if (this.actionButtonText === 'CONTINUE') {
      this.checkoutService.setTabIsDisabled(CheckoutTabNames.BILLING_ADDRESS, false);
      this.checkoutService.setTabIndexByHeader(CheckoutTabNames.BILLING_ADDRESS);
    }
  }

  onTimeChange(timePicker: TimePickerForm, type: TimePickerType) {
    this.actionButtonText = 'CHECK AVAILABILITY';
    this.amount = null;
    this.amountError = '';
    this.timePickers[type] = timePicker;
  }

  addTimeToDate(date: Date, timePicker?: TimePickerForm) {
    if (!timePicker) {
      return;
    }
    const hour = timePicker.meridiem === 'PM' ? +timePicker.hour + 12 : +timePicker.hour;
    date.setHours(hour);
    date.setMinutes(+timePicker.minute);
  }

  isTimeSet(): boolean {
    if (this.showTime) {
      return !!(this.timePickers.startDate && this.timePickers.endDate);
    } else {
      return true;
    }
  }

  get fc() { return this.form.controls; }
}
