import { BehaviorSubject, Observable, of } from 'rxjs';
import { catchError, finalize } from 'rxjs/operators';
import { AppLazyLoadEvent, FindAllResult } from '../models';
import { HttpErrorService } from './http-error.service';

export abstract class GenericSearchService<T> {
  private dataSubject = new BehaviorSubject<T[]>([]);
  public data$ = this.dataSubject.asObservable();

  private dataCountSubject = new BehaviorSubject<number>(0);
  public dataCount$ = this.dataCountSubject.asObservable();

  private isLoadingSubject = new BehaviorSubject<boolean>(true);
  public isLoading$ = this.isLoadingSubject.asObservable();

  constructor(
    private httpErrorService: HttpErrorService,
    private entityType: string,
  ) {}

  public onFilterEvent($event: AppLazyLoadEvent): void {
    this.isLoadingSubject.next(true);
    if ($event.filters && $event.filters['global']) {
      delete $event.filters['global'];
    }
    this.findAll($event).pipe(
      catchError((err) => {
        this.httpErrorService.onHttpError(err, `Could not retrieve ${this.entityType}`);
        return of([]);
      }),
      finalize(() => this.isLoadingSubject.next(false))
    ).subscribe(res => {
      this.dataSubject.next(res[0]);
      this.dataCountSubject.next(res[1]);
    });
  }

  /** This method needs to be implemented in the child class */
  abstract findAll(dto: AppLazyLoadEvent): Observable<FindAllResult<T>>;
}
