import { HttpRequest } from '@angular/common/http';

/** POST end points that should also be cached */
const cachePostUrls = [
  'items/all',
];

export function appTransferStateCacheFilter(req: HttpRequest<unknown>): boolean {
  if (req.method === 'GET') {
    return true;
  }

  if (cachePostUrls.some(url => req.url.endsWith(url))) {
    return true;
  }

  return false;
}
