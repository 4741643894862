<div class="mb-2">
  <img src="assets/images/others/accepted-cards.png" alt="accepted cards">
</div>
<div class="flex flex-wrap gap-2">
  <button type="button"
          pButton
          class="AcceptUI"
          data-billingAddressOptions='{"show":true, "required":false}'
          [attr.data-apiLoginID]="authnetConfig.apiLoginID"
          [attr.data-clientKey]="authnetConfig.clientKey"
          data-acceptUIFormBtnTxt="Submit"
          data-acceptUIFormHeaderTxt="Card Information"
          data-paymentOptions='{"showCreditCard": true, "showBankAccount": false}'
          data-responseHandler="authNetResponseHandler">
    <span *ngIf="cardData.cardNumber">
      Change Credit Card
    </span>
    <span *ngIf="!cardData.cardNumber">
      Add Credit Card
    </span>
  </button>
  <div *ngIf="cardData.cardNumber" class="align-self-center">Added card ending in: {{cardData.cardNumber.slice(12)}}</div>
</div>
