import { inject } from '@angular/core';
import { PublicRoutes } from '../../enums';
import { AdminRoutes } from '../admin/admin.routes';
import { AppStateService, UiService } from '../../services';

export abstract class NgxFooterComponent {
  uiService = inject(UiService);
  appState = inject(AppStateService);

  routes = PublicRoutes;
  adminRoutes = AdminRoutes;
  isMobile$ = this.uiService.isMobile$;
  businessInfo = this.appState.businessInfo;
  currentYear = new Date().getFullYear();
}
