import { Component, ViewChild } from '@angular/core';
import { CommonModule } from '@angular/common';
import { DialogService } from 'primeng/dynamicdialog';
import { Table, TableLazyLoadEvent } from 'primeng/table';
import { EditCountryStatesComponent } from './edit-country-states/edit-country-states.component';
import { AdminPrimengModule } from '../../admin.primeng.module';
import { AppLazyLoadEvent } from '../../../../models';
import { MatchModesPrime } from '../../../../enums';
import { AdminCountryStateService } from './admin-country-state.service';

@Component({
  selector: 'aim-country-states',
  standalone: true,
  imports: [CommonModule, AdminPrimengModule],
  templateUrl: './country-states.component.html',
  styleUrls: ['./country-states.component.scss', '../../admin.scss'],
  providers: [DialogService],
})
export class CountryStatesComponent {
  @ViewChild('dt') table: Table | undefined;
  data$ = this.entityService.data$;
  isLoading$ = this.entityService.isLoading$;
  dataCount$ = this.entityService.dataCount$;
  lastLazyLoadEvent: AppLazyLoadEvent = {
    globalFilterFields: [],
    filters: {}
  };
  selectedOptions: string[] = [];
  isSyncing = false;

  constructor(
    private entityService: AdminCountryStateService,
    private dialogService: DialogService,
  ) {}

  onLazyLoad($event: TableLazyLoadEvent) {
    this.lastLazyLoadEvent = $event;
    const lazyLoadEvent: AppLazyLoadEvent = Object.assign({
      withDeleted: this.selectedOptions.includes('withDeleted'),
      globalFilterFields: this.table?.globalFilterFields || [],
    }, $event);

    this.entityService.onFilterEvent(lazyLoadEvent);
  }

  applyFilterGlobal($event: Event) {
    this.table?.filterGlobal(($event.target as HTMLInputElement).value, MatchModesPrime.CONTAINS);
  }

  openEntity(entityId?: number) {
    const dialog = this.dialogService.open(EditCountryStatesComponent, {
      data: entityId,
      header: 'State',
      width: '700px',
      contentStyle: { "max-height": "580px", "overflow": "auto" },
      dismissableMask: true
    });
    dialog.onClose.subscribe(() => {
      this.onLazyLoad(this.lastLazyLoadEvent);
    });
  }
}
