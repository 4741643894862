<div class="grid">
  <p-card *ngIf="rentalData" header="Rental Item" class="w-full m-3">
    <div class="flex flex-column xl:flex-row xl:align-items-start p-4 gap-4">
      <img class="shadow-2 block xl:block mx-auto border-round"
           [src]="rentalData.subCategory.thumbSrc"
           [alt]="rentalData.subCategory.webTitle"
           height="70"
           width="26"/>
      <div class="flex flex-column sm:flex-row justify-content-between align-items-center xl:align-items-start flex-1 gap-4">
        <div class="text-2xl font-bold text-900">{{ rentalData.subCategory.webTitle}}</div>
        <div class="flex sm:flex-column align-items-center sm:align-items-end gap-3 sm:gap-2">
          <span class="text-2xl font-semibold">
            
          </span>
        </div>
      </div>
    </div>
  </p-card>
  <p-card *ngIf="strData$ | async as strData" header="Time Slot" class="w-full m-3">
    <div class="flex flex-column xl:flex-row xl:align-items-start p-4 gap-4">
      <!-- <img class="w-9 sm:w-16rem xl:w-10rem shadow-2 block xl:block mx-auto border-round" [src]="delivery.Topic | hasImage" [alt]="delivery.AccessoryDesc" /> -->
      <div class="flex flex-column sm:flex-row justify-content-between align-items-center xl:align-items-start flex-1 gap-4">
        <div class="text-2xl font-bold text-900">{{ strData.PickupDate | date }} &mdash; {{strData.ReturnDate | date}}</div>
        <div class="flex sm:flex-column align-items-center sm:align-items-end gap-3 sm:gap-2">
          <span class="text-2xl font-semibold">
            {{strData.strAmount?.total | currency}}
          </span>
        </div>
      </div>
    </div>
  </p-card>
</div>
<!-- <div class="text-right text-2xl font-semibold pr-3">Items & Down Payment: {{totals.items | currency}}</div> -->
<!-- <div class="text-right text-2xl font-semibold pr-3">Shipping: {{totals.shipping | currency}}</div> -->
<div class="text-right text-2xl font-semibold pr-3">Tax: {{totals.tax | currency}}</div>
<p-divider></p-divider>
<div class="text-right text-3xl font-semibold pr-3 mb-2">Total: {{totals.total | currency}}</div>
<div class="text-right text-3xl font-semibold pr-3">
  <p-button (onClick)="onCompleteClick()" [loading]="isProcessing" [disabled]="isProcessing" label="CHECKOUT"></p-button>
</div>
<p-dialog header="Error" [(visible)]="showError">
  <p>
    Your payment was declined.
  </p>
</p-dialog>
