import { Component, Input, OnInit, ChangeDetectorRef, Inject, NgZone, Output, EventEmitter } from '@angular/core';
import { CommonModule, DOCUMENT } from '@angular/common';
import { GenericCheckoutService, HttpErrorService, PaymentService } from '../../../services';
import { NgxTsysService } from './ngx-tsys.service';
import { TsysCardType, TsysManifest, TsysTsepEvent, TsysTsepEventType, TsysTsepFormState, TsysTsepFormStates } from './tsys.model';
import { DialogModule } from 'primeng/dialog';
import { ProgressSpinnerModule } from 'primeng/progressspinner';
import { ButtonModule } from 'primeng/button';
import { CheckoutTabNames, TransactionType } from '../../../enums';

declare const window: any;

@Component({
  selector: 'ngx-tsys',
  standalone: true,
  imports: [
    CommonModule,
    DialogModule,
    ProgressSpinnerModule,
    ButtonModule,
  ],
  templateUrl: './ngx-tsys.component.html',
  styleUrls: ['./ngx-tsys.component.css']
})
export class NgxTsysComponent implements OnInit {
  @Input({ required: true }) checkoutService: GenericCheckoutService | undefined;
  @Input({ required: true }) transactionType!: TransactionType;
  @Output() success = new EventEmitter<boolean>();

  cardData: TsysTsepEvent | undefined;
  errors: string[] = [];
  fieldStates: TsysTsepFormStates = {
    ['tsep-cardNum']: {
      touched: false,
      valid: false,
    },
    ['tsep-datepicker']: {
      touched: false,
      valid: false,
    },
    ['tsep-cvv2']: {
      touched: false,
      valid: false,
    },
    ['tsep-cardHolderName']: {
      touched: false,
      valid: false,
    },
    ['tsep-zipCode']: {
      touched: false,
      valid: false,
    },
  }
  manifest = '';
  data = '';

  isLoadingTsep = true;
  displayErrorDialog = false;
  displayTsepFormError = false;
  isObtainingToken = false;
  tsepFormValid = false;
  addCreditCardClicked = false;
  isFinished = false;

  constructor(
    @Inject(DOCUMENT) private document: Document,
    private tsysService: NgxTsysService,
    private httpErrorService: HttpErrorService,
    private changeRef: ChangeDetectorRef,
    private ngZone: NgZone,
    private paymentService: PaymentService,
  ) {}

  ngOnInit() {
    this.getManifest();
  }

  getManifest() {
    this.tsysService.generateManifest(this.transactionType).subscribe({
      next: (manifest) => this.addTsysScript(manifest),
      error: (err) => this.httpErrorService.onHttpError(err)
    });
  }

  addTsysScript(manifest: TsysManifest) {
    this.manifest = manifest.manifest;
    this.data = manifest.data;

    window.tsepHandler = (eventType: TsysTsepEventType, event: TsysTsepEvent) => {
      return this.tsepHandler(eventType, event);
    }

    const headerEl = this.document.getElementsByTagName('head')[0];
    const script = this.document.createElement('script');
    script.src = `${manifest.scriptUrl}/${manifest.deviceId}?${manifest.manifest}`;
    script.type = 'text/javascript';
    script.onload = () => {
      this.isLoadingTsep = false;
      this.changeRef.detectChanges();
      this.addTsepZipCodeEventListener();
    }
    headerEl.appendChild(script);
    this.changeRef.detectChanges();
  }

  async addTsepZipCodeEventListener() {
    try {
      const inputEl = await this.findTsepZipCodeInputEl();
      inputEl.addEventListener('input', (change) => {
        if (inputEl.value.length >= 5) {
          this.ngZone.run(() => {
            this.fieldStates['tsep-zipCode'].valid = true;
            this.tsepFormValid = Object.values(this.fieldStates).every(field => field);
            this.changeRef.detectChanges();
          });
        }
      });
    } catch (err) {
      console.error('error loading tsep form');
      this.displayTsepFormError = true;
      this.changeRef.detectChanges();
    }
  }

  findTsepZipCodeInputEl(): Promise<HTMLInputElement> {
    let attempts = 0;
    return new Promise<HTMLInputElement>((resolve, reject) => {
      const interval = setInterval(() => {
        attempts++;
        const inputEl = this.document.getElementById('tsep-zipCode') as HTMLInputElement;
        if (inputEl) {
          window.clearInterval(interval);
          resolve(inputEl);
        } else {
          if (attempts >= 40) {
            window.clearInterval(interval);
            reject();
          }
        }
      }, 500);
    });
  }

  reloadPage() {
    location.reload();
  }

  onAddCreditCardClick() {
    if (this.cardData && this.checkoutService) {
      this.checkoutService.setTabIsDisabled(CheckoutTabNames.OVERVIEW, false);
      this.checkoutService.setTabIndexByHeader(CheckoutTabNames.OVERVIEW);
      this.isFinished = true;
    } else if (this.cardData) {
      this.success.emit(true);
    }
    this.isObtainingToken = true;
    this.addCreditCardClicked = true;
  }

  tsepHandler(eventType: TsysTsepEventType, tsepEvent: TsysTsepEvent) {
    switch (eventType) {
      case 'FocusOutEvent': {
        this.handleFocusOutEvent(tsepEvent);
        break;
      }
      case 'FieldValidationSuccessEvent': {
        this.handleFieldValidationSuccessEvent(tsepEvent);
        break;
      }
      case 'FieldValidationErrorEvent': {
        this.handleFieldValidationErrorEvent(tsepEvent);
        break;
      }
      case 'TokenEvent': {
        this.handleTokenEvent(tsepEvent);
        break;
      }
    }

    this.changeRef.detectChanges();
  }

  handleFocusOutEvent(tsepEvent: TsysTsepEvent) {
    this.fieldStates[tsepEvent.fieldName].touched = true;
  }

  handleFieldValidationErrorEvent(tsepEvent: TsysTsepEvent) {
    this.fieldStates[tsepEvent.fieldName].valid = false;
    this.tsepFormValid = false;
  }

  handleFieldValidationSuccessEvent(tsepEvent: TsysTsepEvent) {
    this.fieldStates[tsepEvent.fieldName].valid = true;
    this.tsepFormValid = Object.values(this.fieldStates).every((field: TsysTsepFormState) => field.valid);
  }

  /** since the tsep form is all event driven and there is no button provided we need to 
   * take extra steps so the checkout process doesn't go forward until the user has clicked the use this card button
   * other wise the checkout process would go foward on a blur event which would be odd
   */
  handleTokenEvent(event: TsysTsepEvent) {
    event.manifest = this.manifest;
    event.data = this.data;
    event.cardTypeFull = this.getCardType(event.cardType);
    this.cardData = event;
    this.paymentService.cardData.tsys = event;
    this.isObtainingToken = false;
    if (this.addCreditCardClicked) {
      this.ngZone.run(() => {
        if (this.checkoutService) {
          this.checkoutService.setTabIsDisabled(CheckoutTabNames.OVERVIEW, false);
          this.checkoutService.setTabIndexByHeader(CheckoutTabNames.OVERVIEW);
        } else {
          this.success.emit(true);
        }

        this.isFinished = true;
      });
    }
  }

  getCardType(cardType: TsysCardType) {
    return this.tsysService.getCardType(cardType);
  }
}
