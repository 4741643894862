/**
 * List of roles which will give users access to certain
 * parts of the application.
 *
 * @readonly
 * @enum {string}
 */
export enum UserType {
  ADMIN = 'admin',
  CUSTOMER = 'customer',
}

export const UserTypes = [UserType.ADMIN, UserType.CUSTOMER];
