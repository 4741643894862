<h1 class="mt-4">Global Pay Settings</h1>
<div class="col-12">
  <form [formGroup]="form" class="p-fluid p-formgrid grid dialog-form mt-2 form-width">
    <div class="field col-12">
      <span class="p-float-label">
        <input pInputText id="merchant_name" formControlName="merchant_name" />
        <label htmlFor="merchant_name">Merchant Name</label>
      </span>
    </div>
    <div class="field col-12">
      <span class="p-float-label">
        <input pInputText id="site_id" formControlName="site_id" />
        <label htmlFor="site_id">Site Id</label>
      </span>
    </div>
    <div class="field col-12">
      <span class="p-float-label">
        <input pInputText id="key" formControlName="key" />
        <label htmlFor="key">Key</label>
      </span>
    </div>
  </form>
  <div class="flex justify-content-end form-width">
    <p-button label="Save"
              icon="pi pi-check"
              styleClass="p-button-text"
              [loading]="isSaving"
              [disabled]="form.invalid"
              (onClick)="onSaveClick()">
    </p-button>
    <p-button label="Clear"
              icon="pi pi-trash"
              styleClass="p-button-text"
              (onClick)="onClearClick()">
    </p-button>
  </div>
</div>
<div class="progress-spinner" *ngIf="isLoading">
  <p-progressSpinner></p-progressSpinner>
</div>
