import { Injectable } from '@angular/core';
import { NgxAimService } from '../ngx-aim.service';
import { HttpClient } from '@angular/common/http';
import {
  ContractStepsDisabled,
  C2BDistrictGeneric,
  C2BInstrumentGeneric,
  C2BSchoolGeneric,
  C2BTeacherGeneric,
  C2BGradeGeneric,
  C2BAccessoryGeneric,
  ContractFormInput
} from '../models';
import { BehaviorSubject, Observable } from 'rxjs';
import { ContractStepNames } from '../enums';

@Injectable({
  providedIn: 'root'
})
export class ContractsService {
  private baseUrl = `${this.ngxAimService.getApiUrl()}/contracts`;

  private stepsDisabledSubject = new BehaviorSubject<ContractStepsDisabled>({
    [ContractStepNames.SELECT_SCHOOL]: false,
    [ContractStepNames.SELECT_INSTRUMENT]: true,
    [ContractStepNames.OPTIONS_ACCESSORIES]: true,
    [ContractStepNames.PAYMENT]: false,
  });
  public stepsDisabed$ = this.stepsDisabledSubject.asObservable();
  public updateDisabledSteps() {
    const stepsDisabled: ContractStepsDisabled = {};

    stepsDisabled[ContractStepNames.SELECT_SCHOOL] = false;

    stepsDisabled[ContractStepNames.SELECT_INSTRUMENT] =
      (this.selectedDistirct && this.selectedSchool && this.selectedTeacher) ? false : true;

    stepsDisabled[ContractStepNames.OPTIONS_ACCESSORIES] =
      (this.selectedInstrument && this.selectedGrade) ? false : true;

    stepsDisabled[ContractStepNames.PAYMENT] =
      (!stepsDisabled[ContractStepNames.SELECT_INSTRUMENT] && !stepsDisabled[ContractStepNames.OPTIONS_ACCESSORIES]) ? false : true;

    this.stepsDisabledSubject.next(stepsDisabled);
  }

  private districtsSubject = new BehaviorSubject<C2BDistrictGeneric[]>([]);
  public districts$ = this.districtsSubject.asObservable();
  public setDistricts(districts: C2BDistrictGeneric[]) {
    this.districtsSubject.next(districts);
  }
  public getCurrentDistrcits() {
    return this.districtsSubject.value;
  }

  private schoolsSubject = new BehaviorSubject<C2BSchoolGeneric[]>([]);
  public schools$ = this.schoolsSubject.asObservable();
  public setSchools(schools: C2BSchoolGeneric[]) {
    this.schoolsSubject.next(schools);
  }

  private teachersSubject = new BehaviorSubject<C2BTeacherGeneric[]>([]);
  public teachers$ = this.teachersSubject.asObservable();
  public setTeachers(teachers: C2BTeacherGeneric[]) {
    this.teachersSubject.next(teachers);
  }

  private instrumentsSubject = new BehaviorSubject<C2BInstrumentGeneric[]>([]);
  public instruments$ = this.instrumentsSubject.asObservable();
  public setInstruments(instruments: C2BInstrumentGeneric[]) {
    this.instrumentsSubject.next(instruments);
  }
  public getCurrentInstruments() {
    return this.instrumentsSubject.value;
  }

  private gradesSubject = new BehaviorSubject<C2BGradeGeneric[]>([]);
  public grades$ = this.gradesSubject.asObservable();
  public setGrades(grades: C2BGradeGeneric[]) {
    this.gradesSubject.next(grades);
  }

  public selectedDistirct: C2BDistrictGeneric | undefined;
  public selectedSchool: C2BSchoolGeneric | undefined;
  public selectedTeacher: C2BTeacherGeneric | undefined;
  public selectedInstrument: C2BInstrumentGeneric | undefined;
  public selectedGrade: C2BGradeGeneric | undefined;
  public selectedAccessories: C2BAccessoryGeneric[] | undefined;
  public selectedMaintenance: C2BAccessoryGeneric[] | undefined;
  public selectedDelivery: C2BAccessoryGeneric | undefined;

  constructor(
    private ngxAimService: NgxAimService,
    private http: HttpClient,
  ) {}

  getDistricts(): Observable<C2BDistrictGeneric[]> {
    return this.http.get<C2BDistrictGeneric[]>(`${this.baseUrl}/districts`);
  }

  getSchools(DistrictId: number): Observable<C2BSchoolGeneric[]> {
    return this.http.get<C2BSchoolGeneric[]>(`${this.baseUrl}/district/${DistrictId}/schools`);
  }

  getTeachers(SchoolId: number): Observable<C2BTeacherGeneric[]> {
    return this.http.get<C2BTeacherGeneric[]>(`${this.baseUrl}/school/${SchoolId}/teachers`);
  }

  getInstruements(TeacherId: number): Observable<C2BInstrumentGeneric[]> {
    return this.http.get<C2BInstrumentGeneric[]>(`${this.baseUrl}/teacher/${TeacherId}/instruments`);
  }

  getContractFormInputs(): Observable<ContractFormInput[]> {
    return this.http.get<ContractFormInput[]>(`${this.baseUrl}/form-inputs`);
  }

  resetInstrument() {
    this.selectedInstrument = undefined;
    this.selectedGrade = undefined;
    this.selectedAccessories = undefined;
    this.selectedMaintenance = undefined;
    this.selectedDelivery = undefined;
  }

  reset() {
    this.selectedDistirct = undefined;
    this.selectedSchool = undefined;
    this.selectedTeacher = undefined;
    this.resetInstrument();
  }
}
