import { Component, Inject, OnInit } from '@angular/core';
import { CommonModule, DOCUMENT } from '@angular/common';
import { DynamicDialogConfig } from 'primeng/dynamicdialog';
import { ButtonModule } from 'primeng/button';
import { ItemInventoryType, Order, ShipEngineLabel } from '../../../../../models';
import { AppStateService, HttpErrorService } from '../../../../../services';
import { StoreType } from '../../../../../enums';
import { AdminShipEngineService } from '../../../site/shipping/ship-engine/admin-ship-engine.service';

@Component({
  selector: 'aim-shipping',
  standalone: true,
  imports: [CommonModule, ButtonModule],
  templateUrl: './shipping.component.html',
  styleUrls: ['./shipping.component.scss']
})
export class ShippingComponent implements OnInit {
  itemTypes = ItemInventoryType;
  storeType = this.appState.storeType;
  shippingTitle = this.storeType === StoreType.GENERIC ? '' : 'NON-FFL';
  order!: Order;
  window!: Window;
  isSaving = false;

  constructor(
    private shipEngineService: AdminShipEngineService,
    private appState: AppStateService,
    private config: DynamicDialogConfig<Order>,
    private httpErrorService: HttpErrorService,
    @Inject(DOCUMENT) private document: Document,
  ) {
    this.window = this.document.defaultView!;
  }

  ngOnInit() {
    if (this.config.data) {
      this.order = this.config.data;
    }
  }

  onCreateLabelClick(itemType: ItemInventoryType) {
    if (!this.order.id) {
      return;
    }

    this.isSaving = true;
    this.shipEngineService.createLabel(this.order.id, itemType).subscribe({
      next: (label) => this.onLabelSuccess(label, itemType),
      error: (err) => this.onLabelError(err)
    });
  }

  onLabelSuccess(label: ShipEngineLabel, itemType: ItemInventoryType) {
    this.isSaving = false;
    if (itemType === ItemInventoryType.ACCESSORY) {
      this.order.accessoryTrackingNumber = label.tracking_number;
      this.order.accessoryDeliveryLabelPdfUrl = label.label_download.pdf;
    }

    if (itemType === ItemInventoryType.SERIALIZED) {
      this.order.serializedTrackingNumber = label.tracking_number;
      this.order.serializedLabelPdfUrl = label.label_download.pdf;
    }
  }

  onLabelError(err: any) {
    this.isSaving = false;
    this.httpErrorService.onHttpError(err, 'Could not create label');
  }

  onViewLabelClick(pdfUrl?: string) {
    if (!pdfUrl) {
      return;
    }
    this.window.open(pdfUrl, '_blank');
  }
}
