import { Injectable } from '@angular/core';
import { CardData, ItemInventoryType, SaveAddressDto, UserAddress } from '../models';
import { Observable } from 'rxjs';
import { AddressType, CheckoutTabNames } from '../enums';

@Injectable({
  providedIn: 'root'
})
export abstract class GenericCheckoutService {
  constructor() {}

  abstract getAddress(type: AddressType): Observable<UserAddress>;

  abstract saveAddress(address: SaveAddressDto): Observable<void>;

  abstract setTabIsDisabled(tabIndex: CheckoutTabNames, isDisabled: boolean): void;

  abstract setTabIndexByHeader(header: CheckoutTabNames): void;

  abstract getDeliveryOptions?(itemType: ItemInventoryType): void;
}
