import { Component } from '@angular/core';
import { CommonModule } from '@angular/common';
import { DataViewModule } from 'primeng/dataview';
import { DynamicDialogConfig } from 'primeng/dynamicdialog';
import { LineItem } from '../../../../../models';
import { HttpErrorService } from '../../../../../services';
import { HasImagePipe } from '../../../../../pipes';
import { AdminOrderService } from '../../admin-order.service';

@Component({
  selector: 'aim-admin-order-items',
  standalone: true,
  imports: [CommonModule, DataViewModule, HasImagePipe],
  templateUrl: './admin-order-items.component.html',
  styleUrls: ['./admin-order-items.component.scss']
})
export class AdminOrderItemsComponent {
  lineItems: LineItem[] = [];

  constructor(
    private orderService: AdminOrderService,
    private httpErrorService: HttpErrorService,
    private config: DynamicDialogConfig,
  ) {
    this.orderService.getLineItems(this.config.data).subscribe({
      next: (lineItems) => this.lineItems = lineItems,
      error: (err) => this.httpErrorService.onHttpError(err, 'Could not retrieve items'),
    });
  }
}
