<p-table #dt
         dataKey="id"         
         [value]="(data$ | async)!"
         [loading]="(isLoading$ | async)!"
         [totalRecords]="(dataCount$ | async)!"
         [lazy]="true"
         [rows]="10"
         [showCurrentPageReport]="true"
         [rowsPerPageOptions]="[10, 25, 50]"
         [paginator]="true"
         [globalFilterFields]="['country_state.name', 'country_state.abbreviation']"
         [filterDelay]="250"
         [breakpoint]="'960px'"
         [rowHover]="true"
         (onLazyLoad)="onLazyLoad($event)"
         currentPageReportTemplate="Showing {first} to {last} of {totalRecords} entries"
         sortField="country_state.name"
         sortMode="single"
         responsiveLayout="stack">
  <ng-template pTemplate="caption">
    <div class="flex">
      States
      <span class="p-input-icon-left ml-auto">
        <i class="pi pi-search"></i>
        <input pInputText type="text" placeholder="Search keyword" (input)="applyFilterGlobal($event)" />
      </span>
    </div>
  </ng-template>
  <ng-template pTemplate="header">
    <tr>
      <th style="min-width:15rem" pSortableColumn="country_state.name">
        <div class="flex align-items-center">
          Name
          <p-sortIcon field="country_state.name"></p-sortIcon>
          <p-columnFilter type="text" field="country_state.name" display="menu"></p-columnFilter>
        </div>
      </th>
      <th style="min-width:15rem" pSortableColumn="country_state.abbreviation">
        <div class="flex align-items-center">
          Abbreviation
          <p-sortIcon field="country_state.abbreviation"></p-sortIcon>
          <p-columnFilter type="text" field="country_state.abbreviation" display="menu"></p-columnFilter>
        </div>
      </th>
      <th style="min-width:15rem" pSortableColumn="country_state.isActive">
        <div class="flex align-items-center">
          Active
          <p-sortIcon field="country_state.isActive"></p-sortIcon>
          <p-columnFilter type="text" field="country_state.isActive" display="menu"></p-columnFilter>
        </div>
      </th>
      <th style="width: 8rem"></th>
    </tr>
  </ng-template>
  <ng-template pTemplate="body" let-country_state>
    <tr [ngClass]="{'deleted-record': country_state.deleteDate}">
      <td>
        <span class="p-column-title"><strong>Name</strong></span>
        {{country_state.name}}
      </td>
      <td>
        <span class="p-column-title"><strong>Abbreviation</strong></span>
        {{country_state.abbreviation}}
      </td>
      <td>
        <span class="p-column-title"><strong>Active</strong></span>
        {{country_state.isActive}}
      </td>
      <td style="text-align: center">
        <button pButton
                pRipple
                icon="pi pi-pencil"
                class="p-button-rounded p-button-primary mr-2"
                (click)="openEntity(country_state.id)"
                pTooltip="Edit"
                tooltipPosition="left">
        </button>
      </td>
    </tr>
  </ng-template>
  <ng-template pTemplate="emptymessage">
    <tr>
      <td colspan="7">No states found.</td>
    </tr>
  </ng-template>
</p-table>
