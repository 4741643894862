import { Injectable } from '@angular/core';
import { TrueFalseText } from '../types';

@Injectable({
  providedIn: 'root'
})
export class UtilsService {

  constructor() { }

  convertTrueFalseTextToBoolean(text: TrueFalseText): boolean {
    return text === 'True';
  }

  convertBooleanToTrueFalseText(bool: boolean): TrueFalseText {
    return bool ? 'True' : 'False';
  }
}
