import { inject } from '@angular/core';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { distinctUntilChanged } from 'rxjs';
import { ActivatedRoute, Router } from '@angular/router';
import { ShortTermRentalCheckoutService, ShortTermRentalService } from '../../services';
import { RentalData, SubCategory } from '../../models';
import { PublicRoutes } from '../../enums';

/** when extending this class be sure to implement ngOnInit */
export class NgxViewRentalComponent {
  // ngOnInit() {
  //   this.init();
  // }

  rentalService = inject(ShortTermRentalService);
  route = inject(ActivatedRoute);
  rentalCheckoutService = inject(ShortTermRentalCheckoutService);
  router = inject(Router);

  params$ = this.route.paramMap.pipe(
    distinctUntilChanged(),
    takeUntilDestroyed(),
  );
  subCategory: SubCategory | undefined;
  CategoryNumber: number | undefined;
  SubNumber: number | undefined;
  isLoading = true;

  init() {
    this.params$.subscribe((params) => {
      const categorySlug = params.get('categorySlug');
      const subCategorySlug = params.get('subCategorySlug')
      if (categorySlug && subCategorySlug) {
        this.getSubCategory(categorySlug, subCategorySlug);
      }
    });
  }

  getSubCategory(categorySlug: string, subCategorySlug: string) {
    this.rentalService.getSubCategory(categorySlug, subCategorySlug).subscribe({
      next: (subCategories) => this.onSuccess(subCategories),
      error: (err) => this.onFailure(err),
    });
  }

  onSuccess(subCategory: SubCategory) {
    this.isLoading = false;
    this.subCategory = subCategory;
    this.CategoryNumber = subCategory.categoryCategoryNumber;
    this.SubNumber = subCategory.SubNumber;
  }

  onFailure(err: any) {
    this.isLoading = false;
    this.router.navigateByUrl(`/${PublicRoutes.PAGE_NOT_FOUND}`);
  }

  onRentClick() {
    if (this.CategoryNumber && this.SubNumber && this.subCategory) {
      const rentalData: RentalData = {
        CategoryNumber: this.CategoryNumber,
        SubNumber: this.SubNumber,
        subCategory: this.subCategory,
      }
      this.rentalCheckoutService.setRentalData(rentalData);
      this.router.navigateByUrl(`/${PublicRoutes.RENTAL_CHECKOUT}`);
    }
  }
}
