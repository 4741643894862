import { inject } from '@angular/core';
import { AppStateService, ContractsCheckoutService, ContractsService, HttpErrorService } from '../../../services';
import { distinctUntilChanged } from 'rxjs';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { CheckoutTotals, ContractFormInput, FormInputAnswers, ProcessOrderResponse } from '../../../models';
import { PaymentStatus, PaymentType } from '../../../enums';
import { MessageService } from 'primeng/api';
import { NavigationExtras, Router } from '@angular/router';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';

/** when extending this class be sure to implement ngOnInit,
 * @Input({ required: true })
 * override completeUrl: string = '';
 */
export abstract class NgxAimOverviewContractsComponent {
  contractsService = inject(ContractsService);
  checkoutService = inject(ContractsCheckoutService);
  httpErrorService = inject(HttpErrorService);
  appState = inject(AppStateService);
  messageService = inject(MessageService);
  router = inject(Router);
  fb = inject(FormBuilder);

  completeUrl: string = '';

  totals$ = this.checkoutService.totals$.pipe(
    distinctUntilChanged(),
    takeUntilDestroyed(),
  );
  totals: CheckoutTotals = {
    items: 0,
    shipping: 0,
    tax: 0,
    total: 0,
  }
  form = new FormGroup({});
  formInputs: ContractFormInput[] = [];
  instrument = this.contractsService.selectedInstrument;
  grade = this.contractsService.selectedGrade;
  accessories = this.contractsService.selectedAccessories;
  maintenance = this.contractsService.selectedMaintenance;
  delivery = this.contractsService.selectedDelivery;
  isProcessing = false;
  showError = false;

  init() {
    if (!this.contractsService.selectedInstrument) {
      this.router.navigateByUrl('/');
      return;
    }

    this.totals$.subscribe(totals => {
      this.totals = totals;
    });

    this.contractsService.getContractFormInputs().subscribe({
      next: (formInputs) => this.addFormInputs(formInputs),
      error: (err) => this.httpErrorService.onHttpError(err, 'Could not load overview. Please reload page')
    });
  }

  addFormInputs(formInputs: ContractFormInput[]) {
    formInputs.forEach((input) => {
      const validators = input.required ? [Validators.required] : [];
      this.form.addControl(input.name, this.fb.control<string>('', validators));
    });
    this.formInputs = formInputs;
  }

  onCompleteClick() {
    if (this.appState.paymentType === PaymentType.NONE && this.totals.total !== 0) {
      this.messageService.add({
        severity: 'error',
        summary: 'Failed',
        detail: 'Cannot not check out at this time. No payment processor',
        key: 'app-toast'
      });
      return;
    }

    this.isProcessing = true;
    const formInputAnwers: FormInputAnswers[] = [];
    Object.keys(this.form.controls).forEach((name) => {
      formInputAnwers.push({
        Key: name,
        Value: this.form.get(name)?.value,
      });
    });

    this.checkoutService.checkout(formInputAnwers).subscribe({
      next: (res) => this.handleCheckoutResponse(res),
      error: (err) => this.onError(err),
    });
  }

  handleCheckoutResponse(res: ProcessOrderResponse) {
    this.isProcessing = false;
    if (res.paymentStatus === PaymentStatus.APPROVED) {
      this.contractsService.reset();
      this.checkoutService.reset();

      const navigationExtras: NavigationExtras = {
        state: {
          orderId: res.orderId,
        },
      }
      this.router.navigate(['/', this.completeUrl], navigationExtras);
    } else {
      this.showError = true;
    }
  }

  onError(err: any) {
    this.isProcessing = false;
    this.httpErrorService.onHttpError(err, `We couldn't process your order`);
  }
}
