import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { NgxAimService } from '../../../../ngx-aim.service';
import { AppLazyLoadEvent, Ffl, FindAllResult } from '../../../../models';
import { Observable } from 'rxjs';
import { GenericSearchService, HttpErrorService } from '../../../../services';

@Injectable({
  providedIn: 'root'
})
export class AdminFflService extends GenericSearchService<Ffl> {
  baseUrl = `${this.ngxAimService.getApiUrl()}/admin/ffl`;

  constructor(
    private http: HttpClient,
    private ngxAimService: NgxAimService,
    private _httpErrorService: HttpErrorService,
  ) {
    super(_httpErrorService, 'FFL');
  }

  findAll(dto: AppLazyLoadEvent): Observable<FindAllResult<Ffl>> {
    return this.http.post<FindAllResult<Ffl>>(`${this.baseUrl}/all`, dto);
  }

  findOne(id: number): Observable<Ffl> {
    return this.http.get<Ffl>(`${this.baseUrl}/ffl/${id}`);
  }

  save(ffl: Partial<Ffl>): Observable<Ffl> {
    return this.http.post<Ffl>(`${this.baseUrl}`, ffl);
  }
}
