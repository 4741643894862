import { Component } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ButtonModule } from 'primeng/button';
import { ToolbarModule } from 'primeng/toolbar';
import { Router } from '@angular/router';
import { AdminRoutes } from '../admin.routes';

@Component({
  selector: 'aim-contracts',
  standalone: true,
  imports: [CommonModule, ButtonModule, ToolbarModule],
  templateUrl: './contracts.component.html',
  styleUrls: ['./contracts.component.scss', '../admin.scss']
})
export class ContractsComponent {
  constructor(private router: Router) {}

  onFormInputsClick() {
    this.router.navigate([AdminRoutes.ROOT, AdminRoutes.CONTRACTS, AdminRoutes.FORM_IMPUTS]);
  }
}
