import { Component, OnInit } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ReactiveFormsModule } from '@angular/forms';
import { InputTextModule } from 'primeng/inputtext';
import { DropdownModule } from 'primeng/dropdown';
import { ButtonModule } from 'primeng/button';
import { NgxAimAddFflComponent } from './ngx-aim-add-ffl.class';

@Component({
  selector: 'ngx-add-ffl',
  standalone: true,
  imports: [
    CommonModule,
    ReactiveFormsModule,
    InputTextModule,
    DropdownModule,
    ButtonModule,
  ],
  templateUrl: './ngx-add-ffl.component.html',
  styleUrls: ['./ngx-add-ffl.component.css']
})
export class NgxAddFflComponent extends NgxAimAddFflComponent implements OnInit {
  ngOnInit() {
    this.init();
  }
}
