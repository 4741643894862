import { Component, ChangeDetectionStrategy } from '@angular/core';
import { CommonModule } from '@angular/common';
import { NgxAimCategoryFilterComponent } from './category-filter/category-filter.component';
import { NgxAimAttributeFilterComponent } from './attribute-filter/attribute-filter.component';
import { NgxAimPriceFilterComponent } from './price-filter/price-filter.component';
import { NgxProductFilterComponent } from './ngx-product-filter.class';

@Component({
  selector: 'ngx-aim-product-filter',
  standalone: true,
  imports: [CommonModule, NgxAimCategoryFilterComponent, NgxAimAttributeFilterComponent, NgxAimPriceFilterComponent],
  templateUrl: './product-filter.component.html',
  styleUrls: ['./product-filter.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class NgxAimProductFilterComponent extends NgxProductFilterComponent {}
