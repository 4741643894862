import { inject } from '@angular/core';
import { CheckboxChangeEvent } from 'primeng/checkbox';
import { RadioButtonClickEvent } from 'primeng/radiobutton';
import { Router } from '@angular/router';
import { C2BAccessoryGeneric, C2BGradeGeneric, C2BInstrumentGeneric } from '../../models';
import { ContractsService } from '../../services';
import { PublicRoutes } from '../../enums';

/** when extending this class be sure to implement ngOnInit */
export abstract class NgxSelectOptionsAccessoriesComponent {
  // ngOnInit() {
  //   this.init();
  // }

  contractsService = inject(ContractsService);
  router = inject(Router);
  selectedInstrument: C2BInstrumentGeneric | undefined;
  selectedGrade: C2BGradeGeneric | undefined;
  selectedAccessories: C2BAccessoryGeneric[] = [];
  selectedDelivery: C2BAccessoryGeneric | undefined;
  selectedMaintenance: C2BAccessoryGeneric[] = [];
  accessoryOptions: C2BAccessoryGeneric[] = [];
  deliveryOptions: C2BAccessoryGeneric[] = [];
  maintenanceOptions: C2BAccessoryGeneric[] = [];

  init() {
    if (!this.contractsService.selectedInstrument) {
      this.router.navigate(['/', PublicRoutes.CONTRACTS]);
      return;
    }

    this.selectedInstrument = this.contractsService.selectedInstrument;
    this.selectedGrade = this.contractsService.selectedGrade;
    this.accessoryOptions = this.contractsService.selectedInstrument?.AccessoryList || [];
    this.deliveryOptions = this.contractsService.selectedInstrument?.DeliveryList || [];
    this.maintenanceOptions = this.contractsService.selectedInstrument?.MaintenanceList || [];

    this.accessoryOptions.forEach(opt => {
      if (opt.Required || opt.Default) {
        this.selectedAccessories.push(opt);
      }
    });
    this.maintenanceOptions.forEach(opt => {
      if (opt.Required || opt.Default) {
        this.selectedMaintenance.push(opt);
      }
    });
    this.selectedDelivery = this.deliveryOptions[0];
    this.fillForm();
  }

  fillForm() {
    if (this.contractsService.selectedAccessories?.length) {
      this.selectedAccessories = this.contractsService.selectedAccessories;
    }
    if (this.contractsService.selectedMaintenance?.length) {
      this.selectedMaintenance = this.contractsService.selectedMaintenance;
    }
    if (this.contractsService.selectedDelivery) {
      this.selectedDelivery = this.contractsService.selectedDelivery;
    }
  }

  onAccessoryChange($event: CheckboxChangeEvent) {
    this.contractsService.selectedAccessories = this.selectedAccessories;
  }

  onMaintenanceChange($event: CheckboxChangeEvent) {
    this.contractsService.selectedMaintenance = this.selectedMaintenance;
  }

  onDeliveryChange($event: RadioButtonClickEvent) {
    this.contractsService.selectedDelivery = this.selectedDelivery;
  }

  onNextClick() {
    this.contractsService.selectedAccessories = this.selectedAccessories;
    this.contractsService.selectedMaintenance = this.selectedMaintenance;
    this.contractsService.selectedDelivery = this.selectedDelivery;
    this.contractsService.updateDisabledSteps();
    this.router.navigate(['/', PublicRoutes.CONTRACTS, PublicRoutes.PAYMENT]);
  }

  onBackClick() {
    this.router.navigate(['/', PublicRoutes.CONTRACTS, PublicRoutes.SELECT_INSTRUMENT]);
  }

  getAccessoryTotal(): number {
    return this.selectedAccessories.reduce((acc, accessory) => acc + accessory.Price, 0);
  }

  getMaintenanceTotal(): number {
    return this.selectedMaintenance.reduce((acc, maint) => acc + maint.Price, 0);
  }

  getTotal(): number {
    return this.getAccessoryTotal() +
      (this.selectedDelivery?.Price || 0) +
      (this.selectedGrade?.DownPay || 0) +
      (this.selectedGrade?.DownMaint || 0);
  }
}