/**
 * Names that can be used to create carousel slides
 * @readonly
 * @enum
 * @type {string}
 */
export enum CarouselName {
  MAIN = 'main',
}

export const CarouselNames = [CarouselName.MAIN];
