import { inject } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { distinctUntilChanged } from 'rxjs';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { MenuItem, MessageService } from 'primeng/api';
import { DialogService, DynamicDialogRef } from 'primeng/dynamicdialog';
import { ItemForDisplay } from '../../../models';
import { AppStateService, CartService, HttpErrorService, ItemService } from '../../../services';
import { PaymentType } from '../../../enums';

/** when extending this class be sure to implement ngOnInit */
export abstract class NgxProductComponent {
  // ngOnInit() {
  //   this.init();
  // }

  route = inject(ActivatedRoute);
  itemService = inject(ItemService);
  httpErrorService = inject(HttpErrorService);
  cartService = inject(CartService);
  messageService = inject(MessageService);
  dialogService = inject(DialogService);
  appState = inject(AppStateService);

  params$ = this.route.paramMap.pipe(
    distinctUntilChanged(),
    takeUntilDestroyed(),
  );
  routeData$ = this.route.data.pipe(
    distinctUntilChanged(),
    takeUntilDestroyed(),
  );
  addRentalRef: DynamicDialogRef | undefined;
  item: ItemForDisplay | undefined;
  breadcrumbs: MenuItem[] = [];
  isRental = false;
  showBuyButton = false;

  init() {
    this.showBuyButton = this.appState.paymentType === PaymentType.NONE ? false : true;

    this.params$.subscribe((params) => {
      const id = params.get('id');
      if (id) {
        this.getProduct(+id);
      }
    });
    this.routeData$.subscribe((data) => {
      this.isRental = data['isRental'];
    });
  }

  getProduct(id: number) {
    this.itemService.findOne(id).subscribe({
      next: (item) => this.onSuccess(item),
      error: (err) => this.onError(err, 'Could not load product'),
    });
  }

  onError(err: any, message: string) {
    this.httpErrorService.onHttpError(err, message);
  }

  onSuccess(item: ItemForDisplay) {
    this.item = item;
    this.breadcrumbs = [
      { label: 'Home', routerLink: '/' },
      { label: this.item.Manufacturer, routerLink: `/products/`, queryParams: { manufacturer: this.item.Manufacturer } },
      { label: this.item.Model }
    ];
  }

  onProductActionClick(item: ItemForDisplay) {
    if (!this.isRental) {
      this.addPurchaseToCart(item);
    }
  }

  addPurchaseToCart(item: ItemForDisplay) {
    this.cartService.updateItemCartQuantity(item);
    this.messageService.add({
      severity: 'success',
      summary: `${item.Model} added to cart`,
      key: 'app-toast'
    });
  }
}
