<p-galleria [value]="images"
            [containerStyle]="getContainerStyle((isMobile$ | async))"
            [numVisible]="5"
            [circular]="true"
            [showItemNavigators]="(images && images.length > 1 ) || false"
            [showThumbnails]="(images && images.length > 1 ) || false">
  <ng-template pTemplate="item" let-item>
    <a [href]="'files/' + item.src" target="_blank"><img [src]="'files/' + item.src" class="product-img" /></a>
  </ng-template>
  <ng-template pTemplate="thumbnail" let-item>
    <div class="grid grid-nogutter justify-content-center">
      <img [src]="'files/' + item.thumbSrc" width="100" height="60" />
    </div>
  </ng-template>
  <ng-template pTemplate="itempreviousicon">
    <i class="pi pi-chevron-left chevron"></i>
  </ng-template>
  <ng-template pTemplate="itemnexticon">
    <i class="pi pi-chevron-right chevron"></i>
  </ng-template>
</p-galleria>
