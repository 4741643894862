<div class="flex">
  <div *ngIf="!(showSubCatImages$ | async)">
    <div *ngIf="!(isMobile$ | async) else mobileFilter" id="desktop-filter" style="min-width: 300px; max-width: 300px">
      <ngx-aim-product-filter></ngx-aim-product-filter>
    </div>
    <ng-template #mobileFilter>
      <p-sidebar [(visible)]="filterSidebarVisible" [fullScreen]="true">
        <ngx-aim-product-filter></ngx-aim-product-filter>
        <ng-template pTemplate="footer">
          <div class="flex justify-content-center">
            <p-button (onClick)="onMobileApplyClick()">APPLY</p-button>
          </div>
        </ng-template>
      </p-sidebar>
    </ng-template>
  </div>

  <ng-container *ngIf="!(showSubCatImages$ | async) && (vm$ | async) as vm; else showSubCatImagesTemplate">
    <p-dataView #dv
                class="flex-grow-1 z-1"
                [value]="vm.data"
                [totalRecords]="vm.dataCount"
                [layout]="layout"
                [lazy]="true"
                [paginator]="true"
                [rows]="vm.rows"
                [first]="vm.first"
                [showCurrentPageReport]="true"
                [rowsPerPageOptions]="pageSizes"
                (onLazyLoad)="onLazyLoad($event)"
                currentPageReportTemplate="Showing {first} to {last} of {totalRecords} entries">
      <ng-template pTemplate="header">
        <div class="flex flex-column md:flex-row md:justify-content-between">
          <p-dropdown [options]="sortOptions" [ngModel]="vm.sortBy" placeholder="Sort" (onChange)="onSortChange($event)" styleClass="mb-2 md:mb-0"></p-dropdown>
          <p-button *ngIf="isMobile$ | async" (click)="filterSidebarVisible = true" icon="pi pi-filter" label="FILTER"></p-button>
          <div class="flex gap-1">
            <p-chip *ngFor="let chip of chips$ | async" [label]="chip.value" [removable]="true" (onRemove)="onRemoveChip(chip)"></p-chip>
          </div>
          <p-dataViewLayoutOptions *ngIf="!(isMobile$ | async)"></p-dataViewLayoutOptions>
        </div>
      </ng-template>
      <ng-template pTemplate="list" let-items>
        <div class="grid grid-nogutter">
          <div class="col-12" *ngFor="let item of items; let first = first">
            <div class="flex flex-column xl:flex-row xl:align-items-start p-4 gap-4" [ngClass]="{ 'border-top-1 surface-border': !first }">
              <a [routerLink]="item.routerLink | getProductLink: isRental">
                <img class="w-9 sm:w-16rem xl:w-10rem shadow-2 block xl:block mx-auto border-round" [src]="item.thumbSrc | hasImage" [alt]="item.Model" />
              </a>
              <div class="flex flex-column sm:flex-row justify-content-between align-items-center xl:align-items-start flex-1 gap-4">
                <div class="flex flex-column align-items-center sm:align-items-start gap-3">
                  <a class="text-2xl font-bold text-900 no-underline" [routerLink]="item.routerLink | getProductLink: isRental">{{ item.Model }}</a>
                  <div class="flex align-items-center gap-3">
                    <span class="flex align-items-center gap-2">
                      <i class="pi pi-tag"></i>
                      <span class="font-semibold">{{ item.Manufacturer }}</span>
                    </span>
                    <span [innerHTML]="item.AvailableQuantity | outOfStock"></span>
                  </div>
                </div>
                <div class="flex sm:flex-column align-items-center sm:align-items-end gap-3 sm:gap-2">
                  <ngx-aim-product-price [item]="item" [isRental]="isRental"></ngx-aim-product-price>
                  <button *ngIf="showBuyButton"
                          pButton
                          pRipple
                          icon="pi pi-shopping-cart"
                          class="md:align-self-end mb-2 p-button-rounded"
                          (click)="onAddToCartClick(item)"
                          [disabled]="item.AvailableQuantity <= 0">
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </ng-template>
      <ng-template pTemplate="grid" let-items>
        <div class="grid grid-nogutter">
          <div class="col-12 sm:col-6 lg:col-12 xl:col-3 p-2" *ngFor="let item of items">
            <div class="p-4 border-1 surface-border surface-card border-round">
              <div class="flex flex-wrap align-items-center justify-content-between gap-2">
                <span class="flex align-items-center gap-2">
                  <i class="pi pi-tag"></i>
                  <span class="font-semibold">{{ item.Manufacturer }}</span>
                </span>
                <span [innerHTML]="item.AvailableQuantity | outOfStock"></span>
              </div>
              <div class="flex flex-column align-items-center gap-3 py-5">
                <a class="w-9 shadow-2 border-round" [routerLink]="item.routerLink | getProductLink: isRental">
                  <img class="w-full" [src]="item.thumbSrc | hasImage" [alt]="item.Model" />
                </a>
                <a class=" text-2xl font-bold no-underline" [routerLink]="item.routerLink | getProductLink: isRental">{{ item.Model }}</a>
              </div>
              <div class="flex align-items-center justify-content-between">
                <ngx-aim-product-price [item]="item" [isRental]="isRental"></ngx-aim-product-price>
                <button *ngIf="showBuyButton"
                        pButton
                        pRipple
                        icon="pi pi-shopping-cart"
                        class="p-button-rounded"
                        [label]="isRental | productActionButtonLabel"
                        (click)="onAddToCartClick(item)"
                        [disabled]="item.AvailableQuantity <= 0">
                </button>
              </div>
            </div>
          </div>
        </div>
      </ng-template>
    </p-dataView>
  </ng-container>
  <ng-template #showSubCatImagesTemplate>
    <div *ngIf="!(isLoading$ | async)" class="grid col-12 justify-content-evenly">
      <div *ngFor="let subCat of subCategories$ | async" class="col-12 sm:col-6 lg:col-12 xl:col-3 p-2 sub-cat">
        <div class="p-4 border-1 surface-border surface-card border-round pointer" (click)="onCategoryClick(subCat)">
          <div class="flex flex-column align-items-center gap-3 py-5">
            <img class="w-9 shadow-2 border-round" [src]="subCat.thumbSrc | hasImage" [alt]="subCat.Name" />
            <div class="text-2xl font-bold">{{ subCat.Name }}</div>
          </div>
        </div>
      </div>
    </div>
  </ng-template>
</div>
<div class="progress-spinner" *ngIf="isLoading$ | async">
  <p-progressSpinner></p-progressSpinner>
</div>
