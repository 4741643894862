<p-toolbar>
  <div class="p-toolbar-group-start">
    <p-button label="Sync"
              icon="pi pi-sync"
              class="p-button-primary mr-2"
              (click)="onSyncClick()"
              [loading]="isSyncing">
    </p-button>
  </div>
</p-toolbar>

<p-table #dt
         dataKey="id"         
         [value]="(data$ | async)!"
         [loading]="(isLoading$ | async)!"
         [totalRecords]="(dataCount$ | async)!"
         [lazy]="true"
         [rows]="10"
         [showCurrentPageReport]="true"
         [rowsPerPageOptions]="[10, 25, 50]"
         [paginator]="true"
         [globalFilterFields]="['tax_authority.Id', 'tax_authority.Desc', 'tax_authority.ShortDesc']"
         [filterDelay]="250"
         [breakpoint]="'960px'"
         [rowHover]="true"
         (onLazyLoad)="onLazyLoad($event)"
         currentPageReportTemplate="Showing {first} to {last} of {totalRecords} entries"
         sortField="tax_authority.Id"
         sortMode="single"
         responsiveLayout="stack">
  <ng-template pTemplate="caption">
    <div class="flex">
      Tax Authorities
      <span class="p-input-icon-left ml-auto">
        <i class="pi pi-search"></i>
        <input pInputText type="text" placeholder="Search keyword" (input)="applyFilterGlobal($event)" />
      </span>
    </div>
  </ng-template>
  <ng-template pTemplate="header">
    <tr>
      <th style="min-width:15rem" pSortableColumn="tax_authority.Id">
        <div class="flex align-items-center">
          Id
          <p-sortIcon field="tax_authority.Id"></p-sortIcon>
          <p-columnFilter type="text" field="tax_authority.Id" display="menu"></p-columnFilter>
        </div>
      </th>
      <th style="min-width:15rem" pSortableColumn="tax_authority.ShortDesc">
        <div class="flex align-items-center">
          Short Description
          <p-sortIcon field="tax_authority.ShortDesc"></p-sortIcon>
          <p-columnFilter type="text" field="tax_authority.ShortDesc" display="menu"></p-columnFilter>
        </div>
      </th>
      <th style="min-width:15rem" pSortableColumn="tax_authority.Desc">
        <div class="flex align-items-center">
          Description
          <p-sortIcon field="tax_authority.Desc"></p-sortIcon>
          <p-columnFilter type="text" field="tax_authority.Desc" display="menu"></p-columnFilter>
        </div>
      </th>
      <th style="min-width:15rem" pSortableColumn="tax_authority.isDefault">
        <div class="flex align-items-center">
          Is Default
          <p-sortIcon field="tax_authority.isDefault"></p-sortIcon>
          <p-columnFilter type="text" field="tax_authority.isDefault" display="menu"></p-columnFilter>
        </div>
      </th>
      <th style="width: 8rem"></th>
    </tr>
  </ng-template>
  <ng-template pTemplate="body" let-tax_authority>
    <tr [ngClass]="{'deleted-record': tax_authority.deleteDate}">
      <td>
        <span class="p-column-title"><strong>Id</strong></span>
        {{tax_authority.Id}}
      </td>
      <td>
        <span class="p-column-title"><strong>Short Description</strong></span>
        {{tax_authority.ShortDesc}}
      </td>
      <td>
        <span class="p-column-title"><strong>Description</strong></span>
        {{tax_authority.Desc}}
      </td>
      <td>
        <span class="p-column-title"><strong>Is Default</strong></span>
        {{tax_authority.isDefault}}
      </td>
      <td style="text-align: center">
        <button pButton
                pRipple
                icon="pi pi-pencil"
                class="p-button-rounded p-button-primary mr-2"
                (click)="openEntity(tax_authority.Id)"
                pTooltip="Edit"
                tooltipPosition="left">
        </button>
      </td>
    </tr>
  </ng-template>
  <ng-template pTemplate="emptymessage">
    <tr>
      <td colspan="7">No tax authorities found.</td>
    </tr>
  </ng-template>
</p-table>
