import { Injectable } from '@angular/core';
import {
  CheckoutTab,
  CheckoutTabsDisabled,
  CheckoutTotals,
  FormInputAnswers,
  ProcessContractDto,
  ProcessContractResponse,
  SaveAddressDto,
  UserAddress
} from '../models';
import { NgxAimService } from '../ngx-aim.service';
import { HttpClient, HttpParams } from '@angular/common/http';
import { BehaviorSubject, Observable } from 'rxjs';
import { AddressType, CheckoutTabNames } from '../enums';
import { TaxAmountDto } from '../models/dto/tax.dto';
import { HttpErrorService } from './http-error.service';
import { ContractsService } from './contracts.service';
import { GenericCheckoutService } from './generic-checkout.service';
import { PaymentService } from './payment.service';

@Injectable({
  providedIn: 'root'
})
export class ContractsCheckoutService implements GenericCheckoutService {
  private baseUrl = this.ngxAimService.getApiUrl();

  public tabs: CheckoutTab[] = [];

  private tabIndexSubject = new BehaviorSubject<number>(0);
  public tabIndex$ = this.tabIndexSubject.asObservable();
  public setTabIndex(index: number) {
    this.tabIndexSubject.next(index);
  }
  public setTabIndexByHeader(header: CheckoutTabNames) {
    const tab = this.tabs.find((tab) => tab.header === header);
    if (tab) {
      this.tabIndexSubject.next(tab.index);
    } else {
      console.error(`unable to find tab header: ${header}`);
    }
  }

  private tabsDisabledSubject = new BehaviorSubject<CheckoutTabsDisabled>({
    [CheckoutTabNames.BILLING_ADDRESS]: false,
    [CheckoutTabNames.PAYMENT]: true,
    [CheckoutTabNames.OVERVIEW]: true,
  });
  public tabsDisabed$ = this.tabsDisabledSubject.asObservable();
  public setTabIsDisabled(tabIndex: CheckoutTabNames, isDisabled: boolean) {
    const tabs = this.tabsDisabledSubject.value;
    tabs[tabIndex] = isDisabled;
    this.tabsDisabledSubject.next(tabs);
  }

  private isLoadingTotalsSubject = new BehaviorSubject<boolean>(false);
  public isLoadingTotals$ = this.isLoadingTotalsSubject.asObservable();
  private totalsSubject = new BehaviorSubject<CheckoutTotals>({
    items: 0,
    shipping: 0,
    tax: 0,
    total: 0,
  });
  public totals$ = this.totalsSubject.asObservable();

  async calculateTotals() {
    this.isLoadingTotalsSubject.next(true);
    const shipping = this.contractsService.selectedDelivery?.Price || 0;
    const insturmentTotal = this.getInstrumentTotal();
    const tax = await this.getTaxAmount(insturmentTotal + shipping);
    this.totalsSubject.next({
      items: this.getInstrumentTotal(),
      shipping: shipping,
      tax: tax,
      total: insturmentTotal + shipping + tax,
    });
    this.isLoadingTotalsSubject.next(false);
  }

  getAccessoryTotal(): number {
    return this.contractsService.selectedAccessories?.reduce((acc, accessory) => acc + accessory.Price, 0) || 0;
  }

  getInstrumentTotal(): number {
    return this.getAccessoryTotal() +
      (this.contractsService.selectedGrade?.DownPay || 0) +
      (this.contractsService.selectedGrade?.DownMaint || 0);
  }

  constructor(
    private ngxAimService: NgxAimService,
    private http: HttpClient,
    private contractsService: ContractsService,
    private httpErrorService: HttpErrorService,
    private paymentService: PaymentService,
  ) {}

  saveAddress(address: SaveAddressDto): Observable<void> {
    return this.http.post<void>(`${this.baseUrl}/users/save-address`, address);
  }

  getAddress(type: AddressType): Observable<UserAddress> {
    return this.http.get<UserAddress>(`${this.baseUrl}/users/get-address`, {
      params: new HttpParams()
        .set('type', type)
    });
  }

  getTaxAmount(amount: number): Promise<number> {
    return new Promise<number>((resolve) => {
      this.http.post<TaxAmountDto>(`${this.baseUrl}/payment/tax`, { amount }).subscribe({
        next: (res) => resolve(res.amount),
        error: (err) => {
          this.httpErrorService.onHttpError(err, 'Could not calculate sales tax');
          resolve(0);
        }
      });
    });
  }

  checkout(formInputAnswers: FormInputAnswers[]): Observable<ProcessContractResponse> {
    const accessoryIds: number[] = [];
    this.contractsService.selectedAccessories?.forEach(acc => {
      accessoryIds.push(acc.Id);
    });
    this.contractsService.selectedMaintenance?.forEach(maint => {
      accessoryIds.push(maint.Id);
    });
    if (this.contractsService.selectedDelivery) {
      accessoryIds.push(this.contractsService.selectedDelivery.Id);
    }

    const dto: ProcessContractDto = {
      cardData: this.paymentService.cardData,
      TeacherId: this.contractsService.selectedTeacher?.Id,
      SchoolName: this.contractsService.selectedSchool?.SchoolName,
      AIMSchoolId: this.contractsService.selectedSchool?.AIMSchoolId,
      InstrumentId: this.contractsService.selectedInstrument?.InstrumentId,
      RateId: this.contractsService.selectedGrade?.Id,
      AccessoryIdList: accessoryIds,
      accessoryIds: this.contractsService.selectedAccessories?.map(acc => acc.Id) || [],
      maintenanceIds: this.contractsService.selectedMaintenance?.map(maint => maint.Id) || [],
      deliveryId: this.contractsService.selectedDelivery?.Id,
      formInputAnswers: formInputAnswers,
    }
    return this.http.post<ProcessContractResponse>(`${this.baseUrl}/contracts/checkout`, dto);
  }

  public reset() {
    this.paymentService.reset();
    this.tabIndexSubject.next(0);
    this.tabsDisabledSubject.next({
      [CheckoutTabNames.BILLING_ADDRESS]: false,
      [CheckoutTabNames.PAYMENT]: true,
      [CheckoutTabNames.OVERVIEW]: true,
    });

    this.totalsSubject.next({
      items: 0,
      shipping: 0,
      tax: 0,
      total: 0,
    });
  }
}
