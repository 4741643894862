import { inject } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { ATT_PREFIX, SelectedAttribute, WebCategoryJsonAttribute } from '../../../../models';

/** when extending this class be sure to implement
 * @Input()
 * override attribute: WebCategoryJsonAttribute | undefined;
 *
 * @Input()
 * override set selectedAttributes(attributes: SelectedAttribute[]) {
 *   for (const att of attributes) {
 *     if (att.Description === this.attribute?.Description) {
 *       this.selected = att.selectedValue;
 *       return;
 *     }
 *   }
 *   this.selected = null;
 * }
 */
export abstract class NgxAttributeFilterComponent {
  router = inject(Router);
  route = inject(ActivatedRoute);

  attribute: WebCategoryJsonAttribute | undefined;

  set selectedAttributes(attributes: SelectedAttribute[]) {
    for (const att of attributes) {
      if (att.Description === this.attribute?.Description) {
        this.selected = att.selectedValue;
        return;
      }
    }
    this.selected = null;
  }

  selected: string | null = null;

  onChange(event: any) {
    this.router.navigate(
      [],
      {
        relativeTo: this.route,
        queryParams: {
          [`${ATT_PREFIX}${this.attribute?.Description}`]: event.value || '',
        },
        queryParamsHandling: 'merge',
      }
    );
  }
}
