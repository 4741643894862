import { inject } from '@angular/core';
import { CustomerService, HttpErrorService } from '../../services';
import { PublicRoutes } from '../../enums';
import { AutoPayInfoResponse } from '../../models';

/** when extending this class be sure to implement ngOnInit */
export abstract class NgxManageCreditCardComponent {
  // ngOnInit() {
  //   this.init();
  // }

  customerService = inject(CustomerService);
  httpErrorService = inject(HttpErrorService);

  routes = PublicRoutes;
  isLoading = false;
  data: AutoPayInfoResponse | undefined;

  init() {
    this.isLoading = true;
    this.customerService.getAutoPayInfo().subscribe({
      next: (data) => this.getSuccess(data),
      error: (err) => this.onFailure(err),
    });
  }

  getSuccess(data: AutoPayInfoResponse) {
    this.isLoading = false;
    this.data = data;
  }

  onFailure(err: any) {
    this.isLoading = false;
    this.httpErrorService.onHttpError(err, 'Could not manage card');
  }
}