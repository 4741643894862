import { Component, Input, OnInit } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ButtonModule } from 'primeng/button';
import { DataViewModule } from 'primeng/dataview';
import { DividerModule } from 'primeng/divider';
import { DialogModule } from 'primeng/dialog';
import { CardModule } from 'primeng/card';
import { ReactiveFormsModule } from '@angular/forms';
import { InputTextModule } from 'primeng/inputtext';
import { NgxAimOverviewContractsComponent } from './ngx-aim-overview-contracts.class';

@Component({
  selector: 'ngx-aim-overview-contracts',
  standalone: true,
  imports: [
    CommonModule,
    ButtonModule,
    DataViewModule,
    DividerModule,
    DialogModule,
    CardModule,
    ReactiveFormsModule,
    InputTextModule,
  ],
  templateUrl: './ngx-overview-contracts.component.html',
  styleUrls: ['./ngx-overview-contracts.component.css']
})
export class NgxOverviewContractsComponent extends NgxAimOverviewContractsComponent implements OnInit {
  @Input({ required: true })
  override completeUrl: string = '';

  ngOnInit() {
    this.init();
  }
}
