<div class="col-12 p-0 mt-1 mb-5 ">
  <form [formGroup]="form" class="p-fluid p-formgrid grid dialog-form">
    <div class="field col-4">
      <label for="hour" class="text-xs ml-3">Hour</label>
      <p-dropdown [options]="hourValues"
                  [autoDisplayFirst]="false"
                  [pTooltip]="toolTip"
                  appendTo="body"
                  formControlName="hour"
                  id="hour">
      </p-dropdown>
    </div>
    <div class="field col-4">
      <label for="minute" class="text-xs ml-3">Minute</label>
      <p-dropdown [options]="minuteValues"
                  [autoDisplayFirst]="false"
                  [pTooltip]="toolTip"
                  appendTo="body"
                  formControlName="minute"
                  id="minute">
      </p-dropdown>
    </div>
    <div class="field col-4">
      <label for="meridiem" class="text-xs ml-3">AM/PM</label>
      <p-dropdown [options]="meridiemValues"
                  [autoDisplayFirst]="false"
                  [pTooltip]="toolTip"
                  appendTo="body"
                  formControlName="meridiem"
                  id="meridiem">
      </p-dropdown>
    </div>
  </form>
</div>
