<ng-container *ngIf="!isRental; else rental">
  <span class="text-2xl font-semibold">{{ item.ActiveePrice | currency }}</span>
</ng-container>
<ng-template #rental>
  <ng-container *ngIf="!isLoading; else loadingTemplate">
    <ng-container *ngIf="error; else rateTemplate">
      {{error}}
    </ng-container>
    <ng-template #rateTemplate>

      <p-dropdown [options]="rates" [(ngModel)]="selectedRate" optionLabel="Desc" placeholder="Select a Freqneucy">
        <ng-template pTemplate="selectedItem">
          <div class="flex align-items-center gap-2" *ngIf="selectedRate">
            <div>{{ selectedRate.Desc }} {{selectedRate.Amt | currency}}</div>
          </div>
        </ng-template>
        <ng-template let-rate pTemplate="item">
          <div class="flex align-items-center gap-2">
            <div>{{ rate.Desc }} {{rate.Amt | currency}}</div>
          </div>
        </ng-template>
      </p-dropdown>

    </ng-template>
  </ng-container>
  <ng-template #loadingTemplate>
    <p-progressSpinner styleClass="w-4rem h-4rem" strokeWidth="8" fill="var(--surface-ground)" animationDuration=".5s"></p-progressSpinner>
  </ng-template>
</ng-template>
