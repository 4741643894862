import { inject } from '@angular/core';
import { combineLatest, distinctUntilChanged } from 'rxjs';
import { ActivatedRoute, ParamMap, Router } from '@angular/router';
import { LazyLoadEvent, MessageService, SelectItem } from 'primeng/api';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { DialogService, DynamicDialogRef } from 'primeng/dynamicdialog';
import { ATT_PREFIX, CatForDisplay, FilterChip, ItemForDisplay, ProductsVM, SelectedAttribute } from '../../models';
import { AppStateService, CartService, ItemFilterService, UiService } from '../../services';
import { ItemPageSizes, ItemSortBy, PaymentType } from '../../enums';

/** when extending this class be sure to implement ngOnInit */
export abstract class NgxProductsComponent {
  // ngOnInit() {
  //   this.init();
  // }

  itemFilter = inject(ItemFilterService);
  route = inject(ActivatedRoute);
  router = inject(Router);
  uiService = inject(UiService);
  cartService = inject(CartService);
  messageService = inject(MessageService);
  dialogService = inject(DialogService);
  appState = inject(AppStateService);

  params$ = this.route.queryParamMap.pipe(
    distinctUntilChanged(),
    takeUntilDestroyed(),
  );
  showSubCatImages$ = this.itemFilter.showSubCatImages$.pipe(
    distinctUntilChanged(),
    takeUntilDestroyed(),
  );

  readonly vm$ = combineLatest<ProductsVM>({
    data: this.itemFilter.data$,
    dataCount: this.itemFilter.dataCount$,
    rows: this.itemFilter.rows$,
    first: this.itemFilter.first$,
    sortBy: this.itemFilter.sortBy$,
  });

  pageSizes = ItemPageSizes;
  isMobile$ = this.uiService.isMobile$;
  isLoading$ = this.itemFilter.isLoading$;
  chips$ = this.itemFilter.filterChips$;
  subCategories$ = this.itemFilter.subCategories$;
  layout: 'list' | 'grid' = 'grid';
  sortOptions: SelectItem[] = [
    { label: 'Newest First', value: ItemSortBy.NEWEST },
    { label: 'Price High to Low', value: ItemSortBy.HIGHEST_PRICE },
    { label: 'Price Low to High', value: ItemSortBy.LOWEST_PRICE },
  ];
  addRentalRef: DynamicDialogRef | undefined;
  skipLazyLoad = true;
  filterSidebarVisible = false;
  isRental = false;
  showBuyButton = false;

  constructor() {
    this.showBuyButton = this.appState.paymentType === PaymentType.NONE ? false : true;
  }

  init() {
    this.params$.subscribe((params) => {
      this.setFilter(params);
      this.isRental = params.get('rental') === 'true' ? true : false;
    });
  }

  onLazyLoad(lazyLoadEvent: LazyLoadEvent) {
    if (this.skipLazyLoad) {
      this.skipLazyLoad = false;
      return;
    }

    this.router.navigate(
      [],
      {
        relativeTo: this.route,
        queryParams: {
          rows: lazyLoadEvent.rows,
          first: lazyLoadEvent.first,
        },
        queryParamsHandling: 'merge',
      }
    );
  }

  setFilter(params: ParamMap) {
    this.itemFilter.setRows(parseInt(params.get('rows') || ItemPageSizes[0].toString()));
    this.itemFilter.setFrist(parseInt(params.get('first') || '0'));
    this.itemFilter.setSortBy(parseInt(params.get('sortBy') || ItemSortBy.NEWEST.toString()));
    this.itemFilter.setCategory(parseInt(params.get('category')!)); // return NaN if no category is set
    this.itemFilter.setSearchTerm(params.get('searchTerm') || '');
    this.itemFilter.setShowSubCatImages(params.get('ssci') === 'true' ? true : false);
    this.itemFilter.setManufacturer(params.get('manufacturer') || '');
    this.itemFilter.setMinMax({
      min: Number(params.get('min')) || null,
      max: Number(params.get('max')) || null,
    });

    const selectedAttributes: SelectedAttribute[] = [];
    params.keys
      .filter(key => key.startsWith(ATT_PREFIX))
      .forEach(attributeDescription => {
        const attributeValue = params.get(attributeDescription)
        if (attributeValue) {
          const Description = attributeDescription.split(ATT_PREFIX)[1];
          selectedAttributes.push({ Description, selectedValue: attributeValue });
        }
      });
    this.itemFilter.setSelectedAttributes(selectedAttributes);

    this.itemFilter.onFilterEvent();
  }

  onSortChange(event: any) {
    this.router.navigate(
      [],
      {
        relativeTo: this.route,
        queryParams: {
          sortBy: event.value,
        },
        queryParamsHandling: 'merge',
      }
    );
  }

  onRemoveChip(chip: FilterChip) {
    if (chip.type === 'attribute') {
      this.router.navigate(
        [],
        {
          relativeTo: this.route,
          queryParams: {
            [`${ATT_PREFIX}${chip.name}`]: '',
          },
          queryParamsHandling: 'merge',
        }
      );
      return;
    }

    if (chip.type === 'searchTerm') {
      this.router.navigate(
        [],
        {
          relativeTo: this.route,
          queryParams: {
            searchTerm: '',
          },
          queryParamsHandling: 'merge',
        }
      );
    }

    if (chip.type === 'manufacturer') {
      this.router.navigate(
        [],
        {
          relativeTo: this.route,
          queryParams: {
            manufacturer: '',
          },
          queryParamsHandling: 'merge',
        }
      );
    }
  }

  onCategoryClick(category: CatForDisplay) {
    this.router.navigate(
      [],
      {
        relativeTo: this.route,
        queryParams: {
          category: category.Id,
          ssci: category.showSubCatImages,
        },
        queryParamsHandling: 'merge',
      }
    );
  }

  onAddToCartClick(item: ItemForDisplay) {
    if (!this.isRental) {
      this.addPurchaseToCart(item);
    }
  }

  addPurchaseToCart(item: ItemForDisplay) {
    this.cartService.updateItemCartQuantity(item);
    this.messageService.add({
      severity: 'success',
      summary: `${item.Model} added to cart`,
      key: 'app-toast'
    });
  }

  onMobileApplyClick() {
    this.filterSidebarVisible = false;
  }
}