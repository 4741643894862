import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'outOfStock',
  standalone: true
})
export class OutOfStockPipe implements PipeTransform {
  transform(value: number): string {
    return value <= 0 ? 'out of stock' : '';
  }
}
