<p-toolbar>
  <div class="p-toolbar-group-start">
    <p-button label="Sync"
              icon="pi pi-sync"
              class="p-button-primary mr-2"
              (click)="onSyncClick()"
              [loading]="isSyncing">
    </p-button>
  </div>
</p-toolbar>

<p-table #dt
         dataKey="id"         
         [value]="(data$ | async)!"
         [loading]="(isLoading$ | async)!"
         [totalRecords]="(dataCount$ | async)!"
         [lazy]="true"
         [rows]="10"
         [showCurrentPageReport]="true"
         [rowsPerPageOptions]="[10, 25, 50]"
         [paginator]="true"
         [globalFilterFields]="['customer_default.Id', 'customer_default.Desc', 'customer_default.ShortDesc']"
         [filterDelay]="250"
         [breakpoint]="'960px'"
         [rowHover]="true"
         (onLazyLoad)="onLazyLoad($event)"
         currentPageReportTemplate="Showing {first} to {last} of {totalRecords} entries"
         sortField="customer_default.Id"
         sortMode="single"
         responsiveLayout="stack">
  <ng-template pTemplate="caption">
    <div class="flex">
      Customer Defaults
      <span class="p-input-icon-left ml-auto">
        <i class="pi pi-search"></i>
        <input pInputText type="text" placeholder="Search keyword" (input)="applyFilterGlobal($event)" />
      </span>
    </div>
  </ng-template>
  <ng-template pTemplate="header">
    <tr>
      <th style="min-width:15rem" pSortableColumn="customer_default.Id">
        <div class="flex align-items-center">
          Id
          <p-sortIcon field="customer_default.Id"></p-sortIcon>
          <p-columnFilter type="text" field="customer_default.Id" display="menu"></p-columnFilter>
        </div>
      </th>
      <th style="min-width:15rem" pSortableColumn="customer_default.ShortDesc">
        <div class="flex align-items-center">
          Short Description
          <p-sortIcon field="customer_default.ShortDesc"></p-sortIcon>
          <p-columnFilter type="text" field="customer_default.ShortDesc" display="menu"></p-columnFilter>
        </div>
      </th>
      <th style="min-width:15rem" pSortableColumn="customer_default.Desc">
        <div class="flex align-items-center">
          Description
          <p-sortIcon field="customer_default.Desc"></p-sortIcon>
          <p-columnFilter type="text" field="customer_default.Desc" display="menu"></p-columnFilter>
        </div>
      </th>
      <th style="min-width:15rem" pSortableColumn="customer_default.isDefault">
        <div class="flex align-items-center">
          Is Default
          <p-sortIcon field="customer_default.isDefault"></p-sortIcon>
          <p-columnFilter type="text" field="customer_default.isDefault" display="menu"></p-columnFilter>
        </div>
      </th>
      <th style="width: 8rem"></th>
    </tr>
  </ng-template>
  <ng-template pTemplate="body" let-customer_default>
    <tr [ngClass]="{'deleted-record': customer_default.deleteDate}">
      <td>
        <span class="p-column-title"><strong>Id</strong></span>
        {{customer_default.Id}}
      </td>
      <td>
        <span class="p-column-title"><strong>Short Description</strong></span>
        {{customer_default.ShortDesc}}
      </td>
      <td>
        <span class="p-column-title"><strong>Description</strong></span>
        {{customer_default.Desc}}
      </td>
      <td>
        <span class="p-column-title"><strong>Is Default</strong></span>
        {{customer_default.isDefault}}
      </td>
      <td style="text-align: center">
        <button pButton
                pRipple
                icon="pi pi-pencil"
                class="p-button-rounded p-button-primary mr-2"
                (click)="openEntity(customer_default.Id)"
                pTooltip="Edit"
                tooltipPosition="left">
        </button>
      </td>
    </tr>
  </ng-template>
  <ng-template pTemplate="emptymessage">
    <tr>
      <td colspan="7">No customer defaults found.</td>
    </tr>
  </ng-template>
</p-table>
