import { Component, Input, OnInit } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ProgressSpinnerModule } from 'primeng/progressspinner';
import { SubCategory } from '../../../../models';
import { NgxRentalPriceComponent } from './ngx-rental-price.class';

@Component({
  selector: 'ngx-aim-rental-price',
  standalone: true,
  imports: [CommonModule, ProgressSpinnerModule],
  templateUrl: './rental-price.component.html',
  styleUrls: ['./rental-price.component.scss']
})
export class NgxAimRentalPriceComponent extends NgxRentalPriceComponent implements OnInit {
  @Input()
  override itemPk: number | undefined;

  @Input()
  override subCategory: SubCategory | undefined

  ngOnInit() {
    this.init();
  }
}
