import { inject } from '@angular/core';
import { AppStateService, CheckoutService } from '../../../services';
import { CheckoutTabNames, PaymentType } from '../../../enums';
import { ShipEngineRateEstimate } from '../ngx-delivery-options/delivery-options.model';
import { NgxDeliveryOptionsService } from '../ngx-delivery-options/ngx-delivery-options.service';

export abstract class NgxAimDeliveryOptionsSerializedComponent {
  checkoutService = inject(CheckoutService);
  deliveryService = inject(NgxDeliveryOptionsService);
  appState = inject(AppStateService);

  selectedOption: ShipEngineRateEstimate | undefined;
  options$ = this.deliveryService.serializedDeliveryOptions$;
  loading$ = this.deliveryService.loadingDeliveryOptions$;

  onSelectOptionCLick() {
    if (!this.selectedOption) {
      return;
    }

    this.deliveryService.setSelectedSerializedDeliveryOption(this.selectedOption);
    if (this.appState.paymentType === PaymentType.NONE) {
      this.checkoutService.setTabIsDisabled(CheckoutTabNames.OVERVIEW, false);
      this.checkoutService.setTabIndexByHeader(CheckoutTabNames.OVERVIEW);
    } else {
      this.checkoutService.setTabIsDisabled(CheckoutTabNames.PAYMENT, false);
      this.checkoutService.setTabIndexByHeader(CheckoutTabNames.PAYMENT);
    }
  }
}