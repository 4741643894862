import { DestroyRef, ViewContainerRef, inject } from '@angular/core';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { ActivatedRoute, Router } from '@angular/router';
import { distinctUntilChanged } from 'rxjs';
import { MessageService } from 'primeng/api';
import { FormBuilder } from '@angular/forms';
import { AppStateService, CustomerService, HttpErrorService, PaymentService } from '../../services';
import { CustomerHistoryGeneric, CustomerHistoryGenericPaymentDto, UpdateCCTokenResponse } from '../../models';
import { PaymentStatus, PaymentType, PublicRoutes, TransactionType } from '../../enums';

/** when extending this class be sure to implement ngOnInit,
 * @ViewChild('paymentForm', { read: ViewContainerRef })
 * override paymentFormRef!: ViewContainerRef; 
 */
export abstract class NgxMakePaymentComponent {
  // ngOnInit() {
  //   this.init();
  // }

  route = inject(ActivatedRoute);
  router = inject(Router);
  appState = inject(AppStateService);
  customerService = inject(CustomerService);
  paymentService = inject(PaymentService);
  httpErrorService = inject(HttpErrorService);
  messageService = inject(MessageService);
  fb = inject(FormBuilder);

  paymentFormRef!: ViewContainerRef;

  params$ = this.route.params.pipe(
    distinctUntilChanged(),
    takeUntilDestroyed(),
  );

  form = this.fb.group({
    paymentAmount: this.fb.control<number | null>(null),
  });

  invoice: CustomerHistoryGeneric | undefined;
  destroyRef = inject(DestroyRef);
  isLoading = false;
  isDisabled = true;

  constructor() {
    this.invoice = this.router.getCurrentNavigation()?.extras.state as CustomerHistoryGeneric;
    if (!this.invoice) {
      this.router.navigate([PublicRoutes.CUSTOMER, PublicRoutes.OPEN_INVOICES]);
    }
  }

  init() {
    this.initPaymentForm(this.appState.paymentType);
  }

  initPaymentForm(type: PaymentType) {
    this.paymentService.cardData.type = type;
    switch (type) {
      case PaymentType.AUTHORIZENET: {
        import('../payment/ngx-authorizenet/ngx-authorizenet.component').then(c => {
          const comp = this.paymentFormRef.createComponent(c.NgxAuthorizenetComponent);
          comp.instance.success.pipe(takeUntilDestroyed(this.destroyRef)).subscribe((isSuccess) => {
            this.onCardSuccess();
          });
        });
        break;
      }
      case PaymentType.GLOBALPAY:
      case PaymentType.GLOBALPAY_AUTH_ONLY: {
        import('../payment/ngx-globalpay/ngx-globalpay.component').then(c => {
          const comp = this.paymentFormRef.createComponent(c.NgxGlobalpayComponent);
          comp.instance.success.pipe(takeUntilDestroyed(this.destroyRef)).subscribe((isSuccess) => {
            this.onCardSuccess();
          });
        });
        break;
      }
      case PaymentType.TSYS_AUTH_ONLY: {
        import('../payment/ngx-tsys/ngx-tsys.component').then(c => {
          const comp = this.paymentFormRef.createComponent(c.NgxTsysComponent);
          comp.instance.transactionType = TransactionType.CONTRACT;
          comp.instance.success.pipe(takeUntilDestroyed(this.destroyRef)).subscribe((isSuccess) => {
            this.onCardSuccess();
          });
        });
        break;
      }
    }
  }

  onCardSuccess() {
    this.isLoading = false;
    this.isDisabled = false;
  }

  updateSuccess(res: UpdateCCTokenResponse) {
    this.isLoading = false;
    if (res.paymentStatus === PaymentStatus.APPROVED) {
      this.router.navigateByUrl(`/${PublicRoutes.CUSTOMER}/${PublicRoutes.MANAGE_CREDIT_CARD}`);
    } else {
      this.messageService.add({
        severity: 'error',
        summary: 'Could not update credit card',
        key: 'app-toast'
      });
    }
  }

  onSubmitClick() {
    this.isLoading = true;
    if (!this.invoice) return;
    const dto: CustomerHistoryGenericPaymentDto = {
      ...this.invoice,
      paymentAmount: +this.form.controls.paymentAmount.value!,
    }
    this.customerService.makePayment(this.paymentService.cardData, dto).subscribe({
      next: (res) => this.onPaymentSuccess(res),
      error: (err) => this.onFailure(err),
    });
  }

  onPaymentSuccess(res: UpdateCCTokenResponse) {
    this.isLoading = false;
    if (res.paymentStatus === PaymentStatus.APPROVED) {
      this.router.navigateByUrl(`/${PublicRoutes.CUSTOMER}/${PublicRoutes.OPEN_INVOICES}`);
    } else {
      this.messageService.add({
        severity: 'error',
        summary: 'Could not make payment',
        key: 'app-toast'
      });
    }
  }

  onFailure(err: any) {
    this.isLoading = false;
    this.httpErrorService.onHttpError(err, 'Could not make payment');
  }
}