export type PickerHours = '1' | '2' | '3' | '4' | '5' | '6' | '7' | '8' | '9' | '10' | '11' | '12';
export const TimePickerHours: PickerHours[] = [
  '1',
  '2',
  '3',
  '4',
  '5',
  '6',
  '7',
  '8',
  '9',
  '10',
  '11',
  '12',
];

export type PickerMinutes = '00' | '15' | '30' | '45';
export const TimePickerMinutes: PickerMinutes[] = [
  '00',
  '15',
  '30',
  '45',
];

export type PickerMeridiem = 'AM' | 'PM';
export const TimePickerMeridiem: PickerMeridiem[] = [
  'AM',
  'PM',
];
