import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ToolbarModule } from 'primeng/toolbar';
import { ConfirmPopupModule } from 'primeng/confirmpopup'
import { DialogModule } from 'primeng/dialog';
import { CheckboxModule } from 'primeng/checkbox';
import { ButtonModule } from 'primeng/button';
import { TableModule } from 'primeng/table';
import { RippleModule } from 'primeng/ripple';
import { ProgressSpinnerModule } from 'primeng/progressspinner';
import { DividerModule } from 'primeng/divider';
import { InputTextModule } from 'primeng/inputtext';
import { DropdownModule } from 'primeng/dropdown';
import { MultiSelectModule } from 'primeng/multiselect';
import { TagModule } from 'primeng/tag';
import { MenuModule } from 'primeng/menu';
import { MenubarModule } from 'primeng/menubar';
import { ListboxModule } from 'primeng/listbox';
import { InputTextareaModule } from 'primeng/inputtextarea';

const PrimeModules = [
  ToolbarModule,
  ConfirmPopupModule,
  DialogModule,
  CheckboxModule,
  ButtonModule,
  TableModule,
  RippleModule,
  ProgressSpinnerModule,
  DividerModule,
  InputTextModule,
  DropdownModule,
  MultiSelectModule,
  TagModule,
  MenuModule,
  MenubarModule,
  InputTextareaModule,
  ListboxModule,
];

@NgModule({
  imports: [CommonModule, PrimeModules],
  exports: [PrimeModules]
})
export class AdminPrimengModule {}
