import { Component, Input, OnInit } from '@angular/core';
import { CommonModule } from '@angular/common';
import { CarouselModule } from 'primeng/carousel';
import { CarouselName } from '../../enums';
import { NgxCarouselComponent } from './ngx-carousel.class';

@Component({
  selector: 'ngx-aim-carousel',
  standalone: true,
  imports: [CommonModule, CarouselModule],
  templateUrl: './carousel.component.html',
  styleUrls: ['./carousel.component.css'],
})
export class CarouselComponent extends NgxCarouselComponent implements OnInit {
  @Input() 
  override carouselName: CarouselName = CarouselName.MAIN;

  ngOnInit() {
    this.init()
  }
}
