import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { NgxAimService } from '../../../../ngx-aim.service';
import { CarouselSlide, SaveCarouselSlideDto } from '../../../../models';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class AdminCarouselService {

  private baseUrl = `${this.ngxAimService.getApiUrl()}/admin/carousel-slide`;

  constructor(
    private http: HttpClient,
    private ngxAimService: NgxAimService,
  ) {}

  findAll(name: string): Observable<CarouselSlide[]> {
    return this.http.get<CarouselSlide[]>(`${this.baseUrl}/all/${name}`);
  }

  findOne(id: number): Observable<CarouselSlide> {
    return this.http.get<CarouselSlide>(`${this.baseUrl}/slide/${id}`);
  }

  save(dto: SaveCarouselSlideDto): Observable<CarouselSlide> {
    return this.http.post<CarouselSlide>(this.baseUrl, dto);
  }

  setOrder(dto: CarouselSlide[]): Observable<CarouselSlide[]> {
    return this.http.post<CarouselSlide[]>(`${this.baseUrl}/set-order`, dto);
  }

  remove(id: number): Observable<number> {
    return this.http.delete<number>(`${this.baseUrl}/${id}`);
  }
}
