import { Component, ViewChild } from '@angular/core';
import { CommonModule } from '@angular/common';
import { DialogService } from 'primeng/dynamicdialog';
import { Table, TableLazyLoadEvent } from 'primeng/table';
import { EditCarrierServiceComponent } from './edit-carrier-service/edit-carrier-service.component';
import { AdminPrimengModule } from '../../../../admin.primeng.module';
import { AppLazyLoadEvent, ShipEngineCarrier } from '../../../../../../models';
import { HttpErrorService } from '../../../../../../services';
import { MatchModesPrime } from '../../../../../../enums';
import { AdminShipEngineService } from '../admin-ship-engine.service';

@Component({
  selector: 'aim-carriers-services',
  standalone: true,
  imports: [CommonModule, AdminPrimengModule],
  templateUrl: './carriers-services.component.html',
  styleUrls: ['./carriers-services.component.scss', '../../../../admin.scss'],
  providers: [DialogService],
})
export class CarriersServicesComponent {
  @ViewChild('dt') table: Table | undefined;
  data$ = this.entityService.data$;
  isLoading$ = this.entityService.isLoading$;
  dataCount$ = this.entityService.dataCount$;
  lastLazyLoadEvent: AppLazyLoadEvent = {
    globalFilterFields: [],
    filters: {}
  };
  selectedOptions: string[] = [];
  isSyncing = false;

  constructor(
    private entityService: AdminShipEngineService,
    private dialogService: DialogService,
    private httpErrorService: HttpErrorService,
  ) {}

  onLazyLoad($event: TableLazyLoadEvent) {
    this.lastLazyLoadEvent = $event;
    const lazyLoadEvent: AppLazyLoadEvent = Object.assign({
      withDeleted: this.selectedOptions.includes('withDeleted'),
      globalFilterFields: this.table?.globalFilterFields || [],
    }, $event);

    this.entityService.onFilterEvent(lazyLoadEvent);
  }

  applyFilterGlobal($event: Event) {
    this.table?.filterGlobal(($event.target as HTMLInputElement).value, MatchModesPrime.CONTAINS);
  }

  openEntity(carrier: ShipEngineCarrier) {
    const dialog = this.dialogService.open(EditCarrierServiceComponent, {
      data: carrier,
      header: 'Carrier',
      width: '700px',
      contentStyle: { "max-height": "580px", "overflow": "auto" },
      dismissableMask: true
    });
    dialog.onClose.subscribe(() => {
      this.onLazyLoad(this.lastLazyLoadEvent);
    });
  }

  onSyncClick() {
    this.isSyncing = true;
    this.entityService.updateCarriers().subscribe({
      next: () => { this.isSyncing = false; this.onLazyLoad(this.lastLazyLoadEvent) },
      error: (err) => { this.httpErrorService.onHttpError(err); this.isSyncing = false }
    });
  }
}
