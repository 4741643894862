<div class="col-12">
  <form [formGroup]="form" class="p-fluid p-formgrid grid dialog-form">
    <div class="field col-12 md:col-6">
      <span class="p-float-label">
        <input pInputText id="Id" formControlName="Id" />
        <label htmlFor="Id">Id</label>
      </span>
    </div>
    <div class="field col-12 md:col-6">
      <span class="p-float-label">
        <input pInputText id="ShortDesc" formControlName="ShortDesc" />
        <label htmlFor="ShortDesc">Short Description</label>
      </span>
    </div>
    <div class="field col-12 md:col-6">
      <span class="p-float-label">
        <input pInputText id="Desc" formControlName="Desc" />
        <label htmlFor="Desc">Description</label>
      </span>
    </div>
    <div class="field col-12 md:col-6">
      <span class="p-float-label">
        <input pInputText id="isDefault" formControlName="isDefault" />
        <label htmlFor="isDefault">Is Default</label>
      </span>
    </div>
    <div class="field col-12 md:col-6">
      <span class="p-float-label">
        <input pInputText id="City" formControlName="City" />
        <label htmlFor="City">City</label>
      </span>
    </div>
    <div class="field col-12 md:col-6">
      <span class="p-float-label">
        <input pInputText id="County" formControlName="County" />
        <label htmlFor="County">County</label>
      </span>
    </div>
    <div class="field col-12 md:col-6">
      <span class="p-float-label">
        <input pInputText id="ZipCode" formControlName="ZipCode" />
        <label htmlFor="ZipCode">Zip Code</label>
      </span>
    </div>
    <div class="field col-12 md:col-6">
      <span class="p-float-label">
        <input pInputText id="GeoCode" formControlName="GeoCode" />
        <label htmlFor="GeoCode">Geo Code</label>
      </span>
    </div>
    <div class="field col-12 md:col-6">
      <span class="p-float-label">
        <input pInputText id="TaxRate1" formControlName="TaxRate1" />
        <label htmlFor="TaxRate1">Tax Rate 1</label>
      </span>
    </div>
    <div class="field col-12 md:col-6">
      <span class="p-float-label">
        <input pInputText id="TaxRate2" formControlName="TaxRate2" />
        <label htmlFor="TaxRate2">Tax Rate 2</label>
      </span>
    </div>
  </form>
  <div class="flex justify-content-end">
    <p-button label="Set As Default"
              icon="pi pi-check"
              styleClass="p-button-text"
              [loading]="isSaving"
              [disabled]="form.invalid || !!this.form.get('isDefault')?.value"
              (onClick)="onSetDefault()">
    </p-button>
    <p-button label="Cancel"
              icon="pi pi-times"
              styleClass="p-button-text"
              [disabled]="isSaving"
              (onClick)="onCancel()">
    </p-button>
  </div>
  <p-divider align="left">
    <i class="pi pi-info-circle mr-2"></i>
    <b>Metadata</b>
  </p-divider>
</div>
<div class="col-12">
  <form [formGroup]="metaForm" class="p-fluid p-formgrid grid dialog-form">
    <div class="field col-12 md:col-6">
      <span class="p-float-label">
        <input pInputText id="createDate" formControlName="createDate" />
        <label htmlFor="createDate">Created</label>
      </span>
    </div>
    <div class="field col-12 md:col-6">
      <span class="p-float-label">
        <input pInputText id="updateDate" formControlName="updateDate" />
        <label htmlFor="updateDate">Updated</label>
      </span>
    </div>
  </form>
</div>
<div class="progress-spinner" *ngIf="isLoading">
  <p-progressSpinner></p-progressSpinner>
</div>