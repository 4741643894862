import { Pipe, PipeTransform } from '@angular/core';

/** must match what is in PublicRoutes.ts */
@Pipe({
  name: 'getProductLink',
  standalone: true,
  pure: true
})
export class GetProductLinkPipe implements PipeTransform {
  transform(url: string, isRental: boolean): string {
    if (isRental) {
      return `/item-rental/${url}`;
    } else {
      return `/product/${url}`;
    }
  }
}
