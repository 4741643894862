import { MenuItem } from 'primeng/api';
import { PublicRoutes } from '../../enums';
import { inject } from '@angular/core';
import { AppStateService, UiService } from '../../services';
import { MenuCommandEvent } from '../../models';

/** when extending this class be sure to implement menuItems */
export abstract class NgxLeftSidebarComponent {
  appState = inject(AppStateService);
  uiService = inject(UiService);

  /** override in extended class if you have static menu items */
  menuItems: MenuItem[] = [
    {
      label: 'Products',
      items: this.appState.categories,
    },
    // ...staticMenuItems,
    {
      label: 'Sign In',
      routerLink: `/${PublicRoutes.SIGN_IN}`,
    }
  ];

  constructor() {
    this.addMenuCommand(this.menuItems);
  }

  onItemClick(event: MenuCommandEvent) {
    // no child items so close the menu
    // navigation is handled by the slide menu
    if (!event.item.items?.length) {
      this.uiService.leftSidebarVisible = false;
    }
  }

  addMenuCommand(items?: MenuItem[]) {
    if (!items) return;
    for (const item of items) {
      item.command = (event: MenuCommandEvent) => {
        this.onItemClick(event);
      }
      this.addMenuCommand(item.items);
    }
  }
}
