<div *ngFor="let entry of log">
  <p>ID: {{entry.id}}</p>
  <p>ACTION: {{entry.action}}</p>
  <p>STATUS: {{entry.status}}</p>
  <p>CREATE DATE: {{entry.createDate | date: 'long'}}</p>
  <p>UPDATE DATE:{{entry.updateDate | date: 'long'}}</p>
  RESPONSE:
  <pre>{{entry.response | json}}</pre>
  <hr>
</div>
