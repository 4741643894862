import { ChangeDetectorRef, inject } from '@angular/core';
import { Router } from '@angular/router';
import { OverlayPanel } from 'primeng/overlaypanel';
import { distinctUntilChanged } from 'rxjs';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { MenuItem } from 'primeng/api';
import { AppStateService, AuthenticationService, CartService, UiService } from '../../services';
import { PaymentType, PublicRoutes } from '../../enums';

/** when extending this class be sure to implement ngOnInit, menuItems,
 * @ViewChild('account')
 * override accountPanel: OverlayPanel | undefined;
 */
export abstract class NgxHeaderComponent {
  // ngOnInit() {
  //   this.init();
  // }

  uiService = inject(UiService);
  appState = inject(AppStateService);
  router = inject(Router);
  authService = inject(AuthenticationService);
  cartService = inject(CartService);
  chageRef = inject(ChangeDetectorRef);

  accountPanel: OverlayPanel | undefined;

  currentUser$ = this.authService.currentUser$.pipe(
    distinctUntilChanged(),
    takeUntilDestroyed(),
  );
  cart$ = this.cartService.cart$.pipe(
    takeUntilDestroyed(),
  );

  cartTotal$ = this.cartService.cartTotal$;
  routes = PublicRoutes;
  phone = this.appState.businessInfo.phone;
  phoneUrl = this.appState.businessInfo.phoneUrl;
  businessName = this.appState.businessInfo.name;
  isMobile$ = this.uiService.isMobile$;
  /** override in extended class if you have static menu items */
  menuItems: MenuItem[] = [
    {
      label: 'Products',
      items: this.appState.categories,
    },
    // ...staticMenuItems,
  ];
  leftSidebarVisible = this.uiService.leftSidebarVisible$;
  accountText = 'Sign In / Register';
  searchTerm = '';
  itemsInCart = 0;
  isLoggedIn = false;
  showCart = false;

  constructor() {
    this.currentUser$.subscribe((currentUser) => {
      if (currentUser) {
        this.isLoggedIn = true;
        this.accountText = `${currentUser.firstName} ${currentUser.lastName}`.slice(0, 15);
      } else {
        this.isLoggedIn = false;
        this.accountText = 'Sign In / Register';
      }
    });

    this.showCart = this.appState.paymentType === PaymentType.NONE ? false : true;
  }

  init() {
    this.cart$.subscribe((cart) => {
      this.itemsInCart = cart.items.map(item => item.cartQty).reduce((acc, cartQty) => acc! + cartQty!, 0) || 0;
      this.chageRef.detectChanges();
    });
  }

  onShowLeftSidebarClick() {
    this.uiService.leftSidebarVisible = true;
  }

  onCloseLeftSidebarClick() {
    this.uiService.leftSidebarVisible = false;
  }

  onSearch() {
    this.router.navigate(
      [PublicRoutes.PRODUCTS],
      {
        queryParams: {
          searchTerm: this.searchTerm,
        },
      }
    );
  }

  onAccountClick($event: MouseEvent) {
    if (!this.isLoggedIn) {
      this.router.navigateByUrl(this.routes.SIGN_IN);
      return;
    }

    this.onAccountHover($event);
  }

  onAccountHover($event: MouseEvent) {
    if (this.accountPanel && this.isLoggedIn) {
      this.accountPanel.show($event);
    }
  }

  onCloseAccountOverlay() {
    if (this.accountPanel) {
      this.accountPanel.hide();
    }
  }

  goToHome() {
    this.router.navigate(['/'])
  }

  onClearSearchClick() {
    this.searchTerm = '';
  }
}
