import { inject } from '@angular/core';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { distinctUntilChanged } from 'rxjs';
import { ActivatedRoute, Router } from '@angular/router';
import { HttpErrorService, ShortTermRentalService } from '../../services';
import { SubCategory } from '../../models';
import { PublicRoutes } from '../../enums';

/** when extending this class be sure to implement ngOnInit */
export abstract class NgxRentalSubCategoryComponent {
  // ngOnInit() {
  //   this.init();
  // }

  rentalService = inject(ShortTermRentalService);
  route = inject(ActivatedRoute);
  httpErrorService = inject(HttpErrorService);
  router = inject(Router);

  params$ = this.route.paramMap.pipe(
    distinctUntilChanged(),
    takeUntilDestroyed(),
  );
  subCategories: SubCategory[] = [];
  isLoading = true;

  init() {
    this.params$.subscribe((params) => {
      const categorySlug = params.get('categorySlug');
      if (categorySlug) {
        this.getSubCategories(categorySlug);
      }
    });
  }

  getSubCategories(categorySlug: string) {
    this.rentalService.getSubCategories(categorySlug).subscribe({
      next: (subCategories) => this.onSuccess(subCategories),
      error: (err) => this.onFailure(err),
    });
  }

  onSuccess(subCategories: SubCategory[]) {
    this.isLoading = false;
    this.subCategories = subCategories;
  }

  onFailure(err: any) {
    this.isLoading = false;
    this.router.navigateByUrl(PublicRoutes.PAGE_NOT_FOUND);
  }
}
