<div class="mb-3">
  <p-menubar [model]="items"></p-menubar>
</div>
<p-confirmPopup key="onRefundClick" [style]="{'max-width': '300px', 'white-space': 'pre-line'}"></p-confirmPopup>
<div class="col-12">
  <form [formGroup]="form" class="p-fluid p-formgrid grid dialog-form">
    <div class="field col-12 md:col-6">
      <span class="p-float-label">
        <input pInputText id="OrderNumber" formControlName="OrderNumber" />
        <label htmlFor="OrderNumber">Number</label>
      </span>
    </div>
    <div class="field col-12 md:col-6">
      <span class="p-float-label">
        <input pInputText id="OrderDate" formControlName="OrderDate" />
        <label htmlFor="OrderDate">Order Date</label>
      </span>
    </div>
    <div class="field col-12 md:col-6">
      <span class="p-float-label">
        <input pInputText id="Total" formControlName="Total" />
        <label htmlFor="Total">Total</label>
      </span>
    </div>
    <div class="field col-12 md:col-6">
      <span class="p-float-label">
        <input pInputText id="status" formControlName="status" />
        <label htmlFor="status">Status</label>
      </span>
    </div>
    <div class="field col-12 md:col-6">
      <span class="p-float-label">
        <input pInputText id="paymentStatus" formControlName="paymentStatus" />
        <label htmlFor="paymentStatus">Payment Status</label>
      </span>
    </div>
    <div class="field col-12 md:col-6">
      <span class="p-float-label">
        <input pInputText id="accessoryDelivery" formControlName="accessoryDelivery" />
        <label htmlFor="accessoryDelivery">Delivery</label>
      </span>
    </div>
    <div class="field col-12 md:col-6">
      <span class="p-float-label">
        <input pInputText id="serializedDelivery" formControlName="serializedDelivery" />
        <label htmlFor="serializedDelivery">Serialized Delivery</label>
      </span>
    </div>
    <div class="field col-12 md:col-6">
      <span class="p-float-label">
        <input pInputText id="ShippingName" formControlName="ShippingName" />
        <label htmlFor="ShippingName">Shipping Name</label>
      </span>
    </div>
    <div class="field col-12 md:col-6">
      <span class="p-float-label">
        <input pInputText id="ShippingCompany" formControlName="ShippingCompany" />
        <label htmlFor="ShippingCompany">Shipping Company</label>
      </span>
    </div>
    <div class="field col-12 md:col-6">
      <span class="p-float-label">
        <input pInputText id="ShippingAddr1" formControlName="ShippingAddr1" />
        <label htmlFor="ShippingAddr1">Shipping Address 1</label>
      </span>
    </div>
    <div class="field col-12 md:col-6">
      <span class="p-float-label">
        <input pInputText id="ShippingAddr2" formControlName="ShippingAddr2" />
        <label htmlFor="ShippingAddr2">Shipping Address 2</label>
      </span>
    </div>
    <div class="field col-12 md:col-6">
      <span class="p-float-label">
        <input pInputText id="ShippingCity" formControlName="ShippingCity" />
        <label htmlFor="ShippingCity">Shipping City</label>
      </span>
    </div>
    <div class="field col-12 md:col-6">
      <span class="p-float-label">
        <input pInputText id="ShippingSt" formControlName="ShippingSt" />
        <label htmlFor="ShippingSt">Shipping State</label>
      </span>
    </div>
    <div class="field col-12 md:col-6">
      <span class="p-float-label">
        <input pInputText id="ShippingZip" formControlName="ShippingZip" />
        <label htmlFor="ShippingZip">Shipping Zip</label>
      </span>
    </div>
    <div class="field col-12 md:col-6">
      <span class="p-float-label">
        <input pInputText id="ShippingPhone" formControlName="ShippingPhone" />
        <label htmlFor="ShippingPhone">Shipping Phone</label>
      </span>
    </div>
    <div class="field col-12 md:col-6">
      <span class="p-float-label">
        <input pInputText id="BillingName" formControlName="BillingName" />
        <label htmlFor="BillingName">Billing Name</label>
      </span>
    </div>
    <div class="field col-12 md:col-6">
      <span class="p-float-label">
        <input pInputText id="BillingCompany" formControlName="BillingCompany" />
        <label htmlFor="BillingCompany">Billing Company</label>
      </span>
    </div>
    <div class="field col-12 md:col-6">
      <span class="p-float-label">
        <input pInputText id="BillingAddr1" formControlName="BillingAddr1" />
        <label htmlFor="BillingAddr1">Billing Address 1</label>
      </span>
    </div>
    <div class="field col-12 md:col-6">
      <span class="p-float-label">
        <input pInputText id="BillingAddr2" formControlName="BillingAddr2" />
        <label htmlFor="BillingAddr2">Billing Address 2</label>
      </span>
    </div>
    <div class="field col-12 md:col-6">
      <span class="p-float-label">
        <input pInputText id="BillingCity" formControlName="BillingCity" />
        <label htmlFor="BillingCity">Billing City</label>
      </span>
    </div>
    <div class="field col-12 md:col-6">
      <span class="p-float-label">
        <input pInputText id="BillingSt" formControlName="BillingSt" />
        <label htmlFor="BillingSt">Billing State</label>
      </span>
    </div>
    <div class="field col-12 md:col-6">
      <span class="p-float-label">
        <input pInputText id="BillingZip" formControlName="BillingZip" />
        <label htmlFor="BillingZip">Billing Zip</label>
      </span>
    </div>
    <div class="field col-12 md:col-6">
      <span class="p-float-label">
        <input pInputText id="BillingPhone" formControlName="BillingPhone" />
        <label htmlFor="BillingPhone">Billing Phone</label>
      </span>
    </div>
  </form>
  <p-divider align="left">
    <i class="pi pi-info-circle mr-2"></i>
    <b>Metadata</b>
  </p-divider>
</div>
<div class="col-12">
  <form [formGroup]="metaForm" class="p-fluid p-formgrid grid dialog-form">
    <div class="field col-12 md:col-6">
      <span class="p-float-label">
        <input pInputText id="createDate" formControlName="createDate" />
        <label htmlFor="createDate">Created</label>
      </span>
    </div>
    <div class="field col-12 md:col-6">
      <span class="p-float-label">
        <input pInputText id="updateDate" formControlName="updateDate" />
        <label htmlFor="updateDate">Updated</label>
      </span>
    </div>
    <div class="field col-12 md:col-6">
      <span class="p-float-label">
        <input pInputText id="cartCreateDate" formControlName="cartCreateDate" />
        <label htmlFor="cartCreateDate">Cart Created</label>
      </span>
    </div>
  </form>
</div>
<div class="progress-spinner" *ngIf="isLoading">
  <p-progressSpinner></p-progressSpinner>
</div>
