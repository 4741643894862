import { inject } from '@angular/core';
import { Location } from '@angular/common';
import { Router } from '@angular/router';
import { HttpErrorService, OrderService } from '../../services';
import { CheckoutCompleteNavExtras, Order } from '../../models';

export abstract class NgxCheckoutComlpeteComponent {
  location = inject(Location);
  router = inject(Router);
  orderService = inject(OrderService);
  httpErrorService = inject(HttpErrorService);

  order: Order | undefined;


  constructor() {
    const state = this.location.getState() as CheckoutCompleteNavExtras;
    if (state?.orderId) {
      this.getOrder(+state.orderId);
    } else {
      this.router.navigateByUrl('/');
    }
  }

  getOrder(orderId: number) {
    this.orderService.findOne(orderId).subscribe({
      next: (order) => this.onSucess(order),
      error: (err) => this.onError(err),
    });
  }

  onSucess(order: Order) {
    this.order = order;
  }

  onError(err: any) {
    this.httpErrorService.onHttpError(err, 'Could not load order details');
  }
}