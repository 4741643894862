import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { NgxAimService } from '../ngx-aim.service';
import { HttpErrorService } from './http-error.service';
import { GenericSearchService } from './generic-search.service';
import { AppLazyLoadEvent, FindAllResult, Order } from '../models';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class OrderService extends GenericSearchService<Order> {

  private baseUrl = `${this.ngxAimService.getApiUrl()}/orders`;

  constructor(
    private http: HttpClient,
    private ngxAimService: NgxAimService,
    private _httpErrorService: HttpErrorService
  ) {
    super(_httpErrorService, 'Orders');
  }

  findAll(dto: AppLazyLoadEvent): Observable<FindAllResult<Order>> {
    return this.http.post<FindAllResult<Order>>(`${this.baseUrl}/all`, dto);
  }

  findOne(id: number): Observable<Order> {
    return this.http.get<Order>(`${this.baseUrl}/order/${id}`);
  }
}
