import { Component, OnInit } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormBuilder } from '@angular/forms';
import { DynamicDialogConfig, DynamicDialogRef } from 'primeng/dynamicdialog';
import { ReactiveFormsModule } from '@angular/forms';
import { MessageService } from 'primeng/api';
import { FileUploadModule } from 'primeng/fileupload';
import { AdminPrimengModule } from '../../admin.primeng.module';
import { TrueFalseText } from '../../../../types';
import { AppUploadEvent, UpdateWebCateogryDto, WebCategory } from '../../../../models';
import { HttpErrorService, UtilsService } from '../../../../services';
import { AdminWebCategoryService } from '../admin-web-category.service';

@Component({
  selector: 'aim-edit-web-category',
  standalone: true,
  imports: [
    CommonModule,
    ReactiveFormsModule,
    FileUploadModule,
    AdminPrimengModule,
  ],
  templateUrl: './edit-web-category.component.html',
  styleUrls: ['./edit-web-category.component.scss', '../../admin.scss'],
})
export class EditWebCategoryComponent implements OnInit {
  headers: any;
  dropDownOptions: TrueFalseText[] = ['True', 'False'];

  form = this.fb.group({
    Id: this.fb.nonNullable.control<number>(0),
    Name: this.fb.control<string | null>(''),
    Description: this.fb.control<string | null>(''),
    Unpublished: this.fb.nonNullable.control<TrueFalseText>('False'),
    showSubCatImages: this.fb.nonNullable.control<TrueFalseText>('False'),
    thumbSrc: this.fb.control<string | null>(null),
  });

  isLoading = false;
  isSaving = false;

  constructor(
    private entityService: AdminWebCategoryService,
    private fb: FormBuilder,
    private config: DynamicDialogConfig,
    private dialogRef: DynamicDialogRef,
    private messageService: MessageService,
    private httpErrorService: HttpErrorService,
    private utils: UtilsService,
  ) {}

  ngOnInit() {
    // this.form.disable();
    if (typeof this.config.data === 'number') {
      this.getEntity(this.config.data);
    }
  }

  getEntity(entityId: number) {
    this.isLoading = true;
    this.entityService.findOne(entityId).subscribe({
      next: (entity) => this.setFormData(entity),
      error: (err) => this.onError(err, 'Could not retrieve category')
    });
  }

  setFormData(entity: WebCategory) {
    this.isLoading = false;
    this.form.patchValue({
      ...entity,
      Unpublished: this.utils.convertBooleanToTrueFalseText(entity.Unpublished),
      showSubCatImages: this.utils.convertBooleanToTrueFalseText(entity.showSubCatImages),
    });
    this.headers = { id: entity.Id };
  }

  onSave() {
    this.isSaving = true;
    const dto: UpdateWebCateogryDto = {
      Id: this.form.get('Id')!.value,
      Unpublished: this.utils.convertTrueFalseTextToBoolean(this.form.get('Unpublished')!.value),
      showSubCatImages: this.utils.convertTrueFalseTextToBoolean(this.form.get('showSubCatImages')!.value),
    }
    this.entityService.save(dto).subscribe({
      next: (entity) => this.onSuccess(entity),
      error: (err) => this.onError(err, 'Could not save category')
    });
  }

  onCancel() {
    this.dialogRef.close();
  }

  onError(err: any, message: string) {
    this.isSaving = false;
    this.isLoading = false;
    this.httpErrorService.onHttpError(err, message);
  }

  onSuccess(entity: WebCategory) {
    this.setFormData(entity);
    this.isSaving = false;
    this.messageService.add({
      severity: 'success',
      summary: 'Category saved',
      key: 'app-toast'
    });
  }

  onUpload(event: AppUploadEvent) {
    this.messageService.add({ severity: 'success', summary: 'Success', detail: 'Image Uploaded', key: 'app-toast' });
    this.form.patchValue({ thumbSrc: event.originalEvent.body.url });
  }

  onUploadError(event: any) {
    this.messageService.add({ severity: 'error', summary: 'Failed', detail: 'Image could not be uploaded', key: 'app-toast' });
  }
}
