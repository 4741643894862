Rental pricing
<div class="flex flex-row flex-wrap gap-5 rate-wrapper">
  <p-progressSpinner *ngIf="isLoading" styleClass="w-4rem h-4rem" strokeWidth="8" fill="var(--surface-ground)" animationDuration=".5s"></p-progressSpinner>
  <div *ngFor="let rate of rates" class="rate">
    <div class="flex-1 flex-column align-content-center">
      <h2 class="text-center m-0">{{rate.Amt | currency}}</h2>
      <div class="text-center">{{rate.Desc}}</div>
    </div>
  </div>
</div>
