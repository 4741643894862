import { Component, OnInit } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ReactiveFormsModule } from '@angular/forms';
import { CalendarModule } from 'primeng/calendar';
import { DropdownModule } from 'primeng/dropdown';
import { ProgressSpinnerModule } from 'primeng/progressspinner';
import { NgxTimePickerComponent } from '../../../ngx-time-picker/ngx-time-picker.component';
import { HasImagePipe } from '../../../../pipes/has-image/has-image.pipe';
import { NgxAimRentalCheckAvailabilityComponent } from './ngx-rental-check-availability.class';

@Component({
  selector: 'ngx-rental-check-availability',
  standalone: true,
  imports: [
    CommonModule,
    ReactiveFormsModule,
    CalendarModule,
    HasImagePipe,
    DropdownModule,
    ProgressSpinnerModule,
    NgxTimePickerComponent,
  ],
  templateUrl: './ngx-rental-check-availability.component.html',
  styleUrls: ['./ngx-rental-check-availability.component.css']
})
export class NgxRentalCheckAvailabilityComponent extends NgxAimRentalCheckAvailabilityComponent implements OnInit {
  ngOnInit() {
    this.init();
  }
}
