import { inject } from '@angular/core';
import { Router } from '@angular/router';
import { Dropdown, DropdownChangeEvent } from 'primeng/dropdown';
import { FormBuilder, Validators } from '@angular/forms';
import { ContractsService, HttpErrorService } from '../../services';
import { C2BGradeGeneric, C2BInstrumentGeneric } from '../../models';
import { PublicRoutes } from '../../enums';

/** when extending this class be sure to implement ngOnInit,
 * @ViewChild('dropDown')
 * override dropDown: Dropdown | undefined;
 */
export abstract class NgxSelectInstrumentComponent {
  // ngOnInit(): void {
  //   this.init();
  // }

  router = inject(Router);
  contractsService = inject(ContractsService);
  httpErrorService = inject(HttpErrorService);
  fb = inject(FormBuilder);

  dropDown: Dropdown | undefined;

  form = this.fb.group({
    instrument: this.fb.control<C2BInstrumentGeneric | null>(null, [Validators.required]),
    grade: this.fb.control<C2BGradeGeneric | null>({ value: null, disabled: true }, [Validators.required]),
  });

  instruments$ = this.contractsService.instruments$;
  grades$ = this.contractsService.grades$;
  subSelectionTooltip = 'Select Instrument Frist';
  subSelectionAimToken = 'Size';
  isLoading = false;
  isMobile = false;

  init(): void {
    this.getInstruments();
    this.fillForm();
  }

  getInstruments() {
    if (!this.contractsService.selectedTeacher) {
      this.router.navigate(['/', PublicRoutes.CONTRACTS]);
      return;
    }

    this.isLoading = true;
    this.contractsService.getInstruements(this.contractsService.selectedTeacher.Id).subscribe({
      next: (instruments) => this.onSuccess(instruments),
      error: (err) => this.onFailure(err),
    });
  }

  fillForm() {
    if (this.contractsService.selectedInstrument) {
      this.form.patchValue({ instrument: this.contractsService.selectedInstrument });
      this.subSelectionTooltip = '';
    }

    if (this.contractsService.selectedGrade && this.contractsService.selectedInstrument) {
      this.form.patchValue({ grade: this.contractsService.selectedGrade });
      this.form.controls.grade.enable();
    }
  }

  onSuccess(instruemnts: C2BInstrumentGeneric[]) {
    instruemnts.forEach(inst => {
      if (!inst.Image) {
        inst.Image = '/assets/images/instruments/missing-image.webp';
      }
    });
    this.isLoading = false;
    this.contractsService.setInstruments(instruemnts);
  }

  onFailure(err: any) {
    this.isLoading = false;
    this.httpErrorService.onHttpError(err, 'Could not load instruments');
  }

  onInstrumentClick(instrument: C2BInstrumentGeneric, el: HTMLElement) {
    this.form.patchValue({ instrument: instrument });
    this.contractsService.setGrades(instrument.GradeList);
    this.contractsService.selectedInstrument = instrument;
    this.contractsService.selectedAccessories = [];
    this.contractsService.selectedMaintenance = [];
    this.contractsService.selectedDelivery = undefined;
    this.subSelectionTooltip = '';
    this.subSelectionAimToken = instrument.Token;
    this.form.controls.grade.enable();
    el.scrollIntoView({ behavior: 'smooth' });
    if (this.dropDown) {
      this.dropDown.show();
    }
  }

  onGradeChange($event: DropdownChangeEvent) {
    if (this.form.controls.grade.value) {
      this.contractsService.selectedGrade = this.form.controls.grade.value;
      this.contractsService.updateDisabledSteps();
    }
  }

  onNextClick() {
    this.isLoading = true;
    this.contractsService.updateDisabledSteps();
    this.router.navigate(['/', PublicRoutes.CONTRACTS, PublicRoutes.OPTIONS_ACCESSORIES]);
  }

  onBackClick() {
    this.router.navigate(['/', PublicRoutes.CONTRACTS, PublicRoutes.SELECT_SCHOOL]);
  }
}