import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { NgxAimService } from '../ngx-aim.service';
import { Observable } from 'rxjs';
import { ItemForDisplay } from '../models';

@Injectable({
  providedIn: 'root'
})
export class ItemService {
  private baseUrl = `${this.ngxAimService.getApiUrl()}/items`;

  constructor(
    private http: HttpClient,
    private ngxAimService: NgxAimService,
  ) {}

  findOne(id: number): Observable<ItemForDisplay> {
    return this.http.get<ItemForDisplay>(`${this.baseUrl}/${id}`);
  }
}
