import { Injectable } from '@angular/core';
import { NgxAimService } from '../../../ngx-aim.service';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { GPTokenSuccess, GlobalpayConfig } from './globalpay.model';

@Injectable({
  providedIn: 'root'
})
export class NgxGlobalpayService {
  private baseUrl = `${this.ngxAimService.getApiUrl()}/payment/globalpay`;

  constructor(private ngxAimService: NgxAimService, private http: HttpClient) {}

  getConfig(): Observable<GlobalpayConfig> {
    return this.http.get<GlobalpayConfig>(`${this.baseUrl}/config`);
  }
}
