<div class="col-12">
  <form [formGroup]="form" class="p-fluid p-formgrid grid dialog-form">
    <div class="field col-12 md:col-6 mt-3">
      <span class="p-float-label">
        <input pInputText id="name" formControlName="name" />
        <label htmlFor="name">Name</label>
      </span>
    </div>
    <div class="field col-12 md:col-6 mt-3">
      <span class="p-float-label">
        <p-dropdown [options]="dropDownOptions" formControlName="required" placeholder="Accepts Transfers"  inputId="required"></p-dropdown>
        <label for="required">Required</label>
    </span>
    </div>
    <!-- <div class="field col-12 md:col-6">
      <span class="p-float-label">
        <input pInputText id="forType" formControlName="forType" />
        <label htmlFor="forType">For</label>
      </span>
    </div> -->
  </form>
  <div class="flex justify-content-end">
    <p-button label="Save"
              icon="pi pi-check"
              styleClass="p-button-text"
              [loading]="isSaving"
              [disabled]="form.invalid"
              (onClick)="onSave()">
    </p-button>
    <p-button label="Cancel"
              icon="pi pi-times"
              styleClass="p-button-text"
              [disabled]="isSaving"
              (onClick)="onCancel()">
    </p-button>
  </div>
  <p-divider align="left">
    <i class="pi pi-info-circle mr-2"></i>
    <b>Metadata</b>
  </p-divider>
</div>
<div class="col-12">
  <form [formGroup]="metaForm" class="p-fluid p-formgrid grid dialog-form">
    <div class="field col-12 md:col-6">
      <span class="p-float-label">
        <input pInputText id="createDate" formControlName="createDate" />
        <label htmlFor="createDate">Created</label>
      </span>
    </div>
    <div class="field col-12 md:col-6">
      <span class="p-float-label">
        <input pInputText id="updateDate" formControlName="updateDate" />
        <label htmlFor="updateDate">Updated</label>
      </span>
    </div>
  </form>
</div>
<div class="progress-spinner" *ngIf="isLoading">
  <p-progressSpinner></p-progressSpinner>
</div>