import { ItemAttribute } from './attribute.model';
import { Location } from './locations.model';
import { STRData } from './short-term-rental.model';
import { WebCategory } from './web-category.model';

export enum ItemInventoryType {
  ACCESSORY = 'A',
  SERIALIZED = 'S',
  PACKAGE = 'P', // not implemented
  /** not used in AIM */
  ANY = 'any',
}

export interface ImageInfo {
  id: number;
  UriPk: string;
  src: string;
  thumbSrc: string;
}

export interface ItemSerial {
  SasPk: number;
  Serial: string;
  Retail: number;
  OurPrice: number;
  Status: string;
  PriceA: number;
  PriceB: number;
  PriceC: number;
  MinPrice: number;
  AllowPriceChange: boolean;
  Notes: string;
  HasImages: boolean;
  IsActiveeHoldback: boolean;
  AvailableForSTR: boolean;
}

export interface ItemLocation {
  SaqPk: number;
  Serials: ItemSerial[];
  // Substitutes: any[];
  Retail: number;
  OurPrice: number;
  PriceA: number;
  PriceB: number;
  PriceC: number;
  MinPrice: number;
  CustomerPrice: number;
  AllowPriceChange: boolean;
  Qty: number;
  QtyAvail: number;
  ActiveeHoldbackQty: number;
  ReorderMin: number;
  ReorderMax: number;
  Locator: string;
  Spiff: number;
  QtyOut: number;
  QtyOnOrder: number;
  AvailableForSTR: number;
  LocationInfo: Location;
}

export interface ItemVariant {
  SkuPk: number;
  Sku: string;
  VendorName: string;
  // Attributes: any[];
  LocationDetails: ItemLocation[];
  // Images: any[];
  HasImages: boolean;
  // Barcodes: string[];
}

export interface ActiveEInfo {
  Dimension: string;
  WebDescription: string;
  Summary: string;
  SETitle: string;
  SEDescription: string;
  SEKeywords: string;
  SEAltText: string;
  SEHtmlFreeText: string;
  Unpublished: boolean;
  FreeShipping: boolean;
  IsFeatured: boolean;
  WebCategoryIds: number[];
}

export interface ItemDetail {
  SadPk: number;
  InvType: string;
  Model: string;
  Description: string;
  Mfg: string;
  Category: number;
  CategoryDescription: string;
  SubCategory: number;
  SubCategoryDescription: string;
  SellSerialsOnline: boolean;
  Notes: string;
  Images: ImageInfo[];
  HasImages: boolean;
  VariantDetails: ItemVariant[];
  // AddOns: any[];
  Weight: number;
  Unit: string;
  ActiveEInfo?: ActiveEInfo;
}

export interface Item {
  Pk: number;
  CustomerPrice: number;
  Description: string;
  InventoryType: ItemInventoryType;
  Manufacturer: string;
  Model: string;
  Category: number;
  CategoryDescription: string;
  SubCategory: number;
  SubCategoryDescription: string;
  SelectionCode: string;
  SelectionCodeDescription: string;
  Sku: string;
  Discontinued: boolean;
  ComputerQuantity: number;
  OurPrice: number;
  RetailPrice: number;
  ActiveePrice: number;
  PriceA: number;
  PriceB: number;
  PriceC: number;
  MinPrice: number;
  AvailableQuantity: number;
  IsMedia: boolean;
  IsPackage: boolean;
  HasImage: boolean;
  Keywords: string;
  KeywordsOrigin: string;
  Deleted: boolean;
  TimeStamp: string;
  Detail: ItemDetail;
  /** Start of item flattening. Some objects are deeply nested. They will be moved to the item itself */
  Dimension: string;
  WebDescription: string;
  Summary: string;
  SETitle: string;
  SEDescription: string;
  SEKeywords: string;
  SEAltText: string;
  SEHtmlFreeText: string;
  Unpublished: boolean;
  FreeShipping: boolean;
  IsFeatured: boolean;
  webCategories: WebCategory[];
  images: ImageInfo[];
  thumbSrc: string;
  attributes: ItemAttribute[];
  /** End of item flattening */
  routerLink: string;
}

export type ItemForDisplayItemProperties = Pick<
  Item,
  | 'Pk'
  | 'Model'
  | 'Manufacturer'
  | 'RetailPrice'
  | 'ActiveePrice'
  | 'Sku'
  | 'Description'
  | 'SETitle'
  | 'WebDescription'
  | 'images'
  | 'attributes'
  | 'thumbSrc'
  | 'routerLink'
  | 'InventoryType'
  | 'Dimension'
  | 'Summary'
  | 'AvailableQuantity'
>;

export interface ItemForDisplay extends ItemForDisplayItemProperties {
  cartQty?: number;
  strData?: STRData;
  Weight: number;
}
