<p-table #dt
         dataKey="id"         
         [value]="(data$ | async)!"
         [loading]="(isLoading$ | async)!"
         [totalRecords]="(dataCount$ | async)!"
         [lazy]="true"
         [rows]="10"
         [showCurrentPageReport]="true"
         [rowsPerPageOptions]="[10, 25, 50]"
         [paginator]="true"
         [globalFilterFields]="['order.id', 'order.BillingName', 'order.BillingPhone']"
         [filterDelay]="250"
         [breakpoint]="'960px'"
         [rowHover]="true"
         [sortOrder]="-1"
         (onLazyLoad)="onLazyLoad($event)"
         currentPageReportTemplate="Showing {first} to {last} of {totalRecords} entries"
         sortField="order.id"
         sortMode="single"
         responsiveLayout="stack">
  <ng-template pTemplate="caption">
    <div class="flex">
      Orders
      <span class="p-input-icon-left ml-auto">
        <i class="pi pi-search"></i>
        <input pInputText type="text" placeholder="Search keyword" (input)="applyFilterGlobal($event)" />
      </span>
    </div>
  </ng-template>
  <ng-template pTemplate="header">
    <tr>
      <th style="min-width:15rem" pSortableColumn="order.id">
        <div class="flex align-items-center">
          Number
          <p-sortIcon field="order.id"></p-sortIcon>
          <p-columnFilter type="text" field="order.id" display="menu"></p-columnFilter>
        </div>
      </th>
      <th style="min-width:15rem" pSortableColumn="order.status">
        <div class="flex align-items-center">
          Status
          <p-sortIcon field="order.status"></p-sortIcon>
          <p-columnFilter type="text" field="order.status" display="menu"></p-columnFilter>
        </div>
      </th>
      <th style="min-width:15rem" pSortableColumn="order.paymentStatus">
        <div class="flex align-items-center">
          Payment Status
          <p-sortIcon field="order.paymentStatus"></p-sortIcon>
          <p-columnFilter type="text" field="order.paymentStatus" display="menu"></p-columnFilter>
        </div>
      </th>
      <th style="min-width:15rem" pSortableColumn="order.BillingName">
        <div class="flex align-items-center">
          Name
          <p-sortIcon field="order.BillingName"></p-sortIcon>
          <p-columnFilter type="text" field="order.BillingName" display="menu"></p-columnFilter>
        </div>
      </th>
      <th style="min-width:15rem" pSortableColumn="order.BillingPhone">
        <div class="flex align-items-center">
          Phone
          <p-sortIcon field="order.BillingPhone"></p-sortIcon>
          <p-columnFilter type="text" field="order.BillingPhone" display="menu"></p-columnFilter>
        </div>
      </th>
      <th style="min-width:15rem" pSortableColumn="order.Total">
        <div class="flex align-items-center">
          Total
          <p-sortIcon field="order.Total"></p-sortIcon>
          <p-columnFilter type="text" field="order.Total" display="menu"></p-columnFilter>
        </div>
      </th>
      <th style="width: 8rem"></th>
    </tr>
  </ng-template>
  <ng-template pTemplate="body" let-order>
    <tr [ngClass]="{'deleted-record': order.deleteDate}">
      <td>
        <span class="p-column-title"><strong>Number</strong></span>
        {{order.id}}
      </td>
      <td>
        <span class="p-column-title"><strong>Status</strong></span>
        {{order.status}}
      </td>
      <td>
        <span class="p-column-title"><strong>Payment Status</strong></span>
        {{order.paymentStatus}}
      </td>
      <td>
        <span class="p-column-title"><strong>Name</strong></span>
        {{order.BillingName}}
      </td>
      <td>
        <span class="p-column-title"><strong>Phone</strong></span>
        {{order.BillingPhone}}
      </td>
      <td>
        <span class="p-column-title"><strong>Total</strong></span>
        {{order.Total | currency}}
      </td>
      <td style="text-align: center">
        <button pButton
                pRipple
                icon="pi pi-pencil"
                class="p-button-rounded p-button-primary mr-2"
                (click)="openEntity(order.id)"
                pTooltip="Edit"
                tooltipPosition="left">
        </button>
      </td>
    </tr>
  </ng-template>
  <ng-template pTemplate="emptymessage">
    <tr>
      <td colspan="7">No orders found.</td>
    </tr>
  </ng-template>
</p-table>
