import { ActivatedRoute, Router } from '@angular/router';
import { PriceFilter } from '../../../../models';
import { inject } from '@angular/core';

/** when extending this class be sure to implement ngOnInit,
 * @Input()
 * override minMax: PriceFilter | undefined;
 */
export abstract class NgxPriceFilterComponent {
  // ngOnInit() {
  //   this.init();
  // }

  router = inject(Router);
  route = inject(ActivatedRoute);

  minMax: PriceFilter | undefined;

  min: number | null = null;
  max: number | null = null;

  init() {
    this.min = this.minMax?.min || null;
    this.max = this.minMax?.max || null;
  }

  onGoClick() {
    this.router.navigate(
      [],
      {
        relativeTo: this.route,
        queryParams: {
          min: this.min,
          max: this.max,
        },
        queryParamsHandling: 'merge',
      }
    );
  }
}
