import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { NgxAimService } from '../ngx-aim.service';
import { AutoPayInfoResponse, CardData, CustomerHistoryGeneric, GetCustomerHistoryDto, CustomerHistoryGenericPaymentDto, UpdateCCTokenResponse } from '../models';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class CustomerService {
  private baseUrl = `${this.ngxAimService.getApiUrl()}/customers`;

  constructor(
    private http: HttpClient,
    private ngxAimService: NgxAimService,
  ) {}

  getCustomerHistory(dto: GetCustomerHistoryDto): Observable<CustomerHistoryGeneric[]> {
    return this.http.post<CustomerHistoryGeneric[]>(`${this.baseUrl}/history`, dto);
  }

  getAutoPayInfo(): Observable<AutoPayInfoResponse> {
    return this.http.get<AutoPayInfoResponse>(`${this.baseUrl}/auto-pay-info`);
  }

  updateCCToken(cardData: CardData, AcctType: string): Observable<UpdateCCTokenResponse> {
    return this.http.post<UpdateCCTokenResponse>(`${this.baseUrl}/update-cc-token`, { cardData, AcctType });
  }

  makePayment(cardData: CardData, invoice: CustomerHistoryGenericPaymentDto): Observable<UpdateCCTokenResponse> {
    return this.http.post<UpdateCCTokenResponse>(`${this.baseUrl}/make-payment`, { cardData, invoice });
  }
}
