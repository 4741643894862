import { Injectable } from '@angular/core';
import { NgxAimService } from '../ngx-aim.service';
import { Observable } from 'rxjs';
import {
  Category,
  STRAmount,
  STRCalcItemAmountDto,
  STRCalcSubCatAmountDto,
  STRResult,
  SubCategory,
} from '../models';
import { HttpClient } from '@angular/common/http';

@Injectable({
  providedIn: 'root'
})
export class ShortTermRentalService {
  private baseUrl = `${this.ngxAimService.getApiUrl()}/short-term-rental`;

  constructor(
    private ngxAimService: NgxAimService,
    private http: HttpClient
  ) {}

  getRateForItem(itemPk: number): Observable<STRResult> {
    return this.http.get<STRResult>(`${this.baseUrl}/rates/${itemPk}`);
  }

  calcAmountForItem(dto: STRCalcItemAmountDto): Observable<STRAmount> {
    return this.http.post<STRAmount>(`${this.baseUrl}/calc-amount`, dto);
  }

  getCategories(): Observable<Category[]> {
    return this.http.get<Category[]>(`${this.ngxAimService.getApiUrl()}/category`);
  }

  getSubCategories(categorySlug: string): Observable<SubCategory[]> {
    return this.http.get<SubCategory[]>(`${this.ngxAimService.getApiUrl()}/category/${categorySlug}`);
  }

  getSubCategory(categorySlug: string, subCategorySlug: string): Observable<SubCategory> {
    return this.http.get<SubCategory>(`${this.ngxAimService.getApiUrl()}/category/${categorySlug}/${subCategorySlug}`);
  }

  getRateForSubCat(subCat: Partial<SubCategory>): Observable<STRResult> {
    return this.http.get<STRResult>(`${this.baseUrl}/rates-sub-cat/${subCat.categoryCategoryNumber}/${subCat.SubNumber}`);
  }

  calcAmountForSubCat(dto: STRCalcSubCatAmountDto): Observable<STRAmount> {
    return this.http.post<STRAmount>(`${this.baseUrl}/calc-amount-sub-cat`, dto);
  }
}
