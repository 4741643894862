<div class="col-12">
  <p>It is your responsibility to contact the FFL and confirm they are willing to accept your transfer. Failure to complete this step may result in extra fees and shipping charges. You can only create a FFL that is in your state of residency. We recommend shopping around for the best transfer fee in your area. </p>
  <form [formGroup]="form" class="p-fluid p-formgrid grid dialog-form mt-1">
    <div class="field col-12 md:col-6">
      <span class="p-float-label">
        <input pInputText id="name" formControlName="name" />
        <label htmlFor="name">Name</label>
      </span>
      <small *ngIf="fc.name.touched && fc.name.errors?.['required']" class="p-error flex-start">This field is required.</small>
    </div>
    <div class="field col-12 md:col-6">
      <span class="p-float-label">
        <input pInputText id="address1" formControlName="address1" />
        <label htmlFor="address1">Address</label>
      </span>
      <small *ngIf="fc.address1.touched && fc.address1.errors?.['required']" class="p-error flex-start">This field is required.</small>
    </div>
    <div class="field col-12 md:col-6">
      <span class="p-float-label">
        <input pInputText id="address2" formControlName="address2" />
        <label htmlFor="address2">Address 2</label>
      </span>
      <small *ngIf="fc.address2.touched && fc.address2.errors?.['required']" class="p-error flex-start">This field is required.</small>
    </div>
    <div class="field col-12 md:col-6">
      <span class="p-float-label">
        <input pInputText id="city" formControlName="city" />
        <label htmlFor="city">City</label>
      </span>
      <small *ngIf="fc.city.touched && fc.city.errors?.['required']" class="p-error flex-start">This field is required.</small>
    </div>
    <div class="field col-12 md:col-6">
      <span class="p-float-label">
        <input pInputText id="state" formControlName="state" />
        <label htmlFor="state">State</label>
      </span>
      <small *ngIf="fc.state.touched && fc.state.errors?.['required']" class="p-error flex-start">This field is required.</small>
    </div>
    <div class="field col-12 md:col-6">
      <span class="p-float-label">
        <input pInputText id="zip" formControlName="zip" />
        <label htmlFor="zip">Zip</label>
      </span>
      <small *ngIf="fc.zip.touched && fc.zip.errors?.['required']" class="p-error flex-start">This field is required.</small>
    </div>
    <div class="field col-12 md:col-6">
      <span class="p-float-label">
        <input pInputText id="phone" formControlName="phone" />
        <label htmlFor="phone">Phone</label>
      </span>
      <small *ngIf="fc.phone.touched && fc.phone.errors?.['required']" class="p-error flex-start">This field is required.</small>
    </div>
    <div class="field col-12 md:col-6">
      <span class="p-float-label">
        <input pInputText id="fflNumber" formControlName="fflNumber" />
        <label htmlFor="fflNumber">FFL Number</label>
      </span>
    </div>
  </form>
  <div class="flex justify-content-end">
    <p-button label="Add"
              [loading]="isSaving"
              [disabled]="form.invalid"
              (onClick)="onAddClick()">
    </p-button>
    <p-button label="Cancel"
              class="ml-2"
              [disabled]="isSaving"
              (onClick)="onCancelClick()">
    </p-button>
  </div>
</div>
