import { inject } from '@angular/core';
import { AppStateService, UiService } from '../../services';
import { CarouselSlide } from '../../models';
import { Carousel } from 'primeng/carousel';
import { CarouselService } from './carousel.service';
import { CarouselName } from '../../enums';

/** when extending this class be sure to implement ngOnInit,
 *  @Input() 
 *  override carouselName: CarouselName = CarouselName.MAIN;
 */
export abstract class NgxCarouselComponent {
  // ngOnInit() {
  //   this.init();
  // }

  uiService = inject(UiService);
  carouselService = inject(CarouselService);
  appState = inject(AppStateService);

  carouselName: CarouselName = CarouselName.MAIN;

  /** If the slides array length is 1 the navigators will be removed and
   * this component will function has a banner image
   */
  slides: CarouselSlide[] = [];
  isMobile$ = this.uiService.isMobile$;
  currentPage = 0;
  autoplayInterval = 0;

  constructor() {
    // Fix mobile scrolling
    // https://stackoverflow.com/questions/62302234/cant-vertical-scroll-page-on-mobile-devices-with-full-width-primeng-carousel
    Carousel.prototype.onTouchMove = () => {};
  }

  init(): void {
    this.carouselService.getSlides(this.carouselName).subscribe({
      next: (slides) => this.slides = slides,
    });

    // this setting for the primeng carousel component will cause SSR to freeze if it
    // isn't set to 0 when rending on the server.
    // this may no longer be needed but cannot test until the autoplayInterval bug is fixed.
    // https://github.com/primefaces/primeng/issues/14569
    if (this.appState.isBrowser) {
      this.autoplayInterval = 4000;
    }
  }

  onPreviousClick() {
    if (this.currentPage === 0) {
      this.currentPage = this.slides.length - 1;
      return;
    }
    this.currentPage--;
  }

  onNextClick() {
    if (this.currentPage === this.slides.length - 1) {
      this.currentPage = 0;
      return;
    }
    this.currentPage++;
  }

  onPage($event: any) {
    this.currentPage = $event.page;
  }
}