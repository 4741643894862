/**
 * List of states a order can be in
 *
 * @readonly
 * @enum {string}
 */
export enum OrderStatus {
  /** The order has been created and is waiting for payment approval */
  CREATED = 'created',
  /** The order is paid OR we have passed a payment token to AIM and
   * they will settle the transaction. Waiting for completion in AIM and
   * creation of shipping label */
  PENDING = 'pending',
  /** The order is complete and shipped */
  COMPLETE = 'complete',
  /** There was an error sending the order to the AIM api */
  AIM_ERROR = 'aim_error',
  /** Payment was declined and order was abandoned */
  DECLINED = 'declined',
  /** Admin manually cancelled the order */
  CANCELLED = 'cancelled',
}

/**
 * Acessory items can be shipped to the customer
 * Serialized items must be shipped to a FFL
 *
 * @readonly
 * @enum {string}
 */
export enum TrackingType {
  /** The order contains non serialized item(s) */
  ACCESSORY = 'accessory',
  /** The order contains serialized / firearm item(s) */
  SERIALIZED = 'serialized',
}

/**
 * This is prefixed to every order number
 */
export const OrderPrefix = 'W';
