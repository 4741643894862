import { Component, OnInit } from '@angular/core';
import { CommonModule, DatePipe } from '@angular/common';
import { FormBuilder } from '@angular/forms';
import { DialogService, DynamicDialogConfig, DynamicDialogRef } from 'primeng/dynamicdialog';
import { ReactiveFormsModule } from '@angular/forms';
import { ConfirmationService, MenuItem, MenuItemCommandEvent, MessageService } from 'primeng/api';
import { OrderPaymentLogComponent } from './order-payment-log/order-payment-log.component';
import { AdminOrderItemsComponent } from './admin-order-items/admin-order-items.component';
import { ShippingComponent } from './shipping/shipping.component';
import { AdminPrimengModule } from '../../admin.primeng.module';
import { Order } from '../../../../models';
import { HttpErrorService } from '../../../../services';
import { PaymentStatus } from '../../../../enums';
import { AdminOrderService } from '../admin-order.service';

@Component({
  selector: 'aim-edit-order',
  standalone: true,
  imports: [CommonModule, ReactiveFormsModule, AdminPrimengModule],
  templateUrl: './edit-order.component.html',
  styleUrls: ['./edit-order.component.scss', '../../admin.scss'],
  providers: [DatePipe, DialogService, ConfirmationService],
})
export class EditOrderComponent implements OnInit {
  items: MenuItem[] = [
    {
      label: 'Actions',
      icon: 'pi pi-ellipsis-h',
      items: [
        {
          label: 'Complete',
          icon: 'pi pi-check-square',
          command: () => this.onCompleteOrderClick(),
        }
      ]
    },
    {
      label: 'Shipping',
      icon: 'pi pi-truck',
      command: () => this.onShippingClick(),
    },
    {
      label: 'Items',
      icon: 'pi pi-fw pi-shopping-bag',
      command: () => this.onItemsClick(),
    },
    {
      label: 'Payment Log',
      icon: 'pi pi-fw pi-history',
      command: () => this.onPaymentLogClick(),
    },
    {
      label: 'Refund',
      icon: 'pi pi-fw pi-times',
      command: ($event) => this.onRefundClick($event),
    },
  ];
  isLoading = false;
  isSaving = false;

  form = this.fb.group({
    id: this.fb.control<number | null>(null),
    OrderNumber: this.fb.control<string | null>(null),
    OrderDate: this.fb.control<string | null>(null),
    Total: this.fb.control<number | null>(null),
    status: this.fb.control<string | null>(null),
    paymentStatus: this.fb.control<string | null>(null),
    accessoryDelivery: this.fb.control<string | null>(null),
    serializedDelivery: this.fb.control<string | null>(null),
    ShippingName: this.fb.control<string | null>(null),
    ShippingCompany: this.fb.control<string | null>(null),
    ShippingAddr1: this.fb.control<string | null>(null),
    ShippingAddr2: this.fb.control<string | null>(null),
    ShippingCity: this.fb.control<string | null>(null),
    ShippingSt: this.fb.control<string | null>(null),
    ShippingZip: this.fb.control<string | null>(null),
    ShippingPhone: this.fb.control<string | null>(null),
    BillingName: this.fb.control<string | null>(null),
    BillingCompany: this.fb.control<string | null>(null),
    BillingAddr1: this.fb.control<string | null>(null),
    BillingAddr2: this.fb.control<string | null>(null),
    BillingCity: this.fb.control<string | null>(null),
    BillingSt: this.fb.control<string | null>(null),
    BillingZip: this.fb.control<string | null>(null),
    BillingPhone: this.fb.control<string | null>(null),
  });

  metaForm = this.fb.group({
    cartCreateDate: [{ value: '', disabled: true }],
    createDate: [{ value: '', disabled: true }],
    updateDate: [{ value: '', disabled: true }],
  });

  order: Order | undefined;

  constructor(
    private entityService: AdminOrderService,
    private fb: FormBuilder,
    private config: DynamicDialogConfig,
    private dialogRef: DynamicDialogRef,
    private messageService: MessageService,
    private httpErrorService: HttpErrorService,
    private datePipe: DatePipe,
    private confirmationService: ConfirmationService,
    private dialogService: DialogService,
  ) {}

  ngOnInit() {
    this.form.disable();
    if (this.config.data) {
      this.getEntity(this.config.data);
    }
  }

  getEntity(entityId: number) {
    this.isLoading = true;
    this.entityService.findOne(entityId).subscribe({
      next: (entity) => this.setFormData(entity),
      error: (err) => this.onError(err, 'Could not retrieve order')
    });
  }

  setFormData(entity: Order) {
    this.order = entity;
    this.isLoading = false;
    this.form.patchValue(entity);
    this.metaForm.patchValue({
      cartCreateDate: this.datePipe.transform(new Date(entity.cartCreateDate), 'short'),
      createDate: this.datePipe.transform(new Date(entity.OrderDate), 'short'),
      updateDate: this.datePipe.transform(new Date(entity.updateDate), 'short'),
    });
  }

  onError(err: any, message: string) {
    this.isSaving = false;
    this.isLoading = false;
    this.httpErrorService.onHttpError(err, message);
  }

  onSuccess(entity: Order) {
    this.setFormData(entity);
    this.isSaving = false;
    this.messageService.add({
      severity: 'success',
      summary: 'Order saved',
      key: 'app-toast'
    });
  }

  onRefundClick(event: MenuItemCommandEvent) {
    this.confirmationService.confirm({
      key: 'onRefundClick',
      target: event.originalEvent!.target!,
      message: `Are you sure you whish to refund this order?`,
      icon: 'pi pi-exclamation-triangle',
      accept: () => {
        this.refund();
      }
    });
  }

  onPaymentLogClick() {
    this.dialogService.open(OrderPaymentLogComponent, {
      data: this.config.data,
      header: 'Payment Log',
      width: '95%',
      contentStyle: { "max-height": "680px", "overflow": "auto" },
      dismissableMask: true
    });
  }

  refund() {
    this.entityService.refundOrder(this.config.data).subscribe({
      next: (res) => this.onRefundSuccess(res.status),
      error: (err) => this.httpErrorService.onHttpError(err, 'Could not refund order'),
    });
  }

  onRefundSuccess(paymentStatus: PaymentStatus) {
    if (paymentStatus === PaymentStatus.REFUNDED) {
      this.messageService.add({
        severity: 'success',
        summary: 'Confirmed',
        detail: 'Order has been refunded',
        key: 'app-toast',
        life: 5000
      });
    } else {
      this.messageService.add({
        severity: 'error',
        summary: 'Failure',
        detail: 'The order could not be refunded. Please see payment log',
        key: 'app-toast',
        life: 5000
      });
    }

    this.getEntity(this.config.data);
  }

  onItemsClick() {
    this.dialogService.open(AdminOrderItemsComponent, {
      data: this.config.data,
      header: 'Items',
      width: '600px',
      contentStyle: { "max-height": "680px", "overflow": "auto" },
      dismissableMask: true
    });
  }

  onShippingClick() {
    this.dialogService.open(ShippingComponent, {
      data: this.order,
      header: 'Shipping',
      width: '800px',
      contentStyle: { "max-height": "680px", "overflow": "auto" },
      dismissableMask: true
    });
  }

  onCompleteOrderClick() {
    if (!this.order || !this.order.id) {
      return;
    }
    this.isLoading = true;
    this.entityService.completeOrder(this.order.id).subscribe({
      next: () => { this.getEntity(this.order!.id!); this.isSaving = false; },
      error: (err) => { this.httpErrorService.onHttpError(err, 'Could not complete order'); this.isLoading = false; }
    });
  }
}
