<div class="grid">
  <div class="col-12 md:col-6">
    <h3>As Entered</h3>
    <div class="flex flex-column">
      <div class="flex">Name: {{config.data?.original_address?.name}}</div>
      <div class="flex">Address 1: {{config.data?.original_address?.address_line1}}</div>
      <div class="flex">Address 2: {{config.data?.original_address?.address_line2}}</div>
      <div class="flex">City: {{config.data?.original_address?.city_locality}}</div>
      <div class="flex">State: {{config.data?.original_address?.state_province}}</div>
      <div class="flex">Zip: {{config.data?.original_address?.postal_code}}</div>
      <p-button class="mt-2" label="Fix Address" (onClick)="onFixAddressClick()">
      </p-button>
    </div>
  </div>
  <div class="col-12 md:col-6">
    <h3>Verified Address</h3>
    <div class="flex flex-column">
      <div class="flex">Name: {{config.data?.matched_address?.name}}</div>
      <div class="flex">Address 1: {{config.data?.matched_address?.address_line1}}</div>
      <div class="flex">Address 2: {{config.data?.matched_address?.address_line2}}</div>
      <div class="flex">City: {{config.data?.matched_address?.city_locality}}</div>
      <div class="flex">State: {{config.data?.matched_address?.state_province}}</div>
      <div class="flex">Zip: {{config.data?.matched_address?.postal_code}}</div>
      <p-button class="mt-2" label="Use Address" (onClick)="onUseAddressClick()">
      </p-button>
    </div>
  </div>
</div>
