import { inject } from '@angular/core';
import { ShipEngineRateEstimate } from './delivery-options.model';
import { NgxDeliveryOptionsService } from './ngx-delivery-options.service';
import { AppStateService, CartService, CheckoutService } from '../../../services';
import { CheckoutTabNames, PaymentType, StoreType } from '../../../enums';

export abstract class NgxAimDeliveryOptionsComponent {
  checkoutService = inject(CheckoutService);
  deliveryService = inject(NgxDeliveryOptionsService);
  cartService = inject(CartService);
  appState = inject(AppStateService);

  selectedOption: ShipEngineRateEstimate | undefined;
  options$ = this.deliveryService.deliveryOptions$;
  loading$ = this.deliveryService.loadingDeliveryOptions$;

  onSelectOptionCLick() {
    if (!this.selectedOption) {
      return;
    }

    this.deliveryService.setSelectedDeliveryOption(this.selectedOption);
    if (this.appState.storeType === StoreType.FIREARMS && this.cartService.hasCartGotSerializedItem()) {
      this.checkoutService.setTabIsDisabled(CheckoutTabNames.DELIVERY_OPTIONS_SERIALIZED, false);
      this.checkoutService.setTabIndexByHeader(CheckoutTabNames.DELIVERY_OPTIONS_SERIALIZED);
    } else if (this.appState.paymentType === PaymentType.NONE) {
      this.checkoutService.setTabIsDisabled(CheckoutTabNames.OVERVIEW, false);
      this.checkoutService.setTabIndexByHeader(CheckoutTabNames.OVERVIEW);
    } else {
      this.checkoutService.setTabIsDisabled(CheckoutTabNames.PAYMENT, false);
      this.checkoutService.setTabIndexByHeader(CheckoutTabNames.PAYMENT);
    }
  }
}