import { Injectable } from '@angular/core';
import { NgxAimService } from '../ngx-aim.service';
import { BehaviorSubject, Observable, catchError, tap, throwError } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import { CurrentUser } from '../models';
import { AppStateService } from './app-state.service';

@Injectable({
  providedIn: 'root'
})
export class AuthenticationService {
  private currentUserSubject = new BehaviorSubject<CurrentUser | null>(null);
  public currentUser$ = this.currentUserSubject.asObservable();
  public getCurrentUser() {
    return this.currentUserSubject.value;
  }

  constructor(
    private readonly ngxAimService: NgxAimService,
    private readonly http: HttpClient,
    private readonly appStateService: AppStateService
  ) {
    if (this.appStateService.isBrowser) {
      const userFromStorage = localStorage.getItem('currentUser');
      if (userFromStorage) {
        this.currentUserSubject.next(JSON.parse(userFromStorage));
      }
    }
  }

  login(email: string, password: string): Observable<CurrentUser> {
    return this.http.post<CurrentUser>(`${this.ngxAimService.getApiUrl()}/auth/login`, { email, password })
      .pipe(
        tap((currentUser) => this.onSuccessfulLogin(currentUser)),
        catchError((err) => this.onUnsuccessfulLogin(err),
        ));
  }

  logout() {
    localStorage.removeItem('currentUser');
    this.currentUserSubject.next(null);
  }

  onSuccessfulLogin(currentUser: CurrentUser) {
    localStorage.setItem('currentUser', JSON.stringify(currentUser));
    this.currentUserSubject.next(currentUser);
  }

  onUnsuccessfulLogin(err: any) {
    localStorage.removeItem('currentUser');
    this.currentUserSubject.next(null);
    return throwError(() => err);
  }

  createGuestUser(firstName: string, lastName: string, email: string,): Observable<CurrentUser> {
    return this.http.post<CurrentUser>(`${this.ngxAimService.getApiUrl()}/auth/create-guest-user`, {
      firstName,
      lastName,
      email
    }).pipe(
      tap((currentUser) => this.onSuccessfulLogin(currentUser)),
      catchError((err) => this.onUnsuccessfulLogin(err),
      ));
  }
}
