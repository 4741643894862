import { Injectable, Optional } from '@angular/core';

export class NgxAimConfig {
  apiUrl = 'http://localhost';
}

@Injectable({
  providedIn: 'root'
})
export class NgxAimService {
  constructor(@Optional() config?: NgxAimConfig) {
    if (config) {
      this.apiUrl = config.apiUrl;
    } else {
      throw new Error('You must provide config in the forRoot method of NgxAimModule');
    }
  }

  getApiUrl(): string {
    return this.apiUrl;
  }

  private apiUrl = 'http://localhost';
}
