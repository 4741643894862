import { Component, ViewChild } from '@angular/core';
import { CommonModule } from '@angular/common';
import { DialogService } from 'primeng/dynamicdialog';
import { FormsModule } from '@angular/forms';
import { Table, TableLazyLoadEvent } from 'primeng/table';
import { MessageService } from 'primeng/api';
import { EditWebCategoryComponent } from './edit-web-category/edit-web-category.component';
import { AdminPrimengModule } from '../admin.primeng.module';
import { AppLazyLoadEvent } from '../../../models';
import { HttpErrorService } from '../../../services';
import { MatchModesPrime } from '../../../enums';
import { AdminWebCategoryService } from './admin-web-category.service';

@Component({
  selector: 'aim-web-categories',
  standalone: true,
  imports: [
    CommonModule,
    FormsModule,
    AdminPrimengModule,
  ],
  templateUrl: './web-categories.component.html',
  styleUrls: ['./web-categories.component.scss', '../admin.scss'],
  providers: [DialogService],
})
export class WebCategoriesComponent {
  @ViewChild('dt') table: Table | undefined;
  data$ = this.entityService.data$;
  isLoading$ = this.entityService.isLoading$;
  dataCount$ = this.entityService.dataCount$;
  lastLazyLoadEvent: AppLazyLoadEvent = {
    globalFilterFields: [],
    filters: {}
  };
  selectedOptions: string[] = [];
  isSyncing = false;

  constructor(
    private entityService: AdminWebCategoryService,
    private dialogService: DialogService,
    private httpErrorService: HttpErrorService,
    private messageService: MessageService
  ) {}

  onLazyLoad($event: TableLazyLoadEvent) {
    this.lastLazyLoadEvent = $event;
    const lazyLoadEvent: AppLazyLoadEvent = Object.assign({
      withDeleted: this.selectedOptions.includes('withDeleted'),
      globalFilterFields: this.table?.globalFilterFields || [],
    }, $event);

    this.entityService.onFilterEvent(lazyLoadEvent);
  }

  applyFilterGlobal($event: Event) {
    this.table?.filterGlobal(($event.target as HTMLInputElement).value, MatchModesPrime.CONTAINS);
  }

  onSyncClick() {
    this.isSyncing = true;
    this.entityService.sync().subscribe({
      next: () => this.onSyncSuccess(),
      error: (err) => this.onSyncError(err, 'Could not sync web categories'),
    });
  }

  onSyncSuccess() {
    this.isSyncing = false;
    this.messageService.add({
      severity: 'success',
      summary: 'Confirmed',
      detail: `Categories synced`,
      key: 'app-toast',
      life: 5000
    });
    this.onLazyLoad(this.lastLazyLoadEvent);
  }

  onSyncError(err: any, summary: string) {
    this.isSyncing = false;
    this.httpErrorService.onHttpError(err, summary);
  }

  openEntity(entityId?: number) {
    const dialog = this.dialogService.open(EditWebCategoryComponent, {
      data: entityId,
      header: 'Web Category',
      width: '700px',
      contentStyle: { "max-height": "580px", "overflow": "auto" },
      dismissableMask: true
    });
    dialog.onClose.subscribe(() => {
      this.onLazyLoad(this.lastLazyLoadEvent);
    });
  }
}
