import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { NgxAimService } from '../../../../ngx-aim.service';
import { Observable } from 'rxjs';
import { GlobalpaySettingsDto } from '../../../../models';

@Injectable({
  providedIn: 'root'
})
export class NgxAdminGlobalpayService {
  private baseUrl = `${this.ngxAimService.getApiUrl()}/admin/payment/globalpay`;

  constructor(
    private ngxAimService: NgxAimService,
    private http: HttpClient,
  ) {}

  getPaymentSettings(): Observable<GlobalpaySettingsDto> {
    return this.http.get<GlobalpaySettingsDto>(this.baseUrl);
  }

  savePaymentSettings(settings: GlobalpaySettingsDto) {
    return this.http.post(this.baseUrl, settings);
  }
}
