<p-toolbar>
  <div class="p-toolbar-group-start">
    <p-button label="Sync"
              icon="pi pi-sync"
              class="p-button-primary mr-2"
              (onClick)="onSyncClick($event)"
              [loading]="isLoading">
    </p-button>
    <p-confirmPopup key="onSyncClick" [style]="{'max-width': '300px', 'white-space': 'pre-line'}"></p-confirmPopup>
  </div>
</p-toolbar>

<p-table #dt
         dataKey="id"         
         [value]="(data$ | async)!"
         [loading]="(isLoading$ | async)!"
         [totalRecords]="(dataCount$ | async)!"
         [lazy]="true"
         [rows]="10"
         [showCurrentPageReport]="true"
         [rowsPerPageOptions]="[10, 25, 50]"
         [paginator]="true"
         [globalFilterFields]="['item.Sku', 'item.Model']"
         [filterDelay]="250"
         [breakpoint]="'960px'"
         [rowHover]="true"
         (onLazyLoad)="onLazyLoad($event)"
         currentPageReportTemplate="Showing {first} to {last} of {totalRecords} entries"
         sortField="item.Sku"
         sortMode="single"
         responsiveLayout="stack">
  <ng-template pTemplate="caption">
    <div class="flex">
      Items
      <span class="p-input-icon-left ml-auto">
        <i class="pi pi-search"></i>
        <input pInputText type="text" placeholder="Search keyword" (input)="applyFilterGlobal($event)" />
      </span>
    </div>
  </ng-template>
  <ng-template pTemplate="header">
    <tr>
      <th style="min-width:15rem" pSortableColumn="item.Sku">
        <div class="flex align-items-center">
          Sku
          <p-sortIcon field="item.Sku"></p-sortIcon>
          <p-columnFilter type="text" field="item.Sku" display="menu"></p-columnFilter>
        </div>
      </th>
      <th style="min-width:15rem" pSortableColumn="item.Model">
        <div class="flex align-items-center">
          Model
          <p-sortIcon field="item.Model"></p-sortIcon>
          <p-columnFilter type="text" field="item.Model" display="menu"></p-columnFilter>
        </div>
      </th>
      <th style="min-width:15rem" pSortableColumn="item.Description">
        <div class="flex align-items-center">
          Description
          <p-sortIcon field="item.Description"></p-sortIcon>
          <p-columnFilter type="text" field="item.Description" display="menu"></p-columnFilter>
        </div>
      </th>
      <th style="min-width:15rem" pSortableColumn="item.ActiveePrice">
        <div class="flex align-items-center">
          Activee Price
          <p-sortIcon field="item.ActiveePrice"></p-sortIcon>
          <p-columnFilter type="text" field="item.ActiveePrice" display="menu"></p-columnFilter>
        </div>
      </th>
      <th style="width: 8rem"></th>
    </tr>
  </ng-template>
  <ng-template pTemplate="body" let-item>
    <tr [ngClass]="{'deleted-record': item.deleteDate}">
      <td>
        <span class="p-column-title"><strong>Sku</strong></span>
        {{item.Sku}}
      </td>
      <td>
        <span class="p-column-title"><strong>Short Model</strong></span>
        {{item.Model}}
      </td>
      <td>
        <span class="p-column-title"><strong>Description</strong></span>
        {{item.Description}}
      </td>
      <td>
        <span class="p-column-title"><strong>Activee price</strong></span>
        {{item.ActiveePrice | currency}}
      </td>
      <td style="text-align: center">
        <button pButton
                pRipple
                icon="pi pi-pencil"
                class="p-button-rounded p-button-primary mr-2"
                (click)="openEntity(item.Id)"
                pTooltip="Edit"
                tooltipPosition="left">
        </button>
      </td>
    </tr>
  </ng-template>
  <ng-template pTemplate="emptymessage">
    <tr>
      <td colspan="7">No items found.</td>
    </tr>
  </ng-template>
</p-table>
