import { inject } from '@angular/core';
import { DatePipe } from '@angular/common';
import { FormBuilder } from '@angular/forms';
import { DynamicDialogConfig } from 'primeng/dynamicdialog';
import { HttpErrorService, OrderService } from '../../../services';
import { Order } from '../../../models';

/** when extending this class be sure to implement ngOnInit */
export abstract class NgxAimViewOrderComponent {
  // ngOnInit() {
  //   this.init();
  // }

  entityService = inject(OrderService);
  fb = inject(FormBuilder);
  config = inject(DynamicDialogConfig);
  httpErrorService = inject(HttpErrorService);
  datePipe = inject(DatePipe);

  isLoading = false;

  form = this.fb.group({
    id: this.fb.control<number | null>(null),
    OrderNumber: this.fb.control<string | null>(null),
    OrderDate: this.fb.control<string | null>(null),
    Total: this.fb.control<number | null>(null),
    status: this.fb.control<string | null>(null),
    accessoryDelivery: this.fb.control<string | null>(null),
    serializedDelivery: this.fb.control<string | null>(null),
    ShippingName: this.fb.control<string | null>(null),
    ShippingCompany: this.fb.control<string | null>(null),
    ShippingAddr1: this.fb.control<string | null>(null),
    ShippingAddr2: this.fb.control<string | null>(null),
    ShippingCity: this.fb.control<string | null>(null),
    ShippingSt: this.fb.control<string | null>(null),
    ShippingZip: this.fb.control<string | null>(null),
    ShippingPhone: this.fb.control<string | null>(null),
    BillingName: this.fb.control<string | null>(null),
    BillingCompany: this.fb.control<string | null>(null),
    BillingAddr1: this.fb.control<string | null>(null),
    BillingAddr2: this.fb.control<string | null>(null),
    BillingCity: this.fb.control<string | null>(null),
    BillingSt: this.fb.control<string | null>(null),
    BillingZip: this.fb.control<string | null>(null),
    BillingPhone: this.fb.control<string | null>(null),
  });

  metaForm = this.fb.group({
    createDate: [{ value: '', disabled: true }],
    updateDate: [{ value: '', disabled: true }],
  });

  init() {
    this.form.disable();
    if (this.config.data) {
      this.getEntity(this.config.data);
    }
  }

  getEntity(entityId: number) {
    this.isLoading = true;
    this.entityService.findOne(entityId).subscribe({
      next: (entity) => this.setFormData(entity),
      error: (err) => this.onError(err, 'Could not retrieve order')
    });
  }

  setFormData(entity: Order) {
    this.isLoading = false;
    this.form.patchValue({
      ...entity,
      OrderDate: this.datePipe.transform(new Date(entity.OrderDate), 'short'),
    });
    this.metaForm.patchValue({
      createDate: this.datePipe.transform(new Date(entity.OrderDate), 'short'),
      updateDate: this.datePipe.transform(new Date(entity.updateDate), 'short'),
    });
  }

  onError(err: any, message: string) {
    this.isLoading = false;
    this.httpErrorService.onHttpError(err, message);
  }
}
