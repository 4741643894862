import { Injectable } from '@angular/core';
import { BehaviorSubject, map } from 'rxjs';
import { BreakpointObserver, Breakpoints } from '@angular/cdk/layout';

@Injectable({
  providedIn: 'root'
})
export class UiService {
  public isMobile$ = this.breakpointObserver
    .observe([Breakpoints.Handset])
    .pipe(
      map(breakpointState => breakpointState.matches)
    );

  private leftSidebarVisibleSubject = new BehaviorSubject<boolean>(false);
  public leftSidebarVisible$ = this.leftSidebarVisibleSubject.asObservable();
  public set leftSidebarVisible(isVisible: boolean) {
    this.leftSidebarVisibleSubject.next(isVisible);
  }

  constructor(private breakpointObserver: BreakpointObserver) {}
}
